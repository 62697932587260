import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { RESET_APP } from "../../actions/types";
import { SIGN_IN_PAGE } from "../../constants/PageRoutes";

import Spinner from "../../components/Loader/Spinner";

import "./LogOutPage.scss";

class LogOutPage extends Component {
  componentDidMount() {
    const localStorageKeys = ['userData', 'emailValid', 'userTeams', 'inviteDetails'];
    this.props.resetAppData();
    this.props.isLoggedIn(false);
    for (const item of localStorageKeys) {
      localStorage.removeItem(item);
    }
    this.redirectToLoginPage();
  }

  redirectToLoginPage() {
    axios
      .get("/api/logout")
      .finally(() => window.location.href = SIGN_IN_PAGE);
  }

  render() {
    return (
      <div className="logout-page full-page-height page-spinner">
        <Spinner color={"blue"} size={30} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: (data) => dispatch({ type: "IS_LOGGED_IN", payload: data }),
  resetAppData: () => dispatch({ type: RESET_APP }),
});

export default connect(null, mapDispatchToProps)(LogOutPage);
