import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { getStartedQues } from '../../api/api';

import Loader from '../../components/Loader/Loader';
import Answer from '../../components/Answer/Answer';

import '../QuestionDetailPage/QuestionDetailPage.scss';
import './GetStartedQuestionDetailPage.scss';

const GetStartedQuestionDetailPage = props => {
  const [getStartedData, setGetStartedData] = useState([]);
  const [isGetStartedDataLoading, setIsGetStartedDataLoading] = useState(true);

  const {
    match: {
      params: { id },
    }
  } = props;

  useEffect(() => {
    axios.get(getStartedQues)
      .then(res => res)
      .then(data => {
        if (data?.data?.data) {
          const filteredData = data?.data?.data?.filter(datum => datum.quesId === id);
          setGetStartedData(filteredData?.[0]);
        }
      })
      .then(() => setIsGetStartedDataLoading(false));
  }, [id]);

  const createMarkup = (html) => {
    return { __html: html };
  }

  return (
    <div className="get-started-detail-page page-loader-pos dashboard-vert-gutters">
      <div className="ques-detail-container">
        {
          isGetStartedDataLoading ? <Loader />
          :
          <>
            <div className="ques-container">
              <h2 className="ques-title">{getStartedData?.quesTitle}</h2>
              <p className="ques-description" dangerouslySetInnerHTML={createMarkup(getStartedData?.quesDesp)} />
            </div>
            <div className="answers-container">
              <Answer
                key={getStartedData?.answers?.[0]?.answerId}
                answerID={getStartedData?.answers?.[0]?.answerId}
                quesID={id}
                answer={getStartedData?.answers?.[0]?.answerDesp}
              />
          </div>
        </>
        }
      </div>
    </div>
  )
}

export default GetStartedQuestionDetailPage;
