import React from 'react';

import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import './InputField.scss';

const InputField = (props) => {
  const {
    labelText,
    inputValue,
    type,
    handleOnChange,
    handleOnFocus,
    handleOnKeyDown,
    inputTag,
    placeholderText,
    handleOnPaste,
    hasIcon,
    handleOnSumit,
    isBtnDisabled, 
    helpText,
    isRequired,
    className,
    isPasswordInput,
    handleClickShowPassword
  } = props;

  let inputEle = null;

  const isHelpTextAdded = helpText && helpText.length;

  const renderInputType = (inputType) => {
    switch (inputType) {
      case 'input' :
        return inputEle = (
          <>
            <input
              className={`text-field ${isHelpTextAdded ? 'help-text' : ''}`}
              value={inputValue}
              type={type}
              placeholder={placeholderText}
              onChange={(e) => handleOnChange(e.target.value)}
              onFocus={handleOnFocus ? (e) => handleOnFocus(e.target.value) : null}
              onKeyDown={handleOnKeyDown ? (e) => handleOnKeyDown(e) : null}
              onPaste={handleOnPaste ? (e) => handleOnPaste(e) : null}
            />
            {
              hasIcon ?
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnSumit}
                className='input-submit-btn'
                disabled={isBtnDisabled}
              >
                <ArrowForwardIcon fontSize="small" />
              </Button>
              : null
            }
            {
              isPasswordInput ?
              <IconButton className='password-visibility' onClick={handleClickShowPassword}>
                {type === 'text' ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
              </IconButton>
              : null
            }
          </>
        );
      case 'textarea' :
        return inputEle = (
          <textarea
            className="text-field description"
            value={inputValue}
            type={type}
            placeholder={placeholderText}
            onChange={(e) => handleOnChange(e.target.value)}
          />
        );
      default: return inputEle;
    }
  }

  return (
    <div className={`input-field ${className ? className : ''}`}>
      {
        labelText && labelText.length ?
        <div>
          <label className="input-label">{labelText}</label>
          {isRequired ? <span className='mandate-mark'>*</span> : null}
        </div> : null
      }
      {renderInputType(inputTag)}
      {isHelpTextAdded ? <p className='help-text-label'>{helpText}</p> : null}
    </div>
  )
}

export default InputField;
