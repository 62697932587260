import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  myPosts,
  getFollowedQueries,
  followQuery,
  unfollowQuery,
  questionDetails,
  getCollectionDetailsByID,
  updateQuestionByID,
  deleteQuestionAPI,
  addAnswer,
  questionsList,
} from "../../api/api";

import Question from "../../components/Question/Question";
import AnswerPost from "../../components/AnswerPost/AnswerPost";

import EditQuestionModal from "../../components/Modals/EditQuestionModal/EditQuestionModal";
import AddAnswerModal from "../../components/Modals/AddAnswerModal/AddAnswerModal";
import MoveQuestionModal from "../../components/Modals/MoveQuestionModal/MoveQuestionModal";

import Shimmer from "../../components/Shimmer";
import TableShimmer from "../../components/Shimmer/TableShimmer";
import { ORIGIN_TANK } from "../../constants";

import {
  saveQuestionAsDraft,
  saveAnswerAsDraft,
  mostRecentEntriesFirst,
} from "../../utils";

import "./MyPostsPage.scss";

const MyPostsPage = (props) => {
  const {
    userTankID,
    userClientID,
    creatorID,
    userRole,
    currentTeamData,
    selectedMembers,
  } = props;
  const [questionsData, setQuestionsData] = useState([]);
  const [answersData, setAnswersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [quesTitle, setQuesTitle] = useState("");
  const [quesDesc, setQuesDesc] = useState("");
  const [collecId, setCollecId] = useState("");
  const [quesCollectionId, setQuesCollectionId] = useState("");
  const [collectionTitle, setCollectionTitle] = useState("");
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const questionEntries = [];

  const [openMoveQuesModal, setOpenMoveQuesModal] = useState(false);
  const [moveQuestionId, setMoveQuestionId] = useState("");

  const fetchMyPosts = () => {
    const body = {
      tankUserID: currentTeamData?.tankUserID,
      spaceID: currentTeamData?.spaceID,
      name: currentTeamData?.name,
    };
    axios
      .post(myPosts, body)
      .then((res) => {
        const data = res?.data?.data;
        setQuestionsData(data?.questions);
        setAnswersData(data?.answers);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchMyPosts();
  }, []);

  if (questionsData?.length) {
    questionsData.map((ques) => {
      questionEntries.push({
        quesId: ques.quesId,
        quesTitle: ques.quesTitle,
        userId: ques.userId,
        userName: ques.userName,
        answers: ques.answers.length,
        collecId: ques.collectionId,
        origin: ques.origin,
        createdAt: ques.createdAt,
        followed: ques.followed && ques.followed ? ques.followed : false,
        creatorId: ques?.tankUserID,
        quesCreatorDp:
          ques?.origin === ORIGIN_TANK
            ? ques?.creatorDetails?.[0]?.dp
            : ques?.slackCreatorDetails?.[0]?.avatar,
      });
    });
    questionEntries?.length && mostRecentEntriesFirst(questionEntries);
  }

  const fetchQuestionsFromCollectionId = async (colId) => {
    const body = {
      collectionId: colId,
      tankUserID: userTankID,
      spaceID: userClientID,
    };

    const data = await axios.post(questionsList, body);

    const res = data?.data;
    const resCollectionData =
      res?.collectionDetails?.length && res?.collectionDetails?.[0];

    setCollectionTitle(resCollectionData?.collectionName);
  };

  const handleQuestionPrefill = (id) => {
    if (openEditQuestionModal === false) {
      setQuesTitle("");
      setQuesDesc("");
      setCollecId("");
      setCollectionTitle("");
    }

    axios
      .get(`${questionDetails}${id}`)
      .then((res) => {
        const data = res?.data?.data?.[0];
        if (data) {
          setQuesTitle(data.quesTitle);
          setQuesDesc(data.quesDesp);
          setCollecId(data.collectionId);
          return data;
        }
      })
      .then((dt) => {
        if (dt) {
          axios
            .get(`${getCollectionDetailsByID}${dt.collectionId}`)
            .then((res) =>
              setCollectionTitle(res?.data?.data?.[0]?.collectionName)
            );
        }
      });
  };

  const renderEditQuestionModal = (id, collecId) => {
    handleQuestionPrefill(id);
    setOpenEditQuestionModal(!openEditQuestionModal);
    setQuestionId(id);
    setQuesCollectionId(collecId);
  };

  const handleQuestionDelete = (quesID) => {
    setIsDeleteInProgress(true);
    axios.delete(`${deleteQuestionAPI}${quesID}`).then(() => {
      setIsDeleteInProgress(false);
      fetchMyPosts();
    });
  };

  const handleAnswerModalOpen = (quesID, quesTitle) => {
    setAnswerModalOpen(true);
    setQuestionId(quesID);
    setQuesTitle(quesTitle);
  };

  const handleFollowQuestion = (quesId) => {
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios.post(followQuery, body);
  };

  const handleUnfollowQuestion = (quesId) => {
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios.post(unfollowQuery, body);
  };

  const handleEditQuestion = (queTitle, queDesc, quesCollecId) => {
    const updatedQuestionData = {
      quesTitle: queTitle,
      quesDesp: queDesc,
      collectionId: quesCollecId,
    };

    axios
      .patch(`${updateQuestionByID}${questionId}`, updatedQuestionData)
      .then(() => {
        setOpenEditQuestionModal(!openEditQuestionModal);
        fetchMyPosts();
      });
  };

  const handleQuestionSaveDraft = (queTitle, queDesc, quesCollecId) => {
    let colId = "";
    if (quesCollecId === "") {
      colId = quesCollectionId;
    } else {
      colId = quesCollecId;
    }
    saveQuestionAsDraft(
      queTitle,
      queDesc,
      colId,
      userTankID,
      creatorID,
      userClientID,
      selectedMembers
    );
    setOpenEditQuestionModal(!openEditQuestionModal);
  };

  const handleAnswerModalClose = () => {
    setAnswerModalOpen(false);
    setQuestionId("");
  };

  const handleAnswerAdd = (answerDesc, userName) => {
    const addAnswerObj = {
      answerDesp: answerDesc,
      answeredBy: userName,
      tankUserID: userTankID,
    };
    axios.patch(`${addAnswer}${questionId}`, addAnswerObj);
    handleAnswerModalClose();
  };

  const handleAnswerSaveDraft = (answerDesc) => {
    saveAnswerAsDraft(
      answerDesc,
      userTankID,
      userClientID,
      creatorID,
      questionId,
      quesTitle
    );
    handleAnswerModalClose();
  };

  const closeMoveQuestionModal = () => {
    setOpenMoveQuesModal(false);
    setMoveQuestionId("");
  };

  const openMoveQuestionModal = (id) => {
    setOpenMoveQuesModal(true);
    setMoveQuestionId(id);
  };

  const handleMoveQuestionToCollection = async (id) => {
    const updatedQuestionData = {
      collectionId: id,
    };

    await axios.patch(
      `${updateQuestionByID}${moveQuestionId}`,
      updatedQuestionData
    );

    fetchQuestionsFromCollectionId(collecId);
    // fetchMyPosts();

    closeMoveQuestionModal();
  };

  const renderQuestionsPosts = () => {
    if (questionEntries.length) {
      return (
        <div className="question-dash">
          <h2 className="my-post-type">My queries</h2>
          <div className="table-card">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Responses</th>
                  <th>Origin</th>
                  <th>Collection</th>
                  <th>Creator</th>
                  <th>Actions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {questionEntries.map((entry) => {
                  const {
                    quesId,
                    quesTitle,
                    userName,
                    answers,
                    collecId,
                    origin,
                    createdAt,
                    followed,
                    creatorId,
                    quesCreatorDp,
                  } = entry;

                  return (
                    <Question
                      key={quesId}
                      quesId={quesId}
                      collecId={collecId}
                      quesTitle={quesTitle}
                      userName={userName}
                      answers={answers}
                      handleAnswerModalOpen={handleAnswerModalOpen}
                      handleQuestionDelete={handleQuestionDelete}
                      renderEditQuestionModal={renderEditQuestionModal}
                      isFollowed={followed}
                      userTankID={userTankID}
                      userClientID={userClientID}
                      toRenderData={true}
                      origin={origin}
                      createdAt={createdAt}
                      handleFollowQuestion={handleFollowQuestion}
                      handleUnfollowQuestion={handleUnfollowQuestion}
                      toRenderCollectionName={true}
                      openMoveQuestionModal={openMoveQuestionModal}
                      userRole={userRole}
                      creatorId={creatorId}
                      currentTeamData={currentTeamData}
                      isDeleteInProgress={isDeleteInProgress}
                      userDP={quesCreatorDp}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else return null;
  };

  const renderAnswersPosts = () => {
    if (answersData.length) {
      return (
        <div className="answer-posts">
          <h2 className="my-post-type">My responses</h2>
          <div className="table-card">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  {/* <th>Responses</th> */}
                  <th>Origin</th>
                  <th>Collection</th>
                  <th>Creator</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {answersData.map((answer) => {
                  const {
                    answerDesp,
                    answerId,
                    answeredBy,
                    createdTime,
                    verifiedState,
                    origin,
                    tankUserID,
                    quesId,
                    quesTitle,
                    colId,
                    dp,
                  } = answer;
                  return (
                    <AnswerPost
                      key={answerId}
                      answerDesp={answerDesp}
                      answerId={answerId}
                      answeredBy={answeredBy}
                      createdAt={createdTime}
                      verifiedState={verifiedState}
                      origin={origin}
                      userRole={userRole}
                      currentTeamData={currentTeamData}
                      creatorId={tankUserID}
                      quesId={quesId?.quesId}
                      fetchMyPosts={fetchMyPosts}
                      quesTitle={quesTitle}
                      colId={colId}
                      dp={dp}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else return null;
  };

  const noPostsAvailable = () => {
    if (!questionEntries.length && !answersData.length) {
      return (
        <div className="no-posts">
          <div className="no-data-img">
            <img src={require("../../assets/images/no-posts.png")} />
          </div>
          <h1>No posts available</h1>
          <p>Post a query or a response and it will show up here.</p>
        </div>
      );
    } else return null;
  };

  return (
    <div className="my-posts page-top">
      {loading ? (
        <>
          <h2 className="my-post-type shimmer">
            <Shimmer variant="text" width={280} height={50} />
          </h2>
          <TableShimmer variant="rect" width="100%" />
        </>
      ) : (
        <>
          {renderQuestionsPosts()}
          {renderAnswersPosts()}
          {noPostsAvailable()}
        </>
      )}
      <>
        {openEditQuestionModal ? (
          <EditQuestionModal
            quesTitle={quesTitle}
            quesDesc={quesDesc}
            collecID={collecId}
            updateQues={handleEditQuestion}
            close={renderEditQuestionModal}
            open={openEditQuestionModal}
            collectionTitle={collectionTitle}
            userClientID={userClientID}
            handleQuestionSaveDraft={handleQuestionSaveDraft}
            userTankID={userTankID}
          />
        ) : null}
        {answerModalOpen ? (
          <AddAnswerModal
            addAns={handleAnswerAdd}
            currentTeamData={currentTeamData}
            open={answerModalOpen}
            close={handleAnswerModalClose}
            handleAnswerSaveDraft={handleAnswerSaveDraft}
          />
        ) : null}
        {openMoveQuesModal ? (
          <MoveQuestionModal
            close={closeMoveQuestionModal}
            open={openMoveQuesModal}
            collectionTitle={collectionTitle}
            userClientID={userClientID}
            currentCollecId={collecId}
            userTankID={userTankID}
            handleMoveQuestionToCollection={handleMoveQuestionToCollection}
          />
        ) : null}
      </>
    </div>
  );
};

export default MyPostsPage;
