import { useState, useEffect } from 'react';

export const useDebounce = (term, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(term);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(term);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [term]);

  return debouncedValue;
}
