import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";

import { planFeatures, pricingPlans } from "./pricingData";

import { isEmailValid } from "../../utils";

import { BRAND_BLUE, COLOR_BLACK } from "../../constants";
import { ORGANIZATION_CHECK_PAGE } from "../../constants/PageRoutes";

import GetStartedModal from "../../components/Modals/GetStartedModal/GetStartedModal";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import "./Pricing.scss";

const BillingCycleSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: COLOR_BLACK,
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      color: COLOR_BLACK,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: COLOR_BLACK,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const PlanSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: BRAND_BLUE,
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      color: BRAND_BLUE,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: BRAND_BLUE,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 0,
  },
  body: {
    fontSize: 14,
    border: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    border: 0,
  },
}))(TableRow);

const Pricing = () => {
  const [userEmail, setUserEmail] = useState("");
  const [btnDisabled, setIsBtnDisabled] = useState(true);
  const [getStartedOpen, setGetStartedOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [currPlan, setCurrPlan] = useState({ currentPlan: "Free" });

  const [state, setState] = useState({
    billedMonthly: true,
  });

  const openGetStartedModal = () => {
    setGetStartedOpen(true);
  };

  const closeGetStartedModal = () => {
    setGetStartedOpen(false);
  };

  const redirectFromEmail = () => {
    setRedirect(true);
  };

  const handleUserEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      redirectFromEmail();
    }
  };

  const switchBillingCycle = (event) => {
    setState({ ...state, [event.target.name]: !event.target.checked });
  };

  const featureOffering = (feature) => {
    if (feature === "Yes") return <DoneIcon fontSize="small" />;
    if (feature === "No") return <CloseIcon fontSize="small" />;
    return feature;
  };

  const onPricePlanChange = (event) => {
    console.log(event.target.name);
    setCurrPlan({ currentPlan: event.target.name });
  };

  useEffect(() => {
    if (isEmailValid(userEmail)) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [userEmail]);

  return (
    <>
      {redirect ? (
        <Redirect to={`${ORGANIZATION_CHECK_PAGE}?email=${userEmail}`} />
      ) : null}
      <div className="pricing-section page-top">
        <div className="screen-max-width pricing-page-width">
          <h1 className="section-title center">
            Choose the best plan for your team
          </h1>
          <FormGroup className="cycle-switch">
            <p
              className={`switch-type ${
                state.billedMonthly ? "active-plan" : ""
              }`}
            >
              BILLED MONTHLY
            </p>
            <FormControlLabel
              className="switch-btn"
              control={
                <BillingCycleSwitch
                  checked={!state.billedMonthly}
                  onChange={switchBillingCycle}
                  name="billedMonthly"
                />
              }
            />
            <p
              className={`switch-type ${
                !state.billedMonthly ? "active-plan" : ""
              }`}
            >
              BILLED ANNUALLY
            </p>
          </FormGroup>
          <TableContainer className="pricing-plans plan-highlight-shadow large-res">
            <Table aria-label="pricing table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  {pricingPlans.map((plan) => (
                    <StyledTableCell
                      key={plan.planType}
                      align="center"
                      className={`plan ${
                        plan.planType === "Pro" ? "pro-val" : ""
                      }`}
                    >
                      {plan.planType === "Pro" ? (
                        <div className="plan-highlight"></div>
                      ) : null}
                      <p className="plan-type">{plan.planType}</p>
                      <div className="plan-amount">
                        {plan.planType !== "Free" ? (
                          <span className="amount">
                            <span>&#8377;</span>
                            {state.billedMonthly
                              ? plan.monthlyAmount
                              : plan.yearlyAmount}
                          </span>
                        ) : (
                          <span>14 days free trial</span>
                        )}
                        {plan.planType !== "Free" ? (
                          <span>/user/month</span>
                        ) : null}
                      </div>
                      <Button
                        onClick={openGetStartedModal}
                        className="btn-comp"
                      >
                        Choose Plan
                      </Button>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {planFeatures.map((feature) => (
                  <StyledTableRow key={feature.title}>
                    <StyledTableCell component="th" scope="row">
                      {feature.title}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {featureOffering(feature.free)}
                    </StyledTableCell>
                    <StyledTableCell align="center" scope="row">
                      {featureOffering(feature.basic)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      scope="row"
                      className="pro-val"
                    >
                      <div className="plan-highlight"></div>
                      <span>{featureOffering(feature.pro)}</span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={`pricing-plans small-res ${currPlan.currentPlan}`}>
            <FormGroup className="plan-switch">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
              >
                {pricingPlans.map((pricePlan) => (
                  <FormControlLabel
                    key={pricePlan.planType}
                    value="end"
                    control={
                      <Radio
                        color="primary"
                        checked={pricePlan.planType === currPlan.currentPlan}
                        name={pricePlan.planType}
                        onChange={onPricePlanChange}
                      />
                    }
                    label={pricePlan.planType}
                  />
                ))}
              </RadioGroup>
            </FormGroup>
            {pricingPlans.map((pricingPlan) => (
              <div
                key={pricingPlan.planType}
                className={`plan-item ${pricingPlan.planType}`}
              >
                <div className="plan-info">
                  <div className="plan-amount">
                    {pricingPlan.planType !== "Free" ? (
                      <span className="amount">
                        <span>&#8377;</span>
                        {state.billedMonthly
                          ? pricingPlan.monthlyAmount
                          : pricingPlan.yearlyAmount}
                      </span>
                    ) : (
                      <span>14 days free trial</span>
                    )}
                    {pricingPlan.planType !== "Free" ? (
                      <span>/user/month</span>
                    ) : null}
                  </div>
                  <Button onClick={openGetStartedModal} className="btn-comp">
                    Choose Plan
                  </Button>
                </div>
                <div className="plan-feature">
                  {pricingPlan.features.map((feature) => (
                    <div key={feature.title} className="feature-item">
                      <p className="title">{feature.title}</p>
                      <p>{featureOffering(feature.offer)}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {getStartedOpen ? (
        <GetStartedModal
          userEmail={userEmail}
          setUserEmail={setUserEmail}
          btnDisabled={btnDisabled}
          open={getStartedOpen}
          close={closeGetStartedModal}
          redirectFromEmail={redirectFromEmail}
          handleUserEmailKeyDown={handleUserEmailKeyDown}
        />
      ) : null}
    </>
  );
};

export default Pricing;
