import React, { Component, Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import { fetchUser } from "../actions";
import { CURRENT_USER_DATA, RESET_APP } from "../actions/types";
import {
  DASHBOARD_PAGE,
  HOME_PAGE,
  PRICING_PAGE,
  ORGANIZATION_PAGE,
  SIGNUP_PAGE,
  SETTING_UP_PAGE,
  USER_INVITE_PAGE,
  SIGN_IN_PAGE,
  LOGOUT_PAGE,
  CONTACT_US_PAGE,
  PRIVACY_POLICY_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  INTEGRATIONS_PAGE,
  ORGANIZATION_CHECK_PAGE,
  FORGOT_PASSWORD_PAGE,
  PASSWORD_RESET_PAGE,
  VERIFY_EMAIL_PAGE,
} from "../constants/PageRoutes";

import Header from "./Header/Header";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";

// import Login from "../Pages/LoginPage/Login";
import Pricing from "../Pages/PricingPage/Pricing";
import LandingPage from "../Pages/LandingPage/LandingPage";
import OnboardingPage from "../Pages/OnboardingPage/OnboardingPage";
import SettingUpPage from "../Pages/SettingUpPage/SettingUpPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import UserSignInPage from "../Pages/UserSignInPage/UserSignInPage";
import ContactUsPage from "../Pages/ContactUsPage/ContactUsPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "../Pages/TermsAndConditionsPage/TermsAndConditionsPage";
import IntegrationsPage from "../Pages/IntegrationsPage/IntegrationsPage";
import OrganizationCheckPage from "../Pages/OrganizationCheckPage/OrganizationCheckPage";
import SignUpUserPage from "../Pages/SignUpUserPage/SignUpUserPage";
import ForgotPasswordPage from "../Pages/ForgotPasswordPage/ForgotPasswordPage";
import PasswordResetPage from "../Pages/PasswordResetPage/PasswordResetPage";
import LogOutPage from "../Pages/LogOutPage/LogOutPage";
import LoadingPage from "../Pages/LoadingPage/LoadingPage";
import VerifyEmailPage from "../Pages/VerifyEmailPage";
class App extends Component {
  state = {
    redirectToDash: false,
  };

  componentDidMount() {
    this.props.fetchCurrentUser();
  }

  render() {
    const { currentUser, currentUserData, loggedInUserData, signedUpUser } =
      this.props;

    const isDashboard =
      window.location.href.indexOf("dashboard") > -1 ? "is-dashboard" : "";

    return (
      <div className={`tank-web ${isDashboard}`}>
        <BrowserRouter>
          <Route
            path={HOME_PAGE}
            component={(props) => <Header {...props} />}
          />
          <Fragment>
            <ScrollToTop />
            <Route exact path={HOME_PAGE} component={LandingPage} />
            <Route exact path={PRICING_PAGE} component={() => <Pricing />} />
            <ProtectedRoute
              path={DASHBOARD_PAGE}
              component={DashboardPage}
              signedUpUser={signedUpUser}
              redirectToDash={this.state.redirectToDash}
              currentUser={currentUser}
              currentUserData={currentUserData}
              loggedInUserData={loggedInUserData}
              loggedIn={this.props.isUserLoggedIn}
            />
            <Route
              exact
              path={ORGANIZATION_PAGE}
              component={(props) => (
                <OnboardingPage
                  {...props}
                  currentUser={currentUser}
                  currentUserData={currentUserData}
                />
              )}
            />
            <Route
              exact
              path={SIGNUP_PAGE}
              component={(props) => <SignUpUserPage {...props} />}
            />
            <Route
              exact
              path={SETTING_UP_PAGE}
              component={(props) => (
                <SettingUpPage currentUser={currentUser} {...props} />
              )}
            />
            <Route
              exact
              path={USER_INVITE_PAGE}
              component={(props) => <LoadingPage {...props} />}
            />

            {/* <Route exact path='/signin/new-user' component={Login} /> */}

            <Route
              exact
              path={SIGN_IN_PAGE}
              component={(props) => <UserSignInPage {...props} />}
            />
            <Route exact path={LOGOUT_PAGE} component={LogOutPage} />
            <Route exact path={CONTACT_US_PAGE} component={ContactUsPage} />
            <Route
              exact
              path={PRIVACY_POLICY_PAGE}
              component={PrivacyPolicyPage}
            />
            <Route
              exact
              path={TERMS_AND_CONDITIONS_PAGE}
              component={TermsAndConditionsPage}
            />
            <Route
              exact
              path={INTEGRATIONS_PAGE}
              component={IntegrationsPage}
            />
            <Route
              exact
              path={ORGANIZATION_CHECK_PAGE}
              component={OrganizationCheckPage}
            />
            <Route
              exact
              path={FORGOT_PASSWORD_PAGE}
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path={`${PASSWORD_RESET_PAGE}/:userid/:token`}
              component={(props) => <PasswordResetPage {...props} />}
            />
            <Route
              exact
              path={`${VERIFY_EMAIL_PAGE}/:userId/:token`}
              component={(props) => <VerifyEmailPage {...props} />}
            />
          </Fragment>
          <Route path="*" component={(props) => <Footer {...props} />} />
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    currentUserData: state.currentUserData,
    loggedInUserData: state.loggedInUserData,
    signedUpUser: state.signedUpUser,
    isUserLoggedIn: state.isUserLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchUser(dispatch)),
  setCurrentUserData: (data) =>
    dispatch({ type: CURRENT_USER_DATA, payload: data }),
  isLoggedIn: (data) => dispatch({ type: "IS_LOGGED_IN", payload: data }),
  resetAppData: () => dispatch({ type: RESET_APP }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
