import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import {
  DRAWER_WIDTH,
  TANK_LOGO_BLUE,
  ROUTES
} from '../../constants';

import { DASHBOARD_PAGE, SIGN_IN_PAGE, HOME_PAGE, CONTACT_US_PAGE, PRICING_PAGE, SIGNUP_PAGE } from '../../constants/PageRoutes';

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CssBaseline from "@material-ui/core/CssBaseline";

import Logo from '../Logo/Logo';

import "./Header.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    color: "white",
  },
  flex: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const handleStickyNav = () => {
  const headerEle = document.querySelector('.tank-header-nav');
  if (headerEle) {
    if (window.pageYOffset > 55) {
      headerEle.classList.add('sticky-nav');
    } else {
      headerEle.classList.remove('sticky-nav');
    }
  }
}

const Header = (props) => {
  const classes = useStyles();

  const [hamburgerOpen, setHamburgerOpen] = useState('');

  useEffect(() => {
    document.addEventListener('scroll', handleStickyNav);

    return () => {
      document.removeEventListener('scroll', () => handleStickyNav);
    };
  }, []);

  const toggleHamburger = () => {
    if (hamburgerOpen === '') {
      setHamburgerOpen('open');
    } else {
      setHamburgerOpen('');
    }
  }

  const getLogInElement = (name, url) => {
    const exiistingUserData = JSON.parse(localStorage.getItem('userData'));
    if (exiistingUserData) return <a href={DASHBOARD_PAGE}>{name}</a>;

    return <NavLink to={url}>{name}</NavLink>;
  };

  const renderContent = (isMobNav = '') => (
    <ListItem onClick={isMobNav !== '' ? toggleHamburger : null} className='session-status sign-in-btn nav-item'>
      {getLogInElement('Sign in', SIGN_IN_PAGE)}
    </ListItem>
  );

  return (
    <>
      {
        !ROUTES.includes(props.location.pathname.split('/')[1])
        ?
        <div className={`${classes.root} header-nav tank-header`}>
          <CssBaseline />
          <AppBar position="fixed" className={`${classes.appBar} tank-header-nav`}>
            <Toolbar className='dash-toolbar'>
                <Typography variant="h6" className={classes.title}>
                  <a
                    className={classes.logo}
                    href={HOME_PAGE}
                  >
                    <Logo imageUrl={TANK_LOGO_BLUE} />
                  </a>
                </Typography>
                <List className={`${classes.flex} menu-nav-links`}>
                  <>
                    <ListItem className='nav-item'>
                      <Link className={classes.logo} to={CONTACT_US_PAGE}>
                        Contact us
                      </Link>
                    </ListItem>
                    <ListItem className='nav-item'>
                      <Link className={classes.logo} to={PRICING_PAGE}>
                        Pricing
                      </Link>
                    </ListItem>
                  </>
                  {renderContent()}
                  <ListItem className='nav-item'>
                      {getLogInElement('Sign up', SIGNUP_PAGE)}
                    </ListItem>
                  </List>
                <>
                  <div onClick={toggleHamburger} className={`mobile-nav-icon ${hamburgerOpen}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className={`mobile-canvas ${hamburgerOpen}`}>
                    <List className={classes.flex}>
                      <>
                        <ListItem onClick={toggleHamburger} className='nav-item'>
                          <Link className={classes.logo} to={CONTACT_US_PAGE}>
                            Contact us
                          </Link>
                        </ListItem>
                        <ListItem onClick={toggleHamburger} className='nav-item'>
                          <Link className={classes.logo} to={PRICING_PAGE}>
                            Pricing
                          </Link>
                        </ListItem>
                      </>
                      {renderContent('isMobNav')}
                    </List>
                  </div>
                  <div onClick={toggleHamburger} className={`mobile-canvas-overlay ${hamburgerOpen}`}></div>
                </>
            </Toolbar>
          </AppBar>
        </div> : null
      }
    </>
  );
};

export default Header;
