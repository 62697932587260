export const FETCH_USER = 'fetch_user';
export const ONBOARDED_USER = 'ONBOARDED_USER';
export const CURRENT_USER = 'CURRENT_USER';
export const CURRENT_USER_DATA = 'CURRENT_USER_DATA';
export const ACTION_ID = 'ACTION_ID';
export const SET_ONBOARD_USER_DATA = 'SET_ONBOARD_USER_DATA';
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGGED_IN_USER = 'LOGGED_IN_USER';
export const USER_SIGN_UP = 'USER_SIGN_UP';
export const RESET_APP = 'RESET_APP';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const TEAM_INVITE = 'TEAM_INVITE';
