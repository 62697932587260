import React, { useState, useEffect } from "react";
import axios from 'axios';

import Modal from '../Modal/Modal';
import InputField from '../../InputField/InputField';
import Repeater from '../../Repeater/Repeater';

import { CANCEL, INVITE } from '../../../constants/ButtonLabel';

import { notifyUser } from '../../../api/api';

import './AddTeamMembersModal.scss';

const INIT_TEAM_MESSAGE = 'Please join the team.';

const AddTeamMembersModal = props => {
  const { open, close, currentTeamData} = props;

  const [emailValue, setEmailValue] = useState('');
  const [emailsList, setEmailsList] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [teamMessage, setTeamMessage] = useState(INIT_TEAM_MESSAGE);
  const [inviteSentConfirm, setInviteSentConfirm] = useState(false);

  useEffect(() => {
    if (!open) {
      setEmailsList([]);
      setEmailValue("");
      setEmailError(null);
      setTeamMessage(INIT_TEAM_MESSAGE);
    }
  }, [open]);

  const teamName = currentTeamData?.teamName;
  const adminName = currentTeamData?.name;
  const avatar = currentTeamData?.dp;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const isEmail = email => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  const isInList = email => {
    return emailsList.includes(email);
  }

  const isValid = email => {
    let error = null;
    
    if (!isEmail(email)) {
      error = 'Invalid email address.';
    }
    
    if (isInList(email)) {
      error = 'Email address already added';
    }
    
    if (error) {
      setEmailError(error);
      return false;
    }
    return true;
  }

  const handleOnKeyDown = event => {
    if (['Enter'].includes(event.key)) {
      event.preventDefault();

      let email = emailValue.trim();
      const isLoggedInUserEmail = email === currentTeamData?.email;

      if (email && isValid(email)) {
        setEmailValue('');
        if (!isLoggedInUserEmail) {
          setEmailsList([...emailsList, email]);
        } else {
          setEmailError('Email address cannot be of the logged in user');
        }
      }
    }
  }

  const handleOnEmailChange = val => {
    setEmailValue(val);
    setEmailError(null);
  }

  const handleEmailDelete = emailToRemove => {
    setEmailsList(emailsList.filter(email => email !== emailToRemove));
  }

  const handleOnEmailPaste = event => {
    event.preventDefault();

    const paste = event.clipboardData.getData('text');
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    
    if (emails) {
      const emailToBeAdded = emails.filter(email => !isInList(email));
      const loggedInUserEmail = emailToBeAdded.filter(em => em === currentTeamData?.email);

      if (!loggedInUserEmail?.length) {
        setEmailsList([...emailsList, ...emailToBeAdded]);
      } else {
        const remainingEmails = emailToBeAdded.filter(em => em !== loggedInUserEmail[0]);
        setEmailsList([...emailsList, ...remainingEmails]);
        setEmailError(`Email address cannot be of the logged in user. Removed ${loggedInUserEmail[0]}`);
      }
    }
  }

  const handleNotifyUser = async () => {
    const notifyUserData = {
      adminUsername: adminName,
      teamName: teamName,
      userToBeNotified: emailsList,
      message: teamMessage,
      avatar: avatar,
      senderEmail: currentTeamData?.email
    }
    try {
      await axios.post(notifyUser, notifyUserData);
      setInviteSentConfirm(true);
    } catch(err) {
      console.log('Something went wrong', err);
    }
  }

  return (
    <div className="invite-members">
      <Modal
        modalTitle={!inviteSentConfirm ? 'Invite team members' : null}
        modalDisplay={divStyle}
        close={close}
        cancelText={!inviteSentConfirm ? CANCEL : 'OK'}
        provisionType={INVITE}
        isDisabled={emailsList.length === 0}
        handleProvisioning={handleNotifyUser}
        feedbackMsgEnabled={inviteSentConfirm}
        feedbackMsg={'Invitation sent!'}
      >
        <div className="tank-modal">
          <InputField labelText={"Add team members"} />
          <Repeater
            emailValue={emailValue}
            emailsList={emailsList}
            emailError={emailError}
            handleOnKeyDown={handleOnKeyDown}
            handleOnEmailChange={handleOnEmailChange}
            handleEmailDelete={handleEmailDelete}
            handleEmailPaste={handleOnEmailPaste}
          />
          <InputField
            labelText={'Enter message'}
            inputValue={teamMessage}
            type={'text'}
            inputTag={'textarea'}
            handleOnChange={setTeamMessage}
          />
        </div>
      </Modal>
    </div>
  )
}

export default AddTeamMembersModal;
