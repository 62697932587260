import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { signInCheck } from '../../api/api';
import { SIGN_IN_PAGE, SIGNUP_PAGE } from '../../constants/PageRoutes';

// import { appSubdomain } from '../../constants';

import './OrganizationPage.scss';

const OrganizationCheckPage = props => {
  const { location: { search } } = props;
  const urlParams = new URLSearchParams(search);
  const userEmailVal = urlParams.get('email');

  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (userEmailVal) {
      const body = {
        emailID: userEmailVal
      }
      axios.post(signInCheck, body)
        .then(res => {
          if (res?.data?.data?.length) {
            setTimeout(() => (window.location.href = `${SIGN_IN_PAGE}?email=${userEmailVal}`), 1000);
            // setTimeout(() => (window.location.href = `https://${appSubdomain}/signin/existing-user-check?email=${userEmailVal}`), 1000);
          } else {
            // setTimeout(() => (window.location.href = `https://${appSubdomain}/sign-up/new-user?actionid=setup`), 1000);
            setTimeout(() => (window.location.href = `${SIGNUP_PAGE}?actionid=setup`), 1000);
          }
        })
        .catch(() => setErrMsg('Oops, something went wrong.'));
    }
  }, [userEmailVal]);

  return (
    <div className="org-check-page screen-max-width full-page-height">
      {errMsg ? <p>{errMsg}</p> : <p>Redirecting...</p>}
    </div>
  );
}

export default OrganizationCheckPage;
