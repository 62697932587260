import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import Logo from '../../components/Logo/Logo';
import InputField from '../../components/InputField/InputField';
import Spinner from '../../components/Loader/Spinner';

import Button from "@material-ui/core/Button";

import { passwordReset } from '../../api/api';
import { isEmailValid } from '../../utils';
import { TANK_LOGO_BLUE } from '../../constants';
import { DASHBOARD_PAGE, SIGN_IN_PAGE } from '../../constants/PageRoutes';

import './ForgotPasswordPage.scss';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');

  const [disableBtn, setDisableBtn] = useState(true);
  const [resetApiBtnDisable, setResetApiBtnDisable] = useState(false);

  const [emailConfirm, setEmailConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [emailUnavailableErr, setEmailUnavailableErr] = useState(false);
  const [passwordResetErr, setPasswordResetErr] = useState(false);

  const sendEmailConfirmation = async () => {
    const body = { email };

    try {
      setLoading(true);
      setResetApiBtnDisable(true);
      await axios.post(passwordReset, body);
      setEmailConfirm(true);
      setLoading(false);
      setResetApiBtnDisable(false);
    } catch(err) {
      if (err.response.status === 400) {
        setEmailUnavailableErr(true);
        setLoading(false);
        setResetApiBtnDisable(false);
      } else {
        setPasswordResetErr(true);
        setLoading(false);
        setResetApiBtnDisable(false);
      }
    }
  }

  useEffect(() => {
    if (isEmailValid(email)) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    if (emailUnavailableErr) setEmailUnavailableErr(false);
    if (passwordResetErr) setPasswordResetErr(false);
  }, [email]);

  return (
    <div className="forgot-pw-page pw-reset full-page-height">
      <div className="screen-max-width">
        {!emailConfirm ? (
          <>
            <div className="logo-wrapper">
              <a className="logo-container" href={DASHBOARD_PAGE}>
                <Logo imageUrl={TANK_LOGO_BLUE} />
              </a>
            </div>
            <div className="forgot-pw-body">
              <p className="forgot-pw-title">Forgot your password?</p>
              <p className="forgot-pw-desc">
                Enter the email address associated with your account.
              </p>
            </div>
            <div
              className={`pw-reset-form ${
                emailUnavailableErr ? "reset-err" : ""
              }`}
            >
              <InputField
                labelText={"Work Email"}
                inputValue={email}
                type={"email"}
                inputTag={"input"}
                handleOnChange={setEmail}
              />
              {emailUnavailableErr ? (
                <p className="form-err">Email address does not exist</p>
              ) : null}
              {passwordResetErr ? (
                <p className="form-err">
                  Oops, something went wrong. Please try again.
                </p>
              ) : null}
              <div className="reset-btn-container">
                <Button
                  className={`primary-btn thin full-width ${
                    resetApiBtnDisable ? "pw-reset" : ""
                  }`}
                  disabled={disableBtn || resetApiBtnDisable}
                  onClick={sendEmailConfirmation}
                >
                  Reset Password
                </Button>
                {loading ? <Spinner color="white" size={20} /> : null}
              </div>
              <NavLink
                to={SIGN_IN_PAGE}
                className="cancel-btn"
              >
                Cancel
              </NavLink>
            </div>
          </>
        ) : (
          <div className="email-confirmation-wrapper">
            <div className="email-confirm-asset">
              <img
                src={require("../../assets/images/email-confirm.png")}
                alt="Email confirm"
              />
            </div>
            <div className="email-confirm-body">
              <p className="email-confirm-title">Confirm your account</p>
              <p>
                We’ve sent an email to{" "}
                <span className="entered-email">{email}</span>
              </p>
              <div className="try-again-container">
                <p>
                  Not received?{" "}
                  <span
                    className={`try-again ${
                      resetApiBtnDisable ? "disable-link" : ""
                    }`}
                    onClick={sendEmailConfirmation}
                  >
                    Try sending another email.
                  </span>
                </p>
                {loading ? <Spinner color="blue" size={15} /> : null}
                {passwordResetErr ? (
                  <p className="form-err">
                    Oops, something went wrong. Please try again.
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
