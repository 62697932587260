import React from "react";
import { Editor } from '@tinymce/tinymce-react';

import { TANK_EDITOR_API_KEY } from '../../api/api';

import './TankEditor.scss';
const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;
const TankEditor = props => {
  return (
    <div className="tank-editor">
      <Editor
        initialValue={props.setContent}
        value={props.content}
        apiKey={TANK_EDITOR_API_KEY}
        // init={{
        //   height: 500,
        //   menubar: false,
        //   plugins: [
        //     'advlist autolink lists link image emoticons', 
        //     'charmap print preview anchor help',
        //     'searchreplace visualblocks code codesample',
        //     'insertdatetime media table paste wordcount'
        //   ],
        //   toolbar_mode: 'wrap',
        //   toolbar:
        //     'bold underline italic strikethrough link forecolor backcolor emoticons | formatselect | fontselect | fontsizeselect | formatselect | \
        //     alignleft aligncenter alignright outdent indent searchreplace | \
        //     bullist numlist | image | table | code codesample | undo redo'
        // }}
        init={{
          height: 500,
          inline: false,
          menubar: false,
          plugins: [
            'preview importcss searchreplace autolink',
            'autosave save directionality code',
            'visualblocks visualchars fullscreen image',
            'link media codesample table charmap' ,
            'pagebreak nonbreaking anchor insertdatetime paste',
            'advlist lists wordcount help charmap quickbars emoticons accordion'
          ],
          
          
          toolbar_mode: 'wrap',
          toolbar:
            'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
          autosave_ask_before_unload: true,
          autosave_interval: '30s',
          autosave_prefix: '{path}{query}-{id}-',
          autosave_restore_when_empty: false,
          autosave_retention: '2m',
          image_advtab: true,
          importcss_append: true,
          image_caption: true,
          automatic_uploads: true,
          media_live_embeds: true,
          file_picker_types: 'image',
          quickbars_insert_toolbar: 'quicktable quickimage media codesample quicklink',
          quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
          quickbars_selection_toolbar: 'bold italic underline strikethrough| code quicklink h2 h3 forecolor backcolor numlist image',
          noneditable_class: 'mceNonEditable',
          toolbar_mode: 'sliding',
          contextmenu: 'link image table numlist bullist emoticons',
          skin: useDarkMode ? 'oxide-dark' : 'oxide',
          content_css: useDarkMode ? 'dark' : 'default',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
        }}
        onEditorChange={props.editorChange}
      />
    </div>
  );
};

export default TankEditor;
