import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Logo from '../../components/Logo/Logo';
import InputField from '../../components/InputField/InputField';
import { passwordReset } from '../../api/api';

import Button from "@material-ui/core/Button";

import { TANK_LOGO_BLUE } from '../../constants';
import { SIGN_IN_PAGE, HOME_PAGE } from '../../constants/PageRoutes';

import '../ForgotPasswordPage/ForgotPasswordPage.scss';
import './PasswordResetPage.scss';

const PasswordResetPage = props => {
  const {match: {params}, history} = props;
  const userToken = params?.token || '';
  const userID = params?.userid || '';

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordMatchErr, setPasswordMatchErr] = useState(false);
  const [passwordResetErr, setPasswordResetErr] = useState(false);
  const [passwordChangeSuccessfull, setPasswordChangeSuccessfull] = useState(false);

  const [disableBtn, setDisableBtn] = useState(true);
  const [resetApiBtnDisable, setResetApiBtnDisable] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const resetPassword = () => {
    if (newPassword !== confirmPassword) {
      setPasswordMatchErr(true);
    } else {
      setPasswordMatchErr(false);
      axios.post(`${passwordReset}/${userID}/${userToken}`, {password: confirmPassword})
        .then(() => {
          setPasswordChangeSuccessfull(true);
          setNewPassword('');
          setConfirmPassword('');
          setTimeout(() => {
            history.push(SIGN_IN_PAGE);
          }, 2000);
        }).catch(() => setPasswordResetErr(true));
    }
  }

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  useEffect(() => {
    if (newPassword !== '' && confirmPassword !== '') {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    setPasswordMatchErr(false);
  }, [newPassword, confirmPassword]);

  return (
    <div className='password-reset-page pw-reset full-page-height'>
      <div className='screen-max-width'>
        <div className='logo-wrapper'>
          <a className='logo-container' href={HOME_PAGE}>
            <Logo imageUrl={TANK_LOGO_BLUE} />
          </a>
        </div>
        <div className='forgot-pw-body'>
          <p className='forgot-pw-title'>Enter and confirm your new password.</p>
        </div>
        <div className={`pw-reset-form`}>
          <InputField
            labelText={'New Password'}
            inputValue={newPassword}
            type={showNewPassword ? 'text' : 'password'}
            inputTag={'input'}
            handleOnChange={setNewPassword}
            isPasswordInput={true}
            handleClickShowPassword={handleShowNewPassword}
          />
          <InputField
            labelText={'Confirm Password'}
            inputValue={confirmPassword}
            type={showConfirmPassword ? 'text' : 'password'}
            inputTag={'input'}
            handleOnChange={setConfirmPassword}
            isPasswordInput={true}
            handleClickShowPassword={handleShowConfirmPassword}
          />
          <div className='reset-btn-container'>
            <Button
              className={`primary-btn thin full-width ${resetApiBtnDisable ? 'pw-reset' : ''}`}
              disabled={disableBtn || resetApiBtnDisable}
              onClick={resetPassword}
            >
              Reset Password
            </Button>
          </div>
          {passwordMatchErr ? <p className='form-err'>Passwords do not match</p> : null}
          {passwordResetErr ? <p className='form-err'>Oops, something went wrong. Please try again.</p> : null}
          {passwordChangeSuccessfull ? <p className='password-update-success'>Password updated successfully.</p> : null}
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage;
