import "./scss/app.scss";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react'

import App from "./components/App";
import reducers from "./reducers";

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['auth']
};

const pReducer = persistReducer(persistConfig, reducers);

const middleware = applyMiddleware(reduxThunk);

const store = createStore(pReducer, {}, middleware);
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App persistor={persistor} />
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);
