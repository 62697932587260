import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  createCustomer,
  createSubscription,
  createCheckoutSession,
} from "../../../../api/api";

import {
  BASIC_MONTHLY_PLAN,
  BASIC_YEARLY_PLAN,
  PRO_MONTHLY_PLAN,
} from "../../../../constants/Billing";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import InputField from "../../../../components/InputField/InputField";
import SelectDropdown from "../../../../components/SelectDropdown/SelectDropdown";

import "../stripe-logic";

import "./BillingForm.scss";

const BillingForm = (props) => {
  const {
    selectedPlan,
    teamMembersCount,
    currentTeamData: { name, lastName, spaceID, tankUserID },
    subscribedPlan,
  } = props;

  let upgradePlan = "";

  const priceBasicMonthly = "price_1Omk9hSES9iap2otua8r4pWi";
  const priceBasicYearly = "price_1KNu8uSFnXXIu5jChv946dfK";

  const priceProMonthly = "price_1OmjnqSES9iap2otXVyUatqq";
  const priceProYearly = "price_1KNuEdSFnXXIu5jCxXZnN7wJ";

  if (selectedPlan === BASIC_MONTHLY_PLAN) {
    upgradePlan = priceBasicMonthly;
  } else if (selectedPlan === BASIC_YEARLY_PLAN) {
    upgradePlan = priceBasicYearly;
  } else if (selectedPlan === PRO_MONTHLY_PLAN) {
    upgradePlan = priceProMonthly;
  } else {
    upgradePlan = priceProYearly;
  }

  const [companyName, setCompanyName] = useState("");
  const [primaryAddress, setPrimaryAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [VATId, setVATId] = useState("");
  const [secondaryAddress, setSecondaryAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  const [customerID, setCustomerID] = useState("");
  const [paymentID, setPaymentID] = useState("");
  const [subscribedUser, setSubscribedUser] = useState(false);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  useEffect(() => {
    if (paymentID !== "") {
      createUserSubscription(customerID, paymentID, upgradePlan);
    }
  }, [paymentID, customerID]);

  const onSubscriptionComplete = (result) => {
    console.log(result);
  };

  const createUserSubscription = (customerId, paymentMethodId, priceId) => {
    console.log(customerId, paymentMethodId, priceId);
    const body = {
      customerId: customerId,
      priceId: priceId,
      quantity: teamMembersCount,
    };
    axios
      .post(createSubscription, body)
      .then((res) => {
        setSubscribedUser(true);
      })
      .then(onSubscriptionComplete)
      .catch((err) => setSubscribedUser(false));
  };

  const handleUserDetailsSubmit = (event) => {
    event.preventDefault();

    const customerEmail = {
      email: billingEmail,
      name: `${name} ${lastName}`,
      phone: "1234567890",
      description: "tank-comp",
      currency: "inr",
      tax_exempt: "none",
      invoicing_email_to: "",
      invoicing_email_cc: "",
      address_line1: primaryAddress,
      address_line2: secondaryAddress,
      address_city: city,
      address_state: state,
      address_postal_code: postalCode,
      address_country: country,
      shipping_name: `${name} ${lastName}`,
      shipping_phone: "1234567890",
      shipping_address_line1: primaryAddress,
      shipping_address_line2: secondaryAddress,
      shipping_address_city: city,
      shipping_address_state: state,
      shipping_address_postal_code: postalCode,
      shipping_address_country: country,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      next_invoice_sequence: 1,
      invoice_prefix: "TANK",
      preferred_locales: "en-US",
      company_name: companyName,
      vat_id: VATId,
      tankUserID,
      spaceID,
    };

    window.localStorage.setItem(
      "subscribedPlan",
      JSON.stringify(subscribedPlan)
    );

    axios
      .post(createCustomer, customerEmail)
      .then(async (res) => {
        setCustomerID(res?.data?.id);
        return res?.data?.id;
      })
      .then(async (response) => {
        console.log(response);
        const createCheckOutSessionBody = {
          customerId: response,
          priceId: upgradePlan,
          quantity: teamMembersCount,
        };
        const session = await axios.post(
          createCheckoutSession,
          createCheckOutSessionBody
        );
        console.log("session", session);
        if (session?.data?.url) {
          window.location.href = session.data.url;
        }
      });
  };

  return (
    <div className="billing-form">
      <form id="signup-form" onSubmit={handleUserDetailsSubmit}>
        <Grid container spacing={4}>
          <Grid item lg={6}>
            <InputField
              labelText={"Company Name"}
              inputValue={companyName}
              type={"text"}
              inputTag={"input"}
              handleOnChange={setCompanyName}
              // isRequired={true}
            />
            <InputField
              labelText={"Address"}
              inputValue={primaryAddress}
              type={"text"}
              inputTag={"input"}
              handleOnChange={setPrimaryAddress}
              // isRequired={true}
            />
            <InputField
              labelText={"City"}
              inputValue={city}
              type={"text"}
              inputTag={"input"}
              handleOnChange={setCity}
              // isRequired={true}
            />
            <InputField
              labelText={"State / province"}
              inputValue={state}
              type={"text"}
              inputTag={"input"}
              handleOnChange={setState}
            />
            <InputField
              labelText={"Billing Email"}
              inputValue={billingEmail}
              type={"email"}
              inputTag={"input"}
              className="email-id"
              handleOnChange={setBillingEmail}
              // isRequired={true}
            />
          </Grid>
          <Grid item lg={6}>
            <InputField
              labelText={"VAT ID"}
              inputValue={VATId}
              type={"text"}
              inputTag={"input"}
              handleOnChange={setVATId}
            />
            <InputField
              labelText={"Address 2"}
              inputValue={secondaryAddress}
              type={"text"}
              inputTag={"input"}
              handleOnChange={setSecondaryAddress}
            />
            <InputField
              labelText={"ZIP or postal code"}
              inputValue={postalCode}
              type={"number"}
              inputTag={"input"}
              handleOnChange={setPostalCode}
              // isRequired={true}
            />
            <SelectDropdown
              labelText={"Country"}
              value={country}
              handleOnChange={handleCountryChange}
              options={[
                "India",
                "United States",
                "Switzerland",
                "Greece",
                "Italy",
                "Spain",
              ]}
              // isRequired={true}
            />
          </Grid>
        </Grid>
        <div className="submit-btn">
          <Button
            variant="contained"
            color="secondary"
            className="primary-btn"
            type="submit"
          >
            Subscribe
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BillingForm;
