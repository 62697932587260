import axios from 'axios';
import {
  FETCH_USER,
  ONBOARDED_USER,
  ACTION_ID,
  SET_ONBOARD_USER_DATA,
  USER_SIGN_IN,
  CURRENT_USER_DATA,
  LOGGED_IN_USER,
  USER_SIGN_UP,
  RESET_APP,
  IS_LOGGED_IN,
  TEAM_INVITE,
} from "./types";

export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/current_user');
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const onboardUser = (data) => {
  return {
    type: ONBOARDED_USER,
    payload: data
  }
}

export const setCurrentUserData = data => {
  return {
    type: CURRENT_USER_DATA,
    payload: data
  }
}

export const setOnboardingActionId = data => {
  return {
    type: ACTION_ID,
    payload: data
  }
}

export const setOnboardUserData = data => {
  return {
    type: SET_ONBOARD_USER_DATA,
    payload: data
  }
}

export const setUserSignInData = data => {
  return {
    type: USER_SIGN_IN,
    payload: data
  }
}

export const setLoggedInUserData = data => {
  return {
    type: LOGGED_IN_USER,
    payload: data
  }
}

export const userSignUpData = data => {
  return {
    type: USER_SIGN_UP,
    payload: data
  }
}

export const setInvitedTeamDetails = data => {
  return {
    type: TEAM_INVITE,
    payload: data
  }
}

export const isLoggedIn = loggedIn => {
  return {
    type: IS_LOGGED_IN,
    payload: loggedIn || false
  }
}

export const resetAppData = () => {
  return {
    type: RESET_APP,
    // payload: data
  }
}
