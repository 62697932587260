import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { questionsList } from "../../api/api";
import { COLLECTION_QUESTIONS_PAGE } from "../../constants/PageRoutes";

import Grid from "@material-ui/core/Grid";
import LockIcon from "@material-ui/icons/Lock";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { formatDateDMY, hasSuperUserPermission } from "../../utils";
import { QUERIES, DELETE_COLLECTION_CONFIRM } from "../../constants/Collection";
import { CANCEL, CONFIRM } from "../../constants/ButtonLabel";

import DeleteModal from "../Modals/DeleteModal/DeleteModal";

import { COLLECTION_MODIFY_OPTIONS, EDIT, DELETE } from "../../constants";
import UserAvatarIcon from "../UserAvatarIcon/UserAvatarIcon";

import "./Collection.scss";

const Collection = (props) => {
  const {
    renderEditCollectionModal,
    collecId,
    creatorID,
    collectionName,
    collectionDesp,
    colorCode,
    createdAt,
    handleCollectionDelete,
    userTankID,
    userClientID,
    openCollectionDeleteModal,
    userRole,
    collectionCreatorID,
    collectionScope,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [openMod, setOpen] = useState(false);

  const [queryCount, setQueryCount] = useState(null);

  const isLoggedInUserCreatedCollection = collectionCreatorID === userTankID;
  const hasCollectionModifyAccess =
    hasSuperUserPermission(userRole) || isLoggedInUserCreatedCollection;

  const isPrivateCollection = collectionScope === "Private";

  const fetchQueriesCountFromCollectionId = async (colId) => {
    const body = {
      collectionId: colId,
      tankUserID: userTankID,
      spaceID: userClientID,
    };
    axios
      .post(questionsList, body)
      .then((res) => setQueryCount(res?.data?.questions?.length));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event, id) => {
    const actionType = event.currentTarget.textContent;
    if (actionType === EDIT) {
      renderEditCollectionModal(id);
    }
    if (actionType === DELETE) {
      openCollectionDeleteModal(id);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchQueriesCountFromCollectionId(collecId);
  }, [collecId]);

  const confirmDelete = () => (
    <DeleteModal
      confirmDeleteMsg={DELETE_COLLECTION_CONFIRM}
      cancelText={CANCEL}
      confirmText={CONFIRM}
      onDelete={() => handleCollectionDelete(collecId)}
      close={handleClose}
      open={openMod}
    />
  );

  return (
    <>
      {openMod ? confirmDelete() : null}
      <Grid item md={4} key={collecId} className="collection-item">
        <div className="collection-item-block">
          <div
            className="collection-color"
            style={{ backgroundColor: `${colorCode}` }}
          ></div>
          <div className="collec-details">
            {isPrivateCollection ? (
              <div className="modify-collec">
                <LockIcon fontSize="small" />
              </div>
            ) : null}
            {hasCollectionModifyAccess ? (
              <div className="modify-collec">
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  fontSize="small"
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={openMenu}
                  onClose={handleMenuClose}
                  className="edit-menu collection"
                >
                  {COLLECTION_MODIFY_OPTIONS.map((item) => (
                    <MenuItem
                      className="menu-item"
                      key={item.optionId}
                      onClick={(e) => handleMenuClose(e, collecId)}
                    >
                      {item.optionText}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : null}
            <div className="details">
              <div className="collection-body">
                <Link
                  to={`${COLLECTION_QUESTIONS_PAGE}/${collecId}`}
                  className="redirect"
                >
                  <p className="collection-title">{collectionName}</p>
                  <p className="collection-desc">{collectionDesp}</p>
                </Link>
                <div className="creator-details-container">
                  <div className="queries-count">
                    {queryCount !== null && (
                      <p>
                        <span>{QUERIES}</span>: {queryCount}
                      </p>
                    )}
                  </div>
                  <div className="creator-info">
                    <div className="creator-info-dp">
                      <UserAvatarIcon userImage={""} size="small" />
                    </div>
                    <div className="creator-info-name">
                      <p>{creatorID}</p>
                      <p>{formatDateDMY(createdAt)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Collection;
