import React from "react";
import { Link } from 'react-router-dom';

import { ROUTES } from "../../constants";
import { CONTACT_US_PAGE, PRIVACY_POLICY_PAGE, INTEGRATIONS_PAGE, TERMS_AND_CONDITIONS_PAGE } from "../../constants/PageRoutes";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from '@material-ui/icons/Twitter';

import "./Footer.scss";

const Footer = props => {
  if (ROUTES.includes(props.location.pathname.split('/')[1]))  return null;

  return (
    <section className="footer-section">
      <div className="footer screen-max-width">
        <div className="links-block">
          <ul>
            <li>
              <Link to={CONTACT_US_PAGE}>Contact Us</Link>
            </li>
            <li>
              <Link to={PRIVACY_POLICY_PAGE}>Privacy policy</Link>
            </li>
            <li>
              <Link to={INTEGRATIONS_PAGE}>Integrations</Link>
            </li>
            <li>
              <Link to={TERMS_AND_CONDITIONS_PAGE}>{"Terms & conditions"}</Link>
            </li>
          </ul>
        </div>
        <div className="social-links-block">
          <a href="https://twitter.com/OrgTank" target="_blank">
            <TwitterIcon fontSize="large" />
          </a>
          <a href="https://www.linkedin.com/company/trytank/" target="_blank">
            <LinkedInIcon fontSize="large" />
          </a>
        </div>
        <div className="copyright-block">
          <p>&copy; Copyright 2021, Tank</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
