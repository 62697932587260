import React, { useState, useEffect } from "react";

import TankEditor from "../../TankEditor/TankEditor";
import Modal from "../Modal/Modal";

import { useGetCollections } from '../../../Hooks/getCollections';
import { collectionsList } from '../../../api/api';

import {
  EDIT_QUERY,
  EDIT_QUERY_TITLE,
  SELECT_COLLECTION,
  SUBMIT_QUERY
} from '../../../constants/Question';

import { CANCEL } from '../../../constants/ButtonLabel';

import InputField from '../../InputField/InputField';
import SelectDropdown from '../../SelectDropdown/SelectDropdown';

import './EditQuestionModal.scss';

const EditQuestionModal = (props) => {
  const { open, close, quesTitle, quesDesc, collecID, updateQues, collectionTitle, userClientID, handleQuestionSaveDraft, isSaveDraft, userTankID } = props;

  const [collectionName, setCollectionName] = useState('');
  const [collecId, setCollecId] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [isQuestionContentAdded, setIsQuestionContentAdded] = useState(false);
  const [questionTitle, setQuestionTitle] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [collections] = useGetCollections(`${collectionsList}/${userClientID}/${userTankID}`);

  const collectionNames = [];
  if (collections && collections.data) {
    collections.data.map(collec => {
      collectionNames.push({
        id: collec.collectionId,
        name: collec.collectionName
      });
    });
  }

  const divStyle = {
    display: open ? "flex" : "none",
  };

  useEffect(() => {
    setQuestionTitle(quesTitle);
    setCollecId(collecID);
  }, [quesTitle, collecID]);

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);

    if (content.trim() === '') {
      setIsQuestionContentAdded(false);
    } else {
      setIsQuestionContentAdded(true);
    }
  }

  const handleCollectionSelectChange = (event, child) => {
    setCollectionName(event.target.value);
    setCollecId(child.key.replace('.$', ''));
  };

  const focusHandle = (val) => {
    setQuestionTitle(val);
  }

  const handleQuestionTitleChange = (val) => {
    setQuestionTitle(val);
  }

  return (
    <div className="edit-ques-modal">
      <Modal
        modalTitle={EDIT_QUERY}
        modalDisplay={divStyle}
        close={close}
        cancelText={CANCEL}
        provisionType={SUBMIT_QUERY}
        isDisabled={isDisabled}
        handleProvisioning={() => updateQues(questionTitle, editorContent, collecId)}
        isDraftBtn={true}
        handleSaveAsDraft={() => handleQuestionSaveDraft(questionTitle, editorContent, collecId)}
      >
        <InputField
          labelText={EDIT_QUERY_TITLE}
          inputValue={questionTitle}
          type={'text'}
          inputTag={'input'}
          handleOnChange={handleQuestionTitleChange}
          handleOnFocus={focusHandle}
        />
        <SelectDropdown
          labelText={SELECT_COLLECTION}
          value={collectionName === '' ? collectionTitle : collectionName}
          handleOnChange={handleCollectionSelectChange}
          options={collectionNames}
        />
        <TankEditor setContent={quesDesc} editorChange={handleEditorChange} />
      </Modal>
    </div>
  );
};

export default EditQuestionModal;
