import {
  MY_POSTS_PAGE,
  COLLECTIONS_PAGE,
  MY_INTERESTS_PAGE,
  MY_DRAFTS_PAGE,
  DASHBOARD_PAGE,
  ANALYTICS_PAGE,
  TEAM_SETTINGS_PAGE,
  BILLING_SETTINGS_PAGE,
} from "../../constants/PageRoutes";

const MY_QUESTIONS = "My queries";
const MY_ANSWERS = "My responses";
export const COLLECTIONS = "Collections";
const MY_INTERESTS = "My interests";
const MY_DRAFTS = "My drafts";
export const MY_NOTIFICATIONS = "Catch up";
export const INVITE_TEAM_MEMBERS = "Invite team members";
const SETTINGS = "My settings";
const MY_POSTS = "My posts";
const PERSONAL_SETTINGS = "Personal settings";
const TEAM_SETTINGS = "Team settings";
const BILLING = "Billing";
const SPACE_SETTINGS = "Space settings";

export const drawerMenuItems = [
  {
    id: 1,
    menuItem: MY_POSTS,
    to: MY_POSTS_PAGE,
  },
  {
    id: 3,
    menuItem: COLLECTIONS,
    to: COLLECTIONS_PAGE,
  },
  {
    id: 4,
    menuItem: MY_INTERESTS,
    to: MY_INTERESTS_PAGE,
  },
  {
    id: 5,
    menuItem: MY_DRAFTS,
    to: MY_DRAFTS_PAGE,
  },
  {
    id: 6,
    menuItem: MY_NOTIFICATIONS,
    to: DASHBOARD_PAGE,
  },
  {
    id: 7,
    icon: "add-member",
    menuItem: INVITE_TEAM_MEMBERS,
    to: null,
  },
  {
    id: 8,
    menuItem: "Analytics",
    to: ANALYTICS_PAGE,
  },
];

export const settingsDrawerItems = [
  // {
  //   id: 1,
  //   menuItem: PERSONAL_SETTINGS,
  //   to: '/dashboard/settings/personal'
  // },
  {
    id: 2,
    menuItem: TEAM_SETTINGS,
    to: TEAM_SETTINGS_PAGE,
  },
  {
    id: 3,
    menuItem: BILLING,
    to: BILLING_SETTINGS_PAGE,
  },
];

export const menuItemsBottom = [
  {
    id: 8,
    menuItem: SETTINGS,
    to: TEAM_SETTINGS_PAGE,
  },
];
