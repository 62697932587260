import { combineReducers } from 'redux';

import { RESET_APP } from '../actions/types';

import authReducer from './authReducer';
import onboardReducer from './onboardReducer';
import currentUserReducer from './currentUserReducer';
import onboardingActionId from './onboardingActionId';
import setUserOnboardDataReducer from './setUserOnboardDataReducer';
import userSignInReducer from './userSignInReducer';
import loggedInUserReducer from './loggedInUserReducer';
import userSignUpReducer from './userSignUpReducer';
import isLoggedIn from './isLoggedIn';
import teamInviteReducer from './teamInviteReducer';

const appReducer = combineReducers({
  currentUser: authReducer,
  onboardedUser: onboardReducer,
  currentUserData: currentUserReducer,
  onboardingActionId: onboardingActionId,
  userOnboardData: setUserOnboardDataReducer,
  userSignInData: userSignInReducer,
  loggedInUserData: loggedInUserReducer,
  signedUpUser: userSignUpReducer,
  isUserLoggedIn: isLoggedIn,
  invitedTeam: teamInviteReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;
