import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import './DataError.scss';

const DataError = ({isPositionAbsolute = false, customClass = ''}) => {
  return (
    <div className={`data-error-container ${isPositionAbsolute ? 'pos-absolute-center' : '' } ${customClass}`}>
      <ErrorOutlineIcon fontSize="small" />
      <span>Oops! Something went wrong!</span>
    </div>
  );
};

export default DataError;
