import React from 'react';

import InputField from '../../InputField/InputField';
import Modal from '../Modal/Modal';

import './GetStartedModal.scss';

const GetStartedModal = props => {
  const {
    close,
    open,
    userEmail,
    setUserEmail,
    btnDisabled,
    redirectFromEmail,
    handleUserEmailKeyDown,
  } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  return (
    <div className="get-started-modal">
      <Modal
        close={close}
        modalDisplay={divStyle}
        isDisabled={false}
      >
        <InputField
          inputValue={userEmail}
          type={"email"}
          labelText={"Enter email address"}
          inputTag={"input"}
          placeholderText={'name@company.com'}
          handleOnChange={setUserEmail}
          hasIcon={true}
          handleOnSumit={redirectFromEmail}
          isBtnDisabled={btnDisabled}
          handleOnKeyDown={handleUserEmailKeyDown}
        />
      </Modal>
    </div>
  );
}

export default GetStartedModal;
