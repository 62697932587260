import React, { useState } from 'react';

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { DRAFT_QUESTION_OPTIONS, EDIT, DELETE } from '../../constants';

import './AnswerItem.scss';

const AnswerItem = props => {
  const { draftAnswer, toggleEditAnswerDraftModal, openDeleteModal, answerDraftId } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const createMarkup = (html) => {
    return { __html: html };
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = (e, quesId, ansDesc, draftId) => {
    const actionType = e.currentTarget.textContent;
    if (actionType === EDIT) {
      toggleEditAnswerDraftModal(quesId, ansDesc, answerDraftId);
    }
    if (actionType === DELETE) {
      openDeleteModal(draftId, 'answer');
    }
    setAnchorEl(null);
  }

  return (
    <>
      <tr key={answerDraftId}>
        <td className='answer'>
          <span className='ques-title'>{draftAnswer.quesTitle}</span>
          <p className='content' dangerouslySetInnerHTML={createMarkup(draftAnswer.ansDesp.length > 150 ? `${draftAnswer.ansDesp.slice(0, 155)}...` : draftAnswer.ansDesp)} />
        </td>
        <td>
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              fontSize="small"
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openMenu}
              onClose={handleMenuClose}
            >
              {DRAFT_QUESTION_OPTIONS.map(item => (
                <MenuItem key={item.optionId} className={`menu-item ${draftAnswer.quesId}`} onClick={(e) => handleMenuClose(e, draftAnswer.quesId, draftAnswer.ansDesp, draftAnswer.draftID)}>
                  {item.optionText}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </td>
      </tr>
    </>
  )
}

export default AnswerItem;
