import React from 'react';

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import './Widget.scss';

const Widget = props => {
  const { title, children, icon, tasks, disableWidgetIcon, disableWidget } = props;
  return (
    <div className='widget'>
      <div className='widget-title'>
        <p>{title}</p>
        <div className='widget-icons'>
          <div className='widget-icon'>
            <img src={require(`../../../assets/images/${icon}`)} alt={icon} />
          </div>
          {disableWidgetIcon ? <CloseOutlinedIcon onClick={disableWidget} className='widget-close-icon' fontSize="default" /> : null }
        </div>
      </div>
      <div className={`widget-content ${tasks && tasks.length === 0 ? 'no-tasks-available' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default Widget;
