import React from 'react';

import Modal from '../Modal/Modal';
import InputField from '../../InputField/InputField';

import './RejectAnswerModal.scss';

const RejectAnswerModal = props => {
  const { open, close, inputValue, handleOnChange, rejectAnswerModalBtnDisabled, submitRejectResponse } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  return (
    <div className="reject-answer-modal">
      <Modal
        close={close}
        modalDisplay={divStyle}
        isDisabled={rejectAnswerModalBtnDisabled}
        cancelText={'Cancel'}
        provisionType={'Submit'}
        handleProvisioning={submitRejectResponse}
      >
        <div className='tank-modal delete'>
          <h3 className="confirm-delete-msg">{'Enter description'}</h3>
          <InputField
            inputValue={inputValue}
            type={'text'}
            inputTag={'textarea'}
            handleOnChange={handleOnChange}
          />
        </div>
      </Modal>
    </div>
  )
}

export default RejectAnswerModal;
