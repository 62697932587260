/* Create new collection */
export const CREATE_COLLECTION_TITLE = 'Create a collection';
export const ENTER_COLLECTION_TITLE = 'Enter collection title';
export const ENTER_COLLECTION_DESCRIPTION = 'Enter collection description';
export const COLLECTION_SCOPE = 'Select collection scope';
export const SELECT_COLOR = 'Select color';

/* Edit collection */
export const EDIT_COLLECTION = 'Edit collection';
export const EDIT_COLLECTION_TITLE = 'Edit collection title';
export const EDIT_COLLECTION_DESCRIPTION = 'Edit collection description';
export const EDIT_COLLECTION_SCOPE = 'Edit collection scope';
export const EDIT_COLOR = 'Edit color';

export const QUERIES = 'Queries';
export const DELETE_COLLECTION_CONFIRM = 'Are you sure you want to delete this collection?';
export const COLLECTION_CREATOR_LABEL = 'Creator';
