import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Team from "./Team/Team";
import AddIntegration from "./AddIntegration/AddIntegration";
import Checkout from "./Billing/Checkout/Checkout";
import BillingSuccess from "./Billing/BillingSuccess/BillingSuccess";
import BillingFailure from "./Billing/BillingFailure/BillingFailure";
import BillingDetailsPage from "./BillingDetailsPage/BillingDetailsPage";

import {
  SETTINGS_PAGE,
  TEAM_SETTINGS_PAGE,
  SETTINGS_PAGE_ADD_INTEGRATION,
  BILLING_SETTINGS_PAGE,
  BILLING_SUCCESS_PAGE,
  BILLING_FAILURE_PAGE,
} from "../../constants/PageRoutes";

import "./SettingsPage.scss";
class SettingsPage extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.currentTeamData &&
      this.props.currentTeamData &&
      nextProps.currentTeamData.spaceID !== this.props.currentTeamData.spaceID
    );
  }

  renderSettingsRoutes = () => {
    const {
      currentTeamData,
      location: { pathname },
    } = this.props;

    if (pathname === SETTINGS_PAGE || pathname === `${SETTINGS_PAGE}/`) {
      return <Redirect to={TEAM_SETTINGS_PAGE} />;
    }

    return (
      <Switch>
        <Route
          exact
          path={TEAM_SETTINGS_PAGE}
          component={(props) => (
            <Team {...this.props} currentTeamData={currentTeamData} />
          )}
        />
        <Route
          exact
          path={SETTINGS_PAGE_ADD_INTEGRATION}
          component={(props) => (
            <AddIntegration {...this.props} currentTeamData={currentTeamData} />
          )}
        />
        <Route
          exact
          path={`${BILLING_SETTINGS_PAGE}/checkout`}
          component={Checkout}
        />
        <Route
          exact
          path={BILLING_SETTINGS_PAGE}
          component={(props) => (
            <BillingDetailsPage
              {...this.props}
              currentTeamData={currentTeamData}
            />
          )}
        />
        <Route
          exact
          path={BILLING_SUCCESS_PAGE}
          component={() => <BillingSuccess currentTeamData={currentTeamData} />}
        />
        <Route exact path={BILLING_FAILURE_PAGE} component={BillingFailure} />
      </Switch>
    );
  };

  render() {
    return <div className="settings">{this.renderSettingsRoutes()}</div>;
  }
}

export default SettingsPage;
