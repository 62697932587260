import React, { Component } from "react";
import axios from "axios";

import {
  getAllUsersFromSpace,
  changeTeamMemberRole,
  getSlackChannelMembers,
  onboardSelectedSlackUsers,
  uninstallSlackIntegration,
  deleteSpace,
  getSlackState,
} from "../../../api/api";

import { isOk } from "../../../utils";

import Button from "@material-ui/core/Button";

import TeamName from "./TeamName/TeamName";
import TeamMembers from "./TeamMembers/TeamMembers";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";
import DeleteModal from "../../../components/Modals/DeleteModal/DeleteModal";
import { CONFIRM, CANCEL } from "../../../constants/ButtonLabel";
import { LOGOUT_PAGE } from "../../../constants/PageRoutes";
import Toast from "../../../components/Toast/Toast";

import ChooseAdminModal from "../../../components/Modals/ChooseAdminModal/ChooseAdminModal";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal/ConfirmationModal";

import Cookies from "js-cookie";

import "./Team.scss";
class Team extends Component {
  state = {
    teamName: "",
    currentTeamName: "",
    showMembersConfig: false,
    showButtonDropdown: false,
    teamUsers: [],
    activeUserId: null,
    setAnchorEl: null,
    adminCheckErr: false,
    slackAccountExists: false,
    selectedChannelMembers: [],
    selectedMembers: [],
    showSlackMembers: false,
    isSlackOnboarded: false,
    openModal: false,
    openSpaceDeleteModal: false,
    isSpaceDeleting: false,
    showActionConfirmation: false,
    showSlackMembersOnboardedActionConfirmation: false,
    confirmationModalOpen: false,
  };

  componentDidMount() {
    const {
      location: { search },
      currentTeamData,
    } = this.props;
    const urlParams = new URLSearchParams(search);
    const slackOnboard = urlParams.get("slackonboard");

    const currTeamName = currentTeamData?.teamName;
    const currSpaceID = currentTeamData?.spaceID;

    this.setState({ teamName: currTeamName, currentTeamName: currSpaceID });

    this.getUsersFromSpace(currSpaceID);

    if (slackOnboard) {
      this.setState({ isSlackOnboarded: true, openModal: true });
    }
  }

  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  openConfirmationModal = () => {
    this.setState({ confirmationModalOpen: true });
  };

  getUsersFromSpace = async (spaceId) => {
    const userData = await axios.get(`${getAllUsersFromSpace}${spaceId}`);

    const users = userData?.data?.data;
    const hasSlackAccount = userData?.data?.slackAccountExists.exist;

    this.setState(
      { teamUsers: users, slackAccountExists: hasSlackAccount },
      () => {
        if (this.state.slackAccountExists) this.getSlackMembers();
      }
    );
  };

  checkAdminRolePresent = (id) => {
    const users = this.state.teamUsers.filter((user) => id !== user.tankUserID);
    let isAdminRolePresent = false;

    users.forEach((user) => {
      if (user.role === "admin") return (isAdminRolePresent = true);
    });

    return isAdminRolePresent;
  };

  closeAdminCheckModal = () => {
    this.setState({ adminCheckErr: false });
  };

  selectedRole = async (role, id) => {
    const { currentTeamData } = this.props;
    const currSpaceID = currentTeamData?.spaceID;

    const body = {
      role: role,
    };

    if (this.checkAdminRolePresent(id)) {
      await axios.patch(`${changeTeamMemberRole}/${id}/${currSpaceID}`, body);
      this.getUsersFromSpace(currSpaceID);
    } else {
      this.setState({ adminCheckErr: true });
    }
  };

  getSlackMembers = () => {
    const { currentTeamData } = this.props;
    const currSpaceID = currentTeamData?.spaceID;

    const body = {
      spaceID: currSpaceID,
    };

    axios.post(getSlackChannelMembers, body).then((res) => {
      const resData = res?.data?.data;
      this.setState({ selectedChannelMembers: resData });
    });
  };

  handleOptionSelection = (val) => {
    this.setState({ selectedMembers: val });
  };

  onboardSlackUser = () => {
    const { selectedMembers } = this.state;
    const { currentTeamData } = this.props;

    const currTeamName = currentTeamData?.teamName;
    const currSpaceID = currentTeamData?.spaceID;

    const body = {
      SlackUsersDetailsArray: selectedMembers,
      spaceID: currSpaceID,
      teamName: currTeamName,
    };

    axios
      .post(onboardSelectedSlackUsers, body)
      .then(() => this.getUsersFromSpace(currSpaceID))
      .then(() =>
        this.setState({
          selectedChannelMembers: [],
          showSlackMembers: false,
          showSlackMembersOnboardedActionConfirmation: true,
        })
      );
  };

  toggleSlackMembersConfig = () => {
    this.setState({ showSlackMembers: !this.state.showSlackMembers });
  };

  removeSlackInstall = () => {
    const { currentTeamData } = this.props;

    const currSpaceID = currentTeamData?.spaceID;

    const body = {
      spaceID: currSpaceID,
    };

    axios
      .post(uninstallSlackIntegration, body)
      .then(() => this.setState({ slackAccountExists: false }))
      .then(() => this.getUsersFromSpace(currSpaceID))
      .then(() => this.closeConfirmationModal())
      .then(() => this.setState({ showActionConfirmation: true }));
  };

  handleOpenSpaceDeleteModal = () => {
    this.setState({ openSpaceDeleteModal: true });
  };

  handleCloseSpaceDeleteModal = () => {
    this.setState({ openSpaceDeleteModal: false });
  };

  handleSpaceDelete = () => {
    const { currentTeamData } = this.props;
    const currentSpaceId = currentTeamData?.spaceID;

    this.setState({ isSpaceDeleting: true });

    axios
      .delete(`${deleteSpace}/${currentSpaceId}`)
      .then((res) => {
        if (isOk(res)) {
          window.location.href = LOGOUT_PAGE;
        }
      })
      .finally(() => this.setState({ isSpaceDeleting: false }));
  };

  configureSlackState = async () => {
    try {
      const response = await fetch(`https://trytank.io/${getSlackState}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200) {
        const cookieData = Cookies.get("stateParam");
        const redirectUrl = `https://slack.com/oauth/v2/authorize?client_id=1063401163719.1153377659252&scope=channels:read,chat:write,chat:write.public,commands,users:read,users:read.email&state=${cookieData}`;

        window.location.href = redirectUrl;
      }
    } catch (error) {
      console.error(`Download error: ${error?.message}`);
    }
  };

  closeToastNotify = () => {
    this.setState({ showActionConfirmation: false });
  };

  closeSlackMembersOnboardedToastNotify = () => {
    this.setState({ showSlackMembersOnboardedActionConfirmation: false });
  };

  render() {
    const {
      teamName,
      teamUsers,
      currentTeamName,
      adminCheckErr,
      slackAccountExists,
      selectedChannelMembers,
      showSlackMembers,
      openSpaceDeleteModal,
      isSpaceDeleting,
      showActionConfirmation,
      confirmationModalOpen,
      showSlackMembersOnboardedActionConfirmation,
    } = this.state;

    const { currentTeamData } = this.props;
    const currSpaceID = currentTeamData?.spaceID;

    return (
      <div className="team-settings screen-max-width page-top">
        <Toast
          isToastOpen={showActionConfirmation}
          closeToast={this.closeToastNotify}
          message={"Slack uninstalled successfully"}
        />
        <Toast
          isToastOpen={showSlackMembersOnboardedActionConfirmation}
          closeToast={this.closeSlackMembersOnboardedToastNotify}
          message={"Slack members onboarded"}
        />
        <ConfirmationModal
          message={"Are you sure you want to uninstall Slack?"}
          onConfirm={this.removeSlackInstall}
          close={this.closeConfirmationModal}
          open={confirmationModalOpen}
        />
        {adminCheckErr ? (
          <ChooseAdminModal
            open={adminCheckErr}
            close={this.closeAdminCheckModal}
          />
        ) : null}
        <TeamName
          teamName={teamName}
          currentTeamName={currentTeamName}
          currentSpaceID={currSpaceID}
        />
        <TeamMembers
          teamUsers={teamUsers}
          selectedRole={this.selectedRole}
          activeSpaceID={currSpaceID}
          getUsersFromSpace={this.getUsersFromSpace}
        />
        <div className="team-config integration">
          <p className="title">Add integration</p>
          <p className="help-text">Add integration to your team</p>
          <div className="config-content">
            <div className="integration-method">
              <img
                src={require("../../../assets/images/slack-logo.png")}
                alt="slack integration"
              />
              <p>Slack</p>
            </div>
            {!slackAccountExists ? (
              <Button
                className="primary-btn"
                variant="contained"
                color="secondary"
                onClick={this.configureSlackState}
              >
                Install
              </Button>
            ) : (
              <Button
                onClick={this.openConfirmationModal}
                className="primary-btn"
                variant="contained"
                color="secondary"
              >
                Uninstall
              </Button>
            )}
          </div>
          {slackAccountExists ? (
            <div className="config-content slack">
              <div className="section-toggle">
                <p className="title">Slack members</p>
                <Button
                  onClick={this.toggleSlackMembersConfig}
                  variant="contained"
                  color="secondary"
                  className="primary-btn"
                >
                  {showSlackMembers ? "Close" : "Expand"}
                </Button>
              </div>
              <p className="help-text">Onboard Slack users</p>
              {showSlackMembers ? (
                <div className="slack-users">
                  <SearchDropdown
                    label={""}
                    options={
                      selectedChannelMembers && selectedChannelMembers.length
                        ? selectedChannelMembers
                        : []
                    }
                    teamMemberNameKey={"real_name"}
                    handleOptionSelection={this.handleOptionSelection}
                  />
                  <div className="btn-block">
                    <Button
                      onClick={this.onboardSlackUser}
                      className="primary-btn small"
                    >
                      Onboard
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="team-config deletion">
          <p className="title">Space deletion</p>
          <p className="help-text">
            Once you delete this space, all associated data will be removed.
            There is no turning back.
          </p>
          <div className="config-content">
            <Button
              variant="contained"
              color="secondary"
              className="primary-btn"
              onClick={this.handleOpenSpaceDeleteModal}
            >
              Delete space
            </Button>
          </div>
        </div>
        {openSpaceDeleteModal ? (
          <DeleteModal
            confirmDeleteMsg={
              "This action will delete all associated content for this space. Are you sure you want to go ahead?"
            }
            cancelText={CANCEL}
            confirmText={CONFIRM}
            onDelete={this.handleSpaceDelete}
            close={this.handleCloseSpaceDeleteModal}
            open={openSpaceDeleteModal}
            isBtnDisabled={isSpaceDeleting}
          />
        ) : null}
      </div>
    );
  }
}

export default Team;
