import React from "react";
import Button from "@material-ui/core/Button";
import Spinner from "../../../Loader/Spinner";

import "./ButtonGroup.scss";

const ButtonGroup = (props) => {
  const {
    close,
    isDisabled,
    handleProvisioning,
    cancelText,
    provisionType,
    isDraftBtn,
    isDraftBtnDisabled,
    handleSaveAsDraft,
    moveToTrash,
    isBtnDisabled = false
  } = props;

  return (
    <div className="btn-group">
      {cancelText && cancelText.length ? (
        <Button
          onClick={close}
          className="primary-btn-modal"
          variant="contained"
          color="primary"
          disabled={isBtnDisabled}
        >
          {cancelText}
        </Button>
      ) : null}
      {provisionType && provisionType.length ? (
        <Button
          disabled={isDisabled || isBtnDisabled}
          onClick={handleProvisioning}
          variant="contained"
          color="secondary"
          className="secondary-btn-modal"
        >
          {provisionType}
        </Button>
      ) : null}
      {isDraftBtn ? (
        <Button
          onClick={handleSaveAsDraft}
          disabled={isDraftBtnDisabled}
          variant="contained"
          color="secondary"
          className="draft-btn-modal"
        >
          Save as draft
        </Button>
      ) : null}
      {
        moveToTrash ? (
          <Button
            // onClick={handleSaveAsDraft}
            // disabled={isDraftBtnDisabled}
            variant="contained"
            color="secondary"
            className="move-to-trash-btn"
          >
            {moveToTrash}
          </Button>
        ) : null
      }
      {isBtnDisabled ? <Spinner color="blue" size={15} /> : null}
    </div>
  );
};

export default ButtonGroup;
