import React from 'react';

import Button from '@material-ui/core/Button';

import './NewQuestionButton.scss';

const NewQuestionButton = (props) => {
  const { handleOnClick, btnText } = props;
  return (
    <div className="new-ques-btn btn-outline">
      <Button onClick={handleOnClick} variant="contained" color="primary">
        {btnText}
      </Button>
    </div>
  );
}

export default NewQuestionButton;
