import React, { useState } from "react";

import axios from "axios";

import {
  USER_ROLES,
  ROLE_ADMIN,
  ROLE_SUPERVISOR,
  ROLE_MEMBER,
} from "../../../../constants/UserRoles";

import { userDeboardFromSpace } from "../../../../api/api";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationModal from "../../../../components/Modals/ConfirmationModal/ConfirmationModal";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem);

const TeamMembers = (props) => {
  const [showMembersConfig, setShowMembersConfig] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [toDeleteMember, setToDeleteMember] = useState({
    memberTankUserId: "",
  });

  const { teamUsers, selectedRole, activeSpaceID, getUsersFromSpace } = props;

  const toggleMembersConfig = () => {
    setShowMembersConfig(!showMembersConfig);
  };

  const openRolesMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setActiveUserId(id);
  };

  const closeRolesMenu = (role = "", id = "") => {
    if (USER_ROLES.includes(role) && id !== "") {
      selectedRole(role, id);
    }
    setAnchorEl(null);
    setActiveUserId(null);
  };

  const getUserPermission = (role) => {
    if (role === ROLE_MEMBER) return "Member";
    if (role === ROLE_ADMIN) return "Admin";
    if (role === "moderator") return "Moderator";
    if (role === ROLE_SUPERVISOR) return "Supervisor";
  };

  const deleteTeamMember = async () => {
    const { memberTankUserId } = toDeleteMember;

    await axios.delete(
      `${userDeboardFromSpace}/${memberTankUserId}/${activeSpaceID}`
    );

    getUsersFromSpace(activeSpaceID);
  };

  const openConfirmationModal = (memberTankUserId) => {
    setConfirmationModalOpen(true);
    setToDeleteMember({ memberTankUserId });
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setToDeleteMember({ memberTankUserId: "" });
  };

  return (
    <div className="team-config team-members">
      <ConfirmationModal
        message={"Are you sure you want to remove this member from the space?"}
        onConfirm={deleteTeamMember}
        close={closeConfirmationModal}
        open={confirmationModalOpen}
      />
      <div className="section-toggle">
        <p className="title">Team members</p>
        <Button
          onClick={toggleMembersConfig}
          variant="contained"
          color="secondary"
          className="primary-btn"
        >
          {showMembersConfig ? "Close" : "Expand"}
        </Button>
      </div>
      <p className="help-text">Manage your team members</p>
      {showMembersConfig ? (
        <div className="config-content">
          <div className="table-card">
            {teamUsers.length > 0 &&
              teamUsers.map((user) => (
                <div key={user.tankUserID} className="member">
                  <p className="name">
                    {user.name} {user?.lastName}
                  </p>
                  <div className="member-actions">
                    <Button
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      color="primary"
                      onClick={(e) => openRolesMenu(e, user.tankUserID)}
                      className={`primary-btn`}
                    >
                      {getUserPermission(user.role)}
                    </Button>
                    {user?.role !== ROLE_ADMIN ? (
                      <DeleteIcon
                        onClick={() =>
                          openConfirmationModal(user.tankUserID, user.name)
                        }
                        fontSize="small"
                        color="error"
                        className="delete-member"
                      />
                    ) : null}
                  </div>
                  <StyledMenu
                    id="customized-menu"
                    className="teams-dropdown roles"
                    anchorEl={anchorEl}
                    keepMounted
                    open={activeUserId === user.tankUserID ? true : false}
                    onClose={closeRolesMenu}
                  >
                    {user.role === ROLE_MEMBER ? (
                      <div>
                        <StyledMenuItem>
                          <div
                            className="role"
                            onClick={() =>
                              closeRolesMenu(ROLE_ADMIN, activeUserId)
                            }
                          >
                            <p className="role-title">Admin</p>
                            <p className="role-desc">
                              Admins can invite new team members and manage all
                              members’ roles and rights.
                            </p>
                          </div>
                        </StyledMenuItem>
                        <StyledMenuItem>
                          <div
                            className="role"
                            onClick={() =>
                              closeRolesMenu(ROLE_SUPERVISOR, activeUserId)
                            }
                          >
                            <p className="role-title">Supervisor</p>
                            <p className="role-desc">
                              Supervisor manages all team preferences.
                            </p>
                          </div>
                        </StyledMenuItem>
                      </div>
                    ) : null}
                    {user.role === ROLE_ADMIN ? (
                      <div>
                        <StyledMenuItem>
                          <div
                            className="role"
                            onClick={() =>
                              closeRolesMenu(ROLE_MEMBER, activeUserId)
                            }
                          >
                            <p className="role-title">Member</p>
                            <p className="role-desc">
                              By default, members can invite new members but
                              can’t manage other members’ roles or rights.
                            </p>
                          </div>
                        </StyledMenuItem>
                        <StyledMenuItem>
                          <div
                            className="role"
                            onClick={() =>
                              closeRolesMenu(ROLE_SUPERVISOR, activeUserId)
                            }
                          >
                            <p className="role-title">Supervisor</p>
                            <p className="role-desc">
                              Supervisor manages all team preferences.
                            </p>
                          </div>
                        </StyledMenuItem>
                      </div>
                    ) : null}
                    {user.role === ROLE_SUPERVISOR ? (
                      <div>
                        <StyledMenuItem>
                          <div
                            className="role"
                            onClick={() =>
                              closeRolesMenu(ROLE_ADMIN, activeUserId)
                            }
                          >
                            <p className="role-title">Admin</p>
                            <p className="role-desc">
                              Admins can invite new team members and manage all
                              members’ roles and rights.
                            </p>
                          </div>
                        </StyledMenuItem>
                        <StyledMenuItem>
                          <div
                            className="role"
                            onClick={() =>
                              closeRolesMenu(ROLE_MEMBER, activeUserId)
                            }
                          >
                            <p className="role-title">Member</p>
                            <p className="role-desc">
                              By default, members can invite new members but
                              can’t manage other members’ roles or rights.
                            </p>
                          </div>
                        </StyledMenuItem>
                      </div>
                    ) : null}
                  </StyledMenu>
                </div>
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (nextProps.activeSpaceID !== prevProps.activeSpaceID) {
    return true;
  }
  return false;
};

export default React.memo(TeamMembers, areEqual);
