import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  formatDateFromNow,
  hasAdminModificationPriviledge,
  isCurrentUserCreated,
  formatDateDMY,
} from "../../utils";
import { getCollectionDetailsByID } from "../../api/api";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import UserAvatarIcon from "../UserAvatarIcon/UserAvatarIcon";

import {
  QUESTION_OPTIONS,
  QUESTION_OPTIONS_ADMIN,
  EDIT,
  MOVE,
  DELETE,
  FOLLOW,
  UNFOLLOW,
  DRAFT_QUESTION_OPTIONS,
} from "../../constants";
import { QUESTION_DETAIL_PAGE } from "../../constants/PageRoutes";
import { DELETE_QUERY_CONFIRM } from "../../constants/Question";
import { CANCEL, CONFIRM } from "../../constants/ButtonLabel";
import { ORIGIN_TANK } from "../../constants";

import DeleteModal from "../Modals/DeleteModal/DeleteModal";

import "./Question.scss";
class Question extends Component {
  state = {
    openMod: false,
    anchorEl: null,
    follow: FOLLOW,
    collectionName: "",
    collectionColor: "",
  };

  componentDidMount() {
    const { isFollowed, toRenderData } = this.props;
    if (isFollowed) {
      this.setState({ follow: UNFOLLOW });
    } else {
      this.setState({ follow: FOLLOW });
    }
    if (toRenderData) {
      this.fetchCollectionName();
    }
  }

  handleClose = () => {
    this.setState({ openMod: false });
  };

  handleOpenMod = () => {
    this.setState({ openMod: true });
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (event, id) => {
    const actionType = event.currentTarget.textContent;
    const {
      renderEditDraftQuesModal,
      toRenderData,
      renderEditQuestionModal,
      collecId,
      openDeleteModal,
      quesDraftId,
      openMoveQuestionModal,
    } = this.props;

    if (actionType === EDIT) {
      if (!toRenderData) {
        renderEditDraftQuesModal(id, quesDraftId);
      } else {
        renderEditQuestionModal(id, collecId);
      }
    }

    if (actionType === MOVE) {
      openMoveQuestionModal(id);
    }

    if (actionType === DELETE) {
      if (!toRenderData) {
        openDeleteModal(quesDraftId, "question");
      } else {
        this.handleOpenMod();
      }
    }

    this.setState({ anchorEl: null });
  };

  followQuestion = (quesId) => {
    const { handleFollowQuestion } = this.props;
    this.setState({ follow: UNFOLLOW });
    handleFollowQuestion(quesId);
  };

  unFollowQuestion = (quesId) => {
    const { handleUnfollowQuestion } = this.props;
    this.setState({ follow: FOLLOW });
    handleUnfollowQuestion(quesId);
  };

  confirmDelete = () => (
    <DeleteModal
      confirmDeleteMsg={DELETE_QUERY_CONFIRM}
      cancelText={CANCEL}
      confirmText={CONFIRM}
      onDelete={() =>
        this.props.handleQuestionDelete(this.props.quesId, this.props.collecId)
      }
      close={this.handleClose}
      open={this.state.openMod}
      isBtnDisabled={this.props.isDeleteInProgress}
    />
  );

  fetchCollectionName = () => {
    const { toRenderCollectionName, collecId } = this.props;
    if (toRenderCollectionName) {
      axios.get(`${getCollectionDetailsByID}${collecId}`).then((res) => {
        const collectionData = res.data && res.data.data && res.data.data[0];
        if (collectionData !== undefined && collectionData !== null) {
          this.setState({
            collectionName: collectionData.collectionName,
            collectionColor: collectionData.colorCode,
          });
        }
      });
    }
  };

  render() {
    const {
      quesId,
      quesTitle,
      userName,
      answers,
      handleAnswerModalOpen,
      toRenderData,
      origin,
      createdAt,
      userDP,
      userRole,
      currentTeamData,
      creatorId,
      hasModifyOptions = true,
    } = this.props;

    const isCreatedByLoggedInUser = isCurrentUserCreated(
      creatorId,
      currentTeamData?.tankUserID
    );
    const hasModificationAccess = hasAdminModificationPriviledge(
      userRole,
      creatorId,
      currentTeamData?.tankUserID
    );

    const openMenu = Boolean(this.state.anchorEl);

    const QUESTION_MODIFY_OPTIONS = !toRenderData
      ? DRAFT_QUESTION_OPTIONS
      : QUESTION_OPTIONS;

    return (
      <>
        {this.confirmDelete()}
        <tr key={quesId} className={answers === 0 ? "no-responses" : ""}>
          <td className="ques-title">
            {toRenderData ? (
              <Link to={`${QUESTION_DETAIL_PAGE}/${quesId}`}>{quesTitle}</Link>
            ) : (
              <p>{quesTitle}</p>
            )}
          </td>
          {toRenderData ? (
            <>
              <td className="responses">
                {answers}
                {/* <Button
                  onClick={() => handleAnswerModalOpen(quesId, quesTitle)}
                  className="primary-btn add-ans"
                >
                  <AddIcon fontSize="small" />
                </Button> */}
              </td>
              <td className="origin-img">
                {origin === ORIGIN_TANK ? (
                  <img
                    src={require("../../assets/images/t-logo.png")}
                    alt="origin tank"
                  />
                ) : (
                  <img
                    src={require("../../assets/images/slack-logo.png")}
                    alt="origin slack"
                  />
                )}
              </td>
              {this.state.collectionName ? (
                <td className="collection-name-cell">
                  <span className="collec-name">
                    <span
                      className="collec-border"
                      style={{
                        borderColor: `${this.state.collectionColor}`,
                        backgroundColor: `${this.state.collectionColor}`,
                        borderWidth: "2px",
                      }}
                    ></span>
                    {this.state.collectionName}
                  </span>
                </td>
              ) : null}
              <td className="creator">
                <UserAvatarIcon
                  userImage={userDP?.[0]?.dp || userDP}
                  size="medium"
                />
                <span className="user-name">
                  {userName}
                  <span className="timestamp">
                    {formatDateFromNow(createdAt)}
                  </span>
                </span>
              </td>
              <td className="follow-cell">
                {this.state.follow === FOLLOW ? (
                  <Button
                    onClick={() => this.followQuestion(quesId)}
                    className="primary-btn"
                  >
                    + {FOLLOW}
                  </Button>
                ) : (
                  <Button
                    onClick={() => this.unFollowQuestion(quesId)}
                    className="primary-btn unfollow"
                  >
                    + {UNFOLLOW}
                  </Button>
                )}
              </td>
              <td>{formatDateDMY(createdAt)}</td>
            </>
          ) : null}
          <td className="text-right">
            {(isCreatedByLoggedInUser || hasModificationAccess) &&
            hasModifyOptions ? (
              <div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenuOpen}
                  fontSize="small"
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={openMenu}
                  onClose={this.handleMenuClose}
                >
                  {hasModificationAccess
                    ? QUESTION_OPTIONS_ADMIN.map((item) => (
                        <MenuItem
                          key={item.optionId}
                          className="menu-item"
                          onClick={(e) => this.handleMenuClose(e, quesId)}
                        >
                          {item.optionText}
                        </MenuItem>
                      ))
                    : QUESTION_MODIFY_OPTIONS.map((item) => (
                        <MenuItem
                          key={item.optionId}
                          className="menu-item"
                          onClick={(e) => this.handleMenuClose(e, quesId)}
                        >
                          {item.optionText}
                        </MenuItem>
                      ))}
                </Menu>
              </div>
            ) : null}
          </td>
        </tr>
      </>
    );
  }
}

export default Question;
