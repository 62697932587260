import React from "react";

const DefaultAvatarImg = () => (
  <img
    src={require("../../assets/images/default-avatar.png")}
    alt="user image"
  />
);

export default DefaultAvatarImg;
