import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import './SearchDropdown.scss';

const SearchDropdown = props => {
  const { label, options, handleOptionSelection, teamMemberNameKey, selectedGurus } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [op, setOp] = useState(options ? options : '');
  const [sel, setSel] = useState(selectedGurus ? selectedGurus : '');

  const selectedCollectionGurus = op.filter(p => sel.includes(p.tankUserID));

  return (
    <div className='search-dropdown'>
      <p className='title'>{label}</p>
      <Autocomplete
        onChange={(e, value) => handleOptionSelection(value)}
        multiple
        id="checkboxes-tags-demo"
        options={options}
        disableCloseOnSelect
        disablePortal={true}
        defaultValue={selectedCollectionGurus ? selectedCollectionGurus : null}
        getOptionLabel={option => option[teamMemberNameKey]}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[teamMemberNameKey]}
          </>
        )}
        renderInput={params => (
          <TextField {...params} variant="outlined" />
        )}
      />
    </div>
  );
}

export default SearchDropdown;
