import React from 'react';

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import './IntegrationsPage.scss';

const IntegrationsPage = () => {
  return (
    <div className='integrations-page'>
      <div className='screen-max-width page-top full-page-height'>
        <div className="integrations-container screen-max-width">
          <h1 className='integration-title'>Integrates with your team's workflow</h1>
          <div className='integration'>
            <div className='slack'>
              <p className='slack-label'>Slack</p>
              {/* <Button
                variant="contained"
                color="secondary"
                className='primary-btn rounded-btn'
              >
                + Add to Slack
              </Button> */}
            </div>
            <p className='slack-desc'>
            Share queries directly from Slack channels to Tank.
            Search for Tank queries and share them directly from Slack using "/tank-search".
            </p>
            <p className='slack-desc'>
              Connect your team accounts:
              <ol>
                <li>You must be an <strong>admin</strong> or <strong>supervisor</strong> to install the integration.</li>
                <li>Click on <strong>My Settings</strong> tab to get to your Settings.</li>
                <li>Under <strong>Add Integration</strong>, click on "Install" to the right of Slack.</li>
              </ol>
            </p>
          </div>
        </div>
        <div className='features-list'>
          <div className='feature'>
            <img src={require('../../assets/images/slack-int.png')} alt='sneak int' />
          </div>
          <div className='feature'>
            <img src={require('../../assets/images/Search_Slack_Creative.png')} alt='search' />
          </div>
        </div>
      </div>
      <div className='integrations-wrapper alt-grey-section'>
        <div className='slack-info screen-max-width'>
          <div className='section-info'>
            <h2 className='section-title center'>Post a Query directly from Slack</h2>
            <p className='subheading'>Tank enables your team members to easily resolve any doubts while working by quickly raising a query from Slack.</p>
          </div>
          <div className='slack-asset'>
            <img src={require('../../assets/images/Post_question_Slack_creative.png')} alt='slack hero' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntegrationsPage;
