import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { debounce } from "lodash";

import { teamNameCheck, signInCheck, invalidUser } from "../../api/api";
import { ONBOARDED_USER, ACTION_ID, USER_SIGN_UP } from "../../actions/types";
import OnboardingStepper from "../../components/OnboardingStepper/OnboardingStepper";
import Spinner from "../../components/Loader/Spinner";
import { SETTING_UP_PAGE } from "../../constants/PageRoutes";
import { isOnlyLetters, TEAM_NAME_CHAR_LIMIT } from "../../utils";

import "./OnboardingPage.scss";
class OnboardingPage extends Component {
  state = {
    fullName: "",
    teamName: "",
    debouncedTeamName: "",
    teamNameError: false,
    disableBtn: true,
    existingUserTeams: [],
    userSelectedTeam: "",
    apiError: "",
    organizationLoading: true,
    selectedSpaceData: null,
    formError: false,
  };

  componentDidMount() {
    this.updateMessage = debounce(this.updateMessage, 500);

    const { signedUpUser, currentUser, invitedTeam } = this.props;
    const inviteDetailsData = JSON.parse(
      window.localStorage.getItem("inviteDetails")
    );

    if (inviteDetailsData !== null) {
      window.location.href = SETTING_UP_PAGE;
    }

    // if (invitedTeam && signedUpUser) {
    //   this.checkInvitedEmailValidity();
    // }

    if ((signedUpUser || currentUser) && !invitedTeam) {
      const userName = `${currentUser?.name} ${currentUser?.lastName}`;
      const userSignInEmail = signedUpUser?.email || currentUser?.email;
      const body = {
        emailID: userSignInEmail,
      };

      this.setState({ fullName: userName });

      if (userSignInEmail?.length) {
        axios
          .post(signInCheck, body)
          .then((res) => {
            if (res?.data?.data?.length) {
              this.setState({ existingUserTeams: res?.data?.data });
            }
          })
          .catch(() => console.log("Oops, something went wrong!"))
          .finally(() => this.setState({ organizationLoading: false }));
      }
    }
  }

  // checkInvitedEmailValidity = async () => {
  //   const { signedUpUser, currentUser, invitedTeam } = this.props;

  //   const tankUser = currentUser || signedUpUser;

  //   if (invitedTeam.emailInvited !== tankUser.email) {
  //     const encodedEmail = encodeURIComponent(invitedTeam.emailInvited);

  //     const body = {
  //       email: tankUser.email
  //     };

  //     const res = await axios.post(invalidUser, body);
  //     if (res && res.data && res.data.inValidUserDeleted) {
  //       window.location.href = `/signup-invite/new-user?email=${encodedEmail}&teamname=${invitedTeam.teamNameInvited}&actionid=invite`;
  //     }
  //   } else {
  //     localStorage.setItem('emailValid', true);
  //     window.location.href = '/setting-up';
  //   }
  // }

  validateOnboardingFields() {
    const { teamName } = this.state;
    if (teamName.trim() !== "") {
      this.setState({ disableBtn: false });
    } else {
      this.setState({ disableBtn: true });
    }
  }

  updateMessage = (debouncedTeamName) => {
    this.setState({ debouncedTeamName }, () => {
      axios
        .get(`${teamNameCheck}${this.state.debouncedTeamName}`)
        .then((res) => {
          const isTeamNameTaken = res && res.data && !res.data.status;
          if (isTeamNameTaken) {
            this.setState({ teamNameError: true });
          } else {
            this.setState({ teamNameError: false });
          }
        });
    });
  };

  handleTeamNameChange = (val) => {
    this.setState({ teamName: val }, () => {
      if (this.state.teamName.length && this.state.teamName.trim() !== "") {
        this.updateMessage(this.state.teamName);
      }
      this.validateOnboardingFields();
    });
  };

  handleTeamSelectionChange = (e) => {
    this.setState({ userSelectedTeam: e.target.value, disableBtn: false });
  };

  selectedSpace = (space) => {
    this.setState({ selectedSpaceData: space });
  };

  handleOnboardDataSubmit = () => {
    const { setOnboardedUser, setOnboardingActionId } = this.props;

    const onboardingData = {
      fullName: this.state.fullName,
      interest: "",
      teamName: this.state.userSelectedTeam || this.state.teamName,
      band: "",
      edition: "",
      teamMembers: [],
    };

    if (
      isOnlyLetters(this.state.fullName) &&
      isOnlyLetters(this.state.userSelectedTeam || this.state.teamName) &&
      (this.state.userSelectedTeam.length || this.state.teamName.length) <=
        TEAM_NAME_CHAR_LIMIT
    ) {
      this.setState({ formError: false });
      setOnboardedUser(onboardingData);
      setOnboardingActionId("set-it-up");
    } else {
      this.setState({ formError: true });
    }
  };

  configureDashboardData = () => {
    const { setOnboardingActionId, userSignUpData } = this.props;
    if (
      isOnlyLetters(this.state.teamName) &&
      this.state.teamName.length <= TEAM_NAME_CHAR_LIMIT
    ) {
      this.setState({ formError: false });
      userSignUpData(this.state.selectedSpaceData);
      localStorage.setItem(
        "userData",
        JSON.stringify(this.state.selectedSpaceData)
      );
      setOnboardingActionId("sign-in-user");
    } else {
      this.setState({ formError: true });
    }
  };

  render() {
    const {
      teamName,
      teamNameError,
      disableBtn,
      userSelectedTeam,
      existingUserTeams,
      organizationLoading,
      formError,
    } = this.state;
    const { signedUpUser, currentUser, invitedTeam } = this.props;

    const tankUser = currentUser || signedUpUser;

    return (
      <div className="onboarding-container screen-max-width full-page-height page-spinner">
        {invitedTeam ? (
          <Spinner color={"blue"} size={30} />
        ) : (
          <OnboardingStepper
            tankUserEmail={tankUser && tankUser.email ? tankUser.email : ""}
            teamName={teamName}
            teamNameError={teamNameError}
            handleTeamNameChange={this.handleTeamNameChange}
            handleOnboardDataSubmit={this.handleOnboardDataSubmit}
            configureDashboardData={this.configureDashboardData}
            disableBtn={disableBtn}
            handleTeamSelectionChange={this.handleTeamSelectionChange}
            existingUserTeams={existingUserTeams}
            userSelectedTeam={userSelectedTeam}
            organizationLoading={organizationLoading}
            selectedTeam={this.selectedSpace}
            formError={formError}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboardedUser: state.onboardedUser,
    onboardingActionId: state.onboardingActionId,
    signedUpUser: state.signedUpUser,
    invitedTeam: state.invitedTeam,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setOnboardedUser: (data) => dispatch({ type: ONBOARDED_USER, payload: data }),
  setOnboardingActionId: (data) => dispatch({ type: ACTION_ID, payload: data }),
  userSignUpData: (data) => dispatch({ type: USER_SIGN_UP, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);
