import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Spinner from "../../components/Loader/Spinner";
import { verifyEmail, getUserById } from "../../api/api";
import TankButton from "../../components/TankButton/TankButton";
import { SIGN_IN_PAGE } from "../../constants/PageRoutes";
import { isOk } from "../../utils";
import "./styles.scss";

const VerifyEmailPage = (props) => {
  const {
    match: { params },
  } = props;
  const [isVerifyEmailInProgress, setVerifyEmailInProgress] = useState(true);
  const [verifyEmailError, setVerifyEmailError] = useState(false);
  const [verifyEmailErrorMsg, setVerifyEmailErrMsg] = useState("");
  const [isRetryVerificationInProgress, setIsRetryVerificationInProgress] =
    useState(false);

  const verifyEmailToken = params?.token;
  const userId = params?.userId;

  const retryVerification = () => {
    setIsRetryVerificationInProgress(true);
    axios
      .get(`${getUserById}/${userId}`)
      .then((getUserByIdResponse) => {
        if (isOk(getUserByIdResponse)) {
          axios
            .post(verifyEmail, {
              email: getUserByIdResponse?.data?.data?.email,
            })
            .then((verifyEmailResponse) => {
              if (isOk(verifyEmailResponse)) {
                window.location.href = `${SIGN_IN_PAGE}?verifyUser=true&userId=${userId}`;
              }
            })
            .catch(() =>
              setVerifyEmailErrMsg(
                "Unable to send verification email. Please try again"
              )
            )
            .finally(() => setIsRetryVerificationInProgress(false));
        }
      })
      .catch(() =>
        setVerifyEmailErrMsg(
          "Oops, something went wrong retrieving the email. Please try again"
        )
      );
  };

  const verifyUserEmail = useCallback(() => {
    axios
      .post(`${verifyEmail}/${userId}/${verifyEmailToken}`)
      .then((verifyEmailResponse) => {
        if (verifyEmailResponse?.data?.emailVerified) {
          window.location.href = `${SIGN_IN_PAGE}?verifyUser=true&userId=${userId}`;
        }
      })
      .catch(() => setVerifyEmailError(true))
      .finally(() => setVerifyEmailInProgress(false));
  }, [verifyEmailToken, userId]);

  const renderVerifyEmailFailure = () => (
    <div className="verifyEmail__Msg">
      <p className="error-title">Verification link invalid or expired.</p>
      <p className="generate-link">
        To generate a new link{" "}
        <TankButton
          btnOnClick={retryVerification}
          btnText={"Click here"}
          isRetryVerificationInProgress={isRetryVerificationInProgress}
        />
        {isRetryVerificationInProgress && <Spinner color="blue" size={20} />}
      </p>
    </div>
  );

  useEffect(() => {
    if (isVerifyEmailInProgress && verifyEmailToken?.length) {
      setTimeout(() => {
        verifyUserEmail();
      }, 2000);
    }
  }, [isVerifyEmailInProgress, verifyEmailToken?.length, verifyUserEmail]);

  return (
    <div className="verifyEmailPage full-page-height">
      <div className="screen-max-width">
        <div className="verifyEmail__PageContainer">
          {isVerifyEmailInProgress && (
            <>
              <h2>
                Verifying Email...
                <Spinner color="blue" size={20} />
              </h2>
            </>
          )}
          {verifyEmailError && (
            <div className="verifyEmail__Msg">{renderVerifyEmailFailure()}</div>
          )}
          {!!verifyEmailErrorMsg?.length && (
            <p className="error">{verifyEmailErrorMsg}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
