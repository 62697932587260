import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { useDebounce } from "../../Hooks/useDebounce";

import { generalSearch } from "../../api/api";

import Loader from "../Loader/Loader";

import { makeStyles } from "@material-ui/core/styles";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import Paper from "@material-ui/core/Paper";

import { SEARCH_PLACEHOLDER, AUTHOR, NO_RESULTS } from "../../constants/Search";
import { QUESTION_DETAIL_PAGE } from "../../constants/PageRoutes";

import "./Search.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

const SET_RESULT_DELAY = 1000;
const DEBOUNCE_DELAY = 500;
const SEARCH_DROPDOWN_CLOSE_DELAY = 100;
const SEARCH_TERM_LENGTH = 2;

const Search = (props) => {
  const classes = useStyles();

  const searchDropdown = useRef(null);
  const searchInput = useRef(null);
  const searchResultItem = useRef(null);

  const [term, setTerm] = useState("");
  const [results, setResults] = useState({
    answers: [],
    queries: [],
  });
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const debouncedSearchTerm = useDebounce(term, DEBOUNCE_DELAY);
  const debouncedSearchTermLength = debouncedSearchTerm.length;

  const fetchSearchResults = async (searchTerm) => {
    return await axios
      .get(`${generalSearch}${searchTerm}/${props.cuurentSpaceID}`)
      .then((searchResults) => searchResults?.data);
  };

  const handleBodyClick = (e) => {
    if (
      searchDropdown.current &&
      !searchDropdown.current.contains(e.target) &&
      !searchInput.current.contains(e.target)
    ) {
      setIsSearchExpanded(false);
      setTerm("");
    }
  };

  const handleSearchItemClick = (e) => {
    if (
      searchResultItem.current &&
      searchResultItem.current.contains(e.target)
    ) {
      setTimeout(() => {
        setIsSearchExpanded(false);
        setTerm("");
      }, SEARCH_DROPDOWN_CLOSE_DELAY);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTermLength > SEARCH_TERM_LENGTH) {
      setIsSearching(true);
      fetchSearchResults(debouncedSearchTerm).then((searchTermResults) => {
        setIsSearchExpanded(true);
        setTimeout(() => {
          setIsSearching(false);
          setResults({
            answers: searchTermResults?.answers,
            queries: searchTermResults?.questions,
          });
        }, SET_RESULT_DELAY);
      });
    } else {
      setResults({
        answers: [],
        queries: [],
      });
      setIsSearchExpanded(false);
    }
    document.addEventListener("mousedown", handleBodyClick);
    document.addEventListener("mousedown", handleSearchItemClick);
  }, [debouncedSearchTerm]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const renderQueriesResults = () => {
    if (!results?.queries?.length) return null;

    return (
      <div className="query-results">
        <p className="result-group">QUERIES</p>
        {results?.queries?.map((result) => (
          <div ref={searchResultItem} className="result-item query">
            <Link to={`${QUESTION_DETAIL_PAGE}/${result?.quesId}`}>
              <p className="result-title">{result?.quesTitle}</p>
              <p className="result-author">
                {AUTHOR}: {result?.userName}
              </p>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const renderAnswerResults = () => {
    if (!results?.answers?.length) return null;

    return (
      <div className="answer-results">
        <p className="result-group">ANSWERS</p>
        {results?.answers?.map((result) => (
          <div ref={searchResultItem} className="result-item answer">
            <Link to={`${QUESTION_DETAIL_PAGE}/${result?.quesId?.quesId}`}>
              <p
                className="result-title"
                dangerouslySetInnerHTML={createMarkup(result?.answerDesp)}
              />
              <p className="result-author">
                {"Answered by"}: {result?.answeredBy}
              </p>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const renderSearchResults = () => (
    <div className="search-result">
      {results?.queries?.length || results?.answers?.length ? (
        <>
          {renderQueriesResults()}
          {renderAnswerResults()}
        </>
      ) : (
        <p className="no-results">{NO_RESULTS}</p>
      )}
    </div>
  );

  return (
    <div className="tank-search">
      <input
        ref={searchInput}
        type="text"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        placeholder={SEARCH_PLACEHOLDER}
      />
      <SearchOutlinedIcon
        color="action"
        className="search-icon"
        fontSize="small"
      />
      {isSearchExpanded ? (
        <div
          ref={searchDropdown}
          className={`${classes.root} search-results-dropdown`}
        >
          <Paper elevation={3}>
            {isSearching ? <Loader /> : renderSearchResults()}
          </Paper>
        </div>
      ) : null}
    </div>
  );
};

export default Search;
