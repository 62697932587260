export const FEATURES_DATA = [
  {
    img: require('../../../assets/images/marketing.png'),
    featureTitle: 'Marketing',
    featureDesc: 'Share your marketing insight and releases thereby build a strong database.'
  },
  {
    img: require('../../../assets/images/hr.png'),
    featureTitle: 'HR',
    featureDesc: 'Easier onboarding with all necessary information right here in Tank.'
  },
  {
    img: require('../../../assets/images/prod.png'),
    featureTitle: 'Product',
    featureDesc: 'Share new releases. Ask and resolve queries.'
  },
  {
    img: require('../../../assets/images/design.png'),
    featureTitle: 'Design',
    featureDesc: 'Discuss your design ideas, publish and share through Tank.'
  },
  {
    img: require('../../../assets/images/customer-success.png'),
    featureTitle: 'Customer Success',
    featureDesc: 'Support teams are more equipped with information required to solve customer issues effectively.'
  }
];
