import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { contactUs } from '../../api/api';

import { isEmailValid } from "../../utils";

import InputField from '../../components/InputField/InputField';

import './ContactUsPage.scss';

const ContactUsPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [postResponse, setPostResponse] = useState(false);

  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    if (name.trim() !== '' && email !== '' && isEmailValid(email) && message.trim() !== '') {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [name, email, message]);

  const handleContactUs = async () => {
    const body = {
      fullName: name,
      emailID: email,
      message
    }

    const res = await axios.post(contactUs, body);
  
    setPostResponse(res && res.status);
  }

  return (
    <div className={`contact-us-page screen-max-width space-top ${postResponse ? 'posted' : ''}`}>
      <div className='contact-us wrap'>
        {
          postResponse ?
          <h2>Thank you for reaching out to us. We will get back to you soon.</h2>
          :
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <div className='contact-heading'>
                <h2 className='title'>We'd love to hear from you</h2>
                <p className='desc'>Send us a message and we'll get back to you as soon as possible.</p>
              </div>
              <div className='contact-form'>
                <InputField
                  labelText={'Your Name'}
                  inputValue={name}
                  type={'text'}
                  inputTag={'input'}
                  handleOnChange={setName}
                />
                <InputField
                  labelText={'Your Email'}
                  inputValue={email}
                  type={'email'}
                  inputTag={'input'}
                  handleOnChange={setEmail}
                />
                <InputField
                  labelText={'Your Message'}
                  inputValue={message}
                  type={'email'}
                  inputTag={'textarea'}
                  handleOnChange={setMessage}
                  placeholderText={'Type something here...'}
                />
                <div className='submit-btn'>
                  <Button
                    variant="contained"
                    color="secondary"
                    className='primary-btn'
                    disabled={disableBtn}
                    onClick={handleContactUs}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className='contact-img'>
              <div className='img-container'>
                <img src={require('../../assets/images/contact-us.png')} alt='contact us' />
              </div>
              <div className='social-contact'>
                <div className='social-logo'>
                  <a href="https://www.linkedin.com/company/trytank/" target="_blank">
                  <img src={require('../../assets/images/linkedin.png')} alt='linkedin' />
                  </a>
                </div>
                <div className='social-logo'>
                  <a href="https://twitter.com/OrgTank" target="_blank">
                  <img src={require('../../assets/images/twitter.png')} alt='twitter' />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        }
      </div>
    </div>
  );
}

export default ContactUsPage;
