import React from 'react';

import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {DASHBOARD_PAGE} from '../../../../constants/PageRoutes';

import '../BillingSuccess/BillingSuccess.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      padding: theme.spacing(3)
    },
  },
}));

const BillingSuccess = () => {
  const classes = useStyles();

  return (
    <div className="billing-failure screen-max-width page-top">
      <div className={classes.root}>
        <Paper elevation={3}>
          <div className='payment-asset'>
            <img src={require('../../../../assets/images/payment_fail.png')} alt='payment success' />
          </div>
          <div className='payment-info'>
            <h2>Uh oh! Something went wrong.</h2>
            <p>We weren't able to process your payment. Please try again.</p>
          </div>
          <Link to={DASHBOARD_PAGE} className="btn-navigate">
            Back to Dashboard
          </Link>
        </Paper>
      </div>
    </div>
  )
}

export default BillingSuccess;
