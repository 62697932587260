import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import JSConfetti from "js-confetti";

import {
  getSlackChannelMembers,
  onboardSelectedSlackUsers,
} from "../../../api/api";

import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";
import Button from "@material-ui/core/Button";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Modal from "../../../components/Modals/Modal/Modal";

import { TEAM_SETTINGS_PAGE } from "../../../constants/PageRoutes";

import "./AddIntegration.scss";

const AddIntegration = (props) => {
  const [integrationType, setIntegrationType] = useState("");
  const [integrationId, setIntegrationId] = useState("");
  const [selectedChannelMembers, setSelectedChannelMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [onboardError, setOnboardError] = useState(false);

  const canvasRef = useRef();
  const confettiRef = useRef();

  const modalDisplay = {
    display: "flex",
  };

  const getChannelMembers = (spaceId) => {
    const body = {
      spaceID: props?.currentTeamData?.spaceID || spaceId,
    };

    axios.post(getSlackChannelMembers, body).then((res) => {
      const resData = res?.data?.data;
      setSelectedChannelMembers(resData);
    });
  };

  useEffect(() => {
    confettiRef.current = new JSConfetti({ canvas: canvasRef.current });

    if (selectedChannelMembers?.length && confettiRef?.current) {
      confettiRef?.current?.addConfetti({
        confettiRadius: 5,
        confettiNumber: 500,
      });
    }
  }, []);

  useEffect(() => {
    const { currentTeamData } = props;
    const clientId = currentTeamData?.spaceID;
    const urlParams = new URL(document.location).searchParams;
    const type = urlParams.get("type");
    const id = urlParams.get("id");

    setIntegrationType(type);
    setIntegrationId(id);

    if (integrationType?.length && integrationId?.length) {
      const postData = {
        type: integrationType,
        integrationId,
        spaceID: clientId,
      };

      axios.post("/api/v1/slackapi/integrationDetails", postData).then(() => {
        getChannelMembers(clientId);
      });
    }
  }, [integrationType, integrationId]);

  const handleOptionSelection = (val) => {
    setSelectedMembers(val);
  };

  const redirectToTeamSettings = () => {
    setIsOnboarded(false);
    window.location.href = `${TEAM_SETTINGS_PAGE}?slackonboard=success`;
  };

  const onboardSlackUser = async () => {
    const { currentTeamData } = props;
    const clientId = currentTeamData?.spaceID;

    const body = {
      SlackUsersDetailsArray: selectedMembers,
      spaceID: clientId,
      teamName: currentTeamData?.teamName,
    };

    await axios
      .post(onboardSelectedSlackUsers, body)
      .then(() => {
        setIsOnboarded(true);
      })
      .catch(() => setOnboardError(true));
  };

  return (
    <>
      <div className="add-integration screen-max-width page-top">
        <div className="integration">
          {selectedChannelMembers?.length ? (
            <div className="confetti-container">
              <p className="slack-install-success">
                <CheckCircleRoundedIcon fontSize="small" />
                Slack installed successfully!
              </p>
              <canvas className="canvas" ref={canvasRef} />
            </div>
          ) : (
            <div className="slack-install-placeholder"></div>
          )}
          <SearchDropdown
            label={"Select your Slack team members"}
            options={
              selectedChannelMembers?.length ? selectedChannelMembers : []
            }
            teamMemberNameKey={"real_name"}
            handleOptionSelection={handleOptionSelection}
          />
          <div className="btn-block">
            <Button onClick={onboardSlackUser} className="primary-btn small">
              Onboard
            </Button>
          </div>
          {onboardError ? (
            <p className="err-msg-secondary">Oops! Something went wrong!</p>
          ) : null}
        </div>
      </div>
      {isOnboarded ? (
        <div className="onboard-successfull">
          <Modal
            close={redirectToTeamSettings}
            modalDisplay={modalDisplay}
            isDisabled={false}
            provisionType={"ok"}
            handleProvisioning={redirectToTeamSettings}
          >
            <div className="tank-modal delete">
              <p className="confirm-delete-msg">
                {"Successfully onboarded users."}
              </p>
            </div>
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default AddIntegration;
