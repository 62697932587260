export const SELECT_COLLECTION = 'Select collection';

/* New question */
export const NEW_QUERY = 'New Query';
export const ADD_NEW_QUERY = 'Add a new query';
export const ENTER_QUERY_TITLE = 'Enter query title';
export const SUBMIT_QUERY = 'Submit query';


/* Edit question */
export const EDIT_QUERY = 'Edit query';
export const EDIT_QUERY_TITLE = 'Edit query title';

export const DELETE_QUERY_CONFIRM = 'Are you sure you want to delete this query?';
export const NO_QUERIES = 'No queries added';
export const ASKED_BY = 'Asked by';
