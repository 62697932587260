import React, { useState, useEffect } from 'react';
import { ADD_RESPONSE_TITLE } from '../../../constants/Answer';
import { CANCEL, ADD } from '../../../constants/ButtonLabel';

import TankEditor from '../../TankEditor/TankEditor';
import Modal from '../Modal/Modal';

import './AddAnswerModal.scss';

const AddAnswerModal = (props) => {
  const [editorContent, setEditorContent] = useState('');
  const [isAnswerAdded, setIsAnswerAdded] = useState(false);

  const { open, currentTeamData, close, addAns, handleAnswerSaveDraft, feedbackMsgEnabled, feedbackMsg } = props;

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);

    if (content.trim() === '') {
      setIsAnswerAdded(false);
    } else {
      setIsAnswerAdded(true);
    }
  }

  const divStyle = {
    display: open ? "flex" : "none",
  };

  useEffect(() => {
    if (!open) {
      setEditorContent('');
    }
  }, [open]);

  return (
    <Modal
      modalTitle={ADD_RESPONSE_TITLE}
      modalDisplay={divStyle}
      close={close}
      isDisabled={!isAnswerAdded}
      handleProvisioning={() => addAns(editorContent, currentTeamData?.name)}
      cancelText={CANCEL}
      provisionType={ADD}
      handleSaveAsDraft={() => handleAnswerSaveDraft(editorContent, 'add')}
      isDraftBtn={true}
      isDraftBtnDisabled={!isAnswerAdded}
      feedbackMsgEnabled={feedbackMsgEnabled}
      feedbackMsg={feedbackMsg}
    >
      <TankEditor content={editorContent} editorChange={handleEditorChange} />
    </Modal>
  );
}

export default AddAnswerModal;
