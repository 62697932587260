import { SET_ONBOARD_USER_DATA, USER_LOGOUT } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case SET_ONBOARD_USER_DATA:
      return action.payload;
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
}
