import React from 'react';

import { COLOR_PALETTE } from '../../constants';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import './ColorPalette.scss';

const ColorPalette = (props) => {

  const renderColors = () => {
    let colors = COLOR_PALETTE.map(color => (
      <Grid key={color} item xs={4}>
        <div className="color-container">
          <FormControlLabel
            value={color}
            control={<Radio className="color-option"></Radio>}
          />
          <div className="color-box" style={{ backgroundColor: color }}></div>
        </div>
      </Grid>
    ));
    return colors;
  }

  return (
    <div className="color-palette">
      <FormControl className="palette-control" component="fieldset">
        <RadioGroup className="palette-group" aria-label="color palette" name="colors" value={props.colorValue} onChange={(e) => props.colorChange(e)}>
          <Grid container>
            {renderColors()}
          </Grid>
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default ColorPalette;
