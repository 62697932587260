import React, { useState } from 'react';

import TankEditor from '../../TankEditor/TankEditor';
import Modal from '../Modal/Modal';

import { EDIT_RESPONSE_TITLE } from '../../../constants/Answer';
import { CANCEL, SAVE } from '../../../constants/ButtonLabel';

const EditAnswerModal = (props) => {
  const {
    open,
    close,
    setAnswer,
    ansID,
    queID,
    updatedAns,
    handleAnswerSaveDraft,
    isDraftAnswer,
    isSaveButtonDisabled = true,
  } = props;

  const [isAnswerUpdated, setIsAnswerUpdated] = useState(false);
  const [editorContentUpdate, setEditorContentUpdate] = useState('');

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const handleEditorChange = (content, editor) => {
    setEditorContentUpdate(content);

    if (content.trim() === '') {
      setIsAnswerUpdated(false);
    } else {
      setIsAnswerUpdated(true);
    }
  }

  return (
    <Modal
      modalTitle={EDIT_RESPONSE_TITLE}
      close={close}
      modalDisplay={divStyle}
      isDisabled={!isSaveButtonDisabled ? isSaveButtonDisabled : !isAnswerUpdated}
      cancelText={CANCEL}
      provisionType={SAVE}
      handleProvisioning={() => isDraftAnswer ? updatedAns(editorContentUpdate) : updatedAns(queID, ansID, editorContentUpdate)}
      handleSaveAsDraft={() => handleAnswerSaveDraft(editorContentUpdate)}
      isDraftBtn={true}
      isDraftBtnDisabled={false}
    >
      <TankEditor setContent={setAnswer} editorChange={handleEditorChange} />
    </Modal>
  );
}

export default EditAnswerModal;
