import React from 'react';
import Modal from '../Modal/Modal';

import './ChooseAdminModal.scss';

const ChooseAdminModal = props => {
  const { close, open } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  return (
    <div className="choose-admin-modal">
      <Modal
        close={close}
        modalDisplay={divStyle}
        isDisabled={false}
        cancelText={'OK'}
      >
        <div className='tank-modal'>
          <h3 className="admin-err-msg">There must be atleast one member with Admin priviledge.</h3>
        </div>
      </Modal>
    </div>
  );
}

export default ChooseAdminModal;
