import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { clientOnboarding, userOnboarding, userBaseURL, signInCheck } from "../../api/api";

import { CURRENT_USER_DATA, LOGGED_IN_USER } from "../../actions/types";
import { isOk, isEmptyObject } from "../../utils";

import UserEntryContainer from '../../components/UserEntryContainer/UserEntryContainer';
import Spinner from "../../components/Loader/Spinner";

import { DASHBOARD_PAGE } from "../../constants/PageRoutes";

// import { appSubdomain } from "../../constants";

import './SettingUpPage.scss';

const REDIRECT_DELAY = 2000;

class SettingUpPage extends Component {
  componentDidMount() {
    const { onboardedUser, onboardingActionId, currentUserData, signedUpUser, currentUser } = this.props;
    const inviteDetailsData = JSON.parse(window.localStorage.getItem('inviteDetails'));
    const tankUser = signedUpUser || currentUser;

    if (onboardingActionId === "set-it-up" && onboardedUser) {
      if (tankUser) {
        this.onboardClient();
      }
    } else if (onboardingActionId === 'sign-in-user') {
      if (tankUser) {
        this.props.isLoggedIn(true);
        this.redirectToDashboard();
      }
    } else if (onboardingActionId === "invite" || inviteDetailsData?.inviteId === 'invite') {
      if (tankUser) {
        this.onboardInvitedUser();
        this.redirectToDashboard();
      }
    }

    if (tankUser && currentUserData && onboardingActionId !== "invite") {
      this.getLoggedInUserData();
    }
  }

  redirectToDashboard() {
    // const redirectURL = localStorage.getItem('redirectTo');

    // if (redirectURL) {
    //   setTimeout(() => (window.location.href = redirectURL), REDIRECT_DELAY);
    // } else {
    //   setTimeout(() => (window.location.href = DASHBOARD_PAGE), REDIRECT_DELAY);
    // }
    setTimeout(() => (window.location.href = DASHBOARD_PAGE), REDIRECT_DELAY)
    // setTimeout(() => (window.location.href = `https://${appSubdomain}/dashboard`), REDIRECT_DELAY);
  }

  onboardInvitedUser = async () => {
    const { signedUpUser, invitedTeam, currentUserData, currentUser } = this.props;
    const inviteDetailsData = JSON.parse(window.localStorage.getItem('inviteDetails'));

    const tankUser = signedUpUser || currentUser;
    const tankProfilePhoto = inviteDetailsData ? currentUser?.dp : tankUser?.dp;

    const userData = {
      teamName: invitedTeam?.teamNameInvited || inviteDetailsData?.inviteTeamName,
      email: invitedTeam?.emailInvited || tankUser?.email,
      tankUserID: tankUser?.tankUserID || currentUser?.tankUserID,
      name: tankUser?.name || currentUser?.name,
      lastName: tankUser?.lastName || currentUser?.lastName,
      dp: tankProfilePhoto ? tankProfilePhoto : null
    };

    if (currentUserData === null) {
      const invitedUser = await axios.post(userOnboarding, userData);

      localStorage.setItem('userData', JSON.stringify(invitedUser?.data));
      this.props.setCurrentUserData(invitedUser?.data);
    }

    if (currentUserData) {
      this.props.isLoggedIn(true);
    }
  }

  onboardClient = async () => {
    const { currentUser, onboardedUser, currentUserData, signedUpUser } = this.props;

    const tankUser = currentUser || signedUpUser;

    const clientData = {
      fullName:
        onboardedUser?.fullName &&
        !onboardedUser?.fullName?.includes("undefined")
          ? onboardedUser?.fullName
          : `${tankUser?.name} ${tankUser?.lastName}`,
      teamName: onboardedUser?.teamName,
      userInfo: tankUser,
    };

    if (currentUserData === null) {
      await axios.post(clientOnboarding, clientData).then(res => {
        if (isOk(res)) {
          const parsedUserData = res?.config?.data ? JSON.parse(res?.config?.data) : {};
          const enteredEmail = parsedUserData?.userInfo?.email || '';
          if (enteredEmail?.length) {
            axios.post(signInCheck, {emailID: enteredEmail}).then(response => {
              if (isOk(res)) {
                const onboardedClientData = response?.data?.data?.[0];
                if (!isEmptyObject(onboardedClientData)) {
                  localStorage.setItem('userData', JSON.stringify(onboardedClientData));
                  this.props.setCurrentUserData(onboardedClientData);
                }
              }
            }).catch(err => console.log('Something went wrong!', err));
          }
        }
      }).catch(err => console.log('Something went wrong!', err));
    }

    if (currentUserData) {
      this.props.isLoggedIn(true);
      this.redirectToDashboard();
    }
  }

  getLoggedInUserData = async () => {
    const { currentUserData, loggedInUserData, signedUpUser } = this.props;

    const tankUser = signedUpUser;

    if (loggedInUserData === null) {
      const user = await axios.get(`${userBaseURL}/user/${tankUser?.tankUserID}/${currentUserData?.spaceID}`);

      const loggedInUserData = user?.data?.data?.[0];

      if (loggedInUserData?.length) {
        localStorage.setItem('userData', JSON.stringify(loggedInUserData));
        this.props.setLoggedInUserData(loggedInUserData);
      }
    }
  }

  render() {
    return (
      <div className="setting-up screen-max-width full-page-height">
        <UserEntryContainer>
          <h2>Setting up your workspace...</h2>
          <Spinner color='blue' size={20} />
        </UserEntryContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    onboardedUser: state.onboardedUser,
    onboardingActionId: state.onboardingActionId,
    userOnboardData: state.userOnboardData,
    currentUserData: state.currentUserData,
    loggedInUserData: state.loggedInUserData,
    signedUpUser: state.signedUpUser,
    invitedTeam: state.invitedTeam
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUserData: (data) => dispatch({ type: CURRENT_USER_DATA, payload: data }),
  setLoggedInUserData: data => dispatch({ type: LOGGED_IN_USER, payload: data }),
  isLoggedIn: (data) => dispatch({ type: 'IS_LOGGED_IN', payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingUpPage);
