import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route, Switch, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import {
  questionsList,
  collectionsList,
  createNewQuestion,
  editCollection,
  getCollectionDetailsByID,
  deleteCollection,
  getAllUsersFromSpace,
  planDetails,
  subscribeEvents,
  signInCheck,
} from "../../api/api";

import {
  saveQuestionAsDraft,
  hasSuperUserPermission,
  isTrialPeriodActive,
  isSubscriptionPlanActive,
  QUERY_CHAR_LIMIT,
  isOk,
  isSubscriptionEnded,
} from "../../utils";
import {
  DASHBOARD_PAGE,
  COLLECTION_QUESTIONS_PAGE,
  QUESTION_DETAIL_PAGE,
  GET_STARTED_PAGE,
  MY_INTERESTS_PAGE,
  SETTINGS_PAGE,
  MY_DRAFTS_PAGE,
  MY_POSTS_PAGE,
  ANALYTICS_PAGE,
  COLLECTIONS_PAGE,
  LOGOUT_PAGE,
  BILLING_SETTINGS_PAGE,
} from "../../constants/PageRoutes";
import { ROLE_ADMIN } from "../../constants/UserRoles";

import QuestionDetailPage from "../QuestionDetailPage/QuestionDetailPage";
import CollectionQuestionsPage from "../CollectionQuestionsPage/CollectionQuestionsPage";
import InterestsPage from "../InterestsPage/InterestsPage";
import SettingsPage from "../SettingsPage/SettingsPage";
import MyDraftsPage from "../MyDraftsPage/MyDraftsPage";
import MyPostsPage from "../MyPostsPage/MyPostsPage";
import NotificationsPage from "../NotificationsPage/NotificationsPage";
import AnalyticsPage from "../AnalyticsPage/AnalyticsPage";
import GetStartedQuestionDetailPage from "../GetStartedQuestionDetailPage/GetStartedQuestionDetailPage";

import Collection from "../../components/Collection/Collection";
import NewQuestionButton from "../../components/NewQuestionButton/NewQuestionButton";
import Logo from "../../components/Logo/Logo";
import TankButton from "../../components/TankButton/TankButton";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";

import Shimmer from "../../components/Shimmer";
import CollectionShimmer from "../../components/Shimmer/CollectionShimmer";

import { DRAWER_WIDTH, TANK_LOGO_BLUE } from "../../constants";
import { NEW_QUERY } from "../../constants/Question";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Grid from "@material-ui/core/Grid";

import {
  drawerMenuItems,
  COLLECTIONS,
  INVITE_TEAM_MEMBERS,
  menuItemsBottom,
  MY_NOTIFICATIONS,
  settingsDrawerItems,
} from "../../components/Header/DrawerItemsConfig";

import NewQuestionModal from "../../components/Modals/NewQuestionModal/NewQuestionModal";
import CreateCollectionModal from "../../components/Modals/CreateCollectionModal/CreateCollectionModal";
import EditCollectionModal from "../../components/Modals/EditCollectionModal/EditCollectionModal";
import AddTeamMembersModal from "../../components/Modals/AddTeamMembersModal/AddTeamMembersModal";
import CollectionDeleteModal from "../../components/Modals/CollectionDeleteModal/CollectionDeleteModal";

import LoaderWithBackdrop from "../../components/Loader/LoaderWithBackdrop";
import InputField from "../../components/InputField/InputField";

import "./DashboardPage.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    color: "white",
  },
  flex: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
    background: "#302f33",
    color: "#ffffff",
    padding: "0 10px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Dashboard = (props) => {
  const {
    location: { pathname },
    handleTeamSwitch,
  } = props;
  const theme = useTheme();
  const classes = useStyles();

  const isSettingsPage = pathname.includes("settings");

  const signedInUserData = JSON.parse(localStorage.getItem("userData"));

  const userTankID = signedInUserData?.tankUserID;
  const userDP =
    signedInUserData?.dp !== null || signedInUserData?.dp !== ""
      ? signedInUserData?.dp
      : null;
  const userRole = signedInUserData?.role;
  const spaceID = signedInUserData?.spaceID;
  const userName = signedInUserData?.name;
  const creatorID = userName;
  const userEmail = signedInUserData?.email;
  const userOrgID = signedInUserData?.orgID;

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [openCreateCollectionModal, setCreateCollectionModalOpen] =
    useState(false);
  const [openEditCollectionModal, setEditCollectionModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [collections, setCollections] = useState([]);
  const [createCollecId, setCreateCollecId] = useState(1);
  const [openAddMembersModal, setAddMembersModalOpen] = useState(false);
  const [userPlan, setUserPlan] = useState({});

  const [colId, setColId] = useState("");
  const [collecName, setCollecName] = useState("");
  const [collecDesc, setCollecDesc] = useState("");
  const [collecColor, setCollecColor] = useState("");
  const [collecScope, setCollecScope] = useState("");
  const [collecGurus, setCollecGurus] = useState("");
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [listening, setListening] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] =
    useState(false);
  const [collectionDeleteId, setCollectionDeleteId] = useState("");

  const [activeTab, setActiveTab] = useState(COLLECTIONS);
  const [userTeams, setUserTeams] = useState([]);
  const [anchor, setAnchor] = useState(false);
  const [counter, setCounter] = useState(0);
  const [quesAdded, setQuesAdded] = useState(false);

  const collectionData = collections?.data;
  const collectionId = collectionData?.[0]?.collectionId;
  const collectionName = collectionData?.[0]?.collectionName;

  const [isCurrentPlanValid, setIsCurrentPlanValid] = useState(true);
  const [isDashboardReady, setIsDashboardReady] = useState(false);
  const [collectionSearchTerm, setCollectionSearchTerm] = useState("");
  const [queryLengthError, setQueryLengthError] = useState(false);

  const isTrialPlanActive = isTrialPeriodActive(userPlan);
  const isSubscriptionInactive = isSubscriptionEnded(userPlan);

  async function fetchQuestions(collecID) {
    const body = {
      collectionId: collecID,
      tankUserID: userTankID,
      spaceID: spaceID,
    };
    const res1 = await axios.post(questionsList, body);
    setQuestions(res1?.data);
  }

  async function fetchCollections(id) {
    if (spaceID) {
      await axios
        .get(`${collectionsList}/${spaceID}/${userTankID}`)
        .then((res) => {
          if (isOk(res)) {
            setCollections(res?.data);
          }
        })
        .catch((err) => console.log("ERR", err))
        .finally(() => setLoading(false));
      setCreateCollecId(id);
    }
  }

  const getUserTeams = async () => {
    const userEmail = signedInUserData?.email;
    const body = {
      emailID: userEmail,
    };
    const userTeamsData = await axios.post(signInCheck, body);
    localStorage.setItem(
      "userTeams",
      JSON.stringify(userTeamsData?.data?.data)
    );
    setUserTeams(userTeamsData?.data?.data);
  };

  const getUsersFromSpace = async (spaceId) => {
    const users = await axios.get(`${getAllUsersFromSpace}${spaceId}`);
    setTeamMembers(users?.data?.data);
  };

  const dashboardInit = () => {
    getUsersFromSpace(spaceID);
    getUserTeams();
    fetchCollections(createCollecId);
  };

  const setUserPlanData = async () => {
    const body = {
      orgID: userOrgID,
      membersEmailID: userEmail,
    };

    const planData = await axios.post(planDetails, body);

    if (isOk(planData)) {
      const planInfo = planData?.data?.data?.plan;
      const isPlanActive =
        isTrialPeriodActive(planInfo) || isSubscriptionPlanActive(planInfo);

      setUserPlan(planInfo);
      setIsDashboardReady(true);
      if (isPlanActive) {
        dashboardInit();
        setIsCurrentPlanValid(true);
      } else {
        setIsCurrentPlanValid(false);
      }
    }
  };

  const renderModal = () => {
    setQuesAdded(false);
    setIsModal(!isModal);
  };

  const handleCollectionDetailsPrefill = async (id) => {
    const COLLECTION_PREFILL_DELAY = 600;

    if (openEditCollectionModal === false) {
      setCollecName("");
      setCollecDesc("");
      setCollecColor("");
      setCollecScope("");
      setCollecGurus([]);
    }
    const collecDetails = await axios.get(`${getCollectionDetailsByID}${id}`);
    const collectionData = collecDetails?.data?.data?.[0];

    setCollecName(collectionData?.collectionName);
    setCollecDesc(collectionData?.collectionDesp);
    setCollecColor(collectionData?.colorCode);
    setCollecScope(collectionData?.scope);
    setCollecGurus(collectionData?.guru);

    setTimeout(() => {
      setCollectionLoading(false);
    }, COLLECTION_PREFILL_DELAY);
  };

  const handleCreateCollectionModalOpen = () => {
    setCreateCollectionModalOpen(true);
    closeDashboardDrawer(false);
  };

  const handleAddMembersModalOpen = () => {
    setAddMembersModalOpen(true);
    closeDashboardDrawer(false);
  };

  const handleAddMembersModalClose = () => {
    setAddMembersModalOpen(false);
  };

  const renderEditCollectionModal = (id) => {
    setEditCollectionModalOpen(!openEditCollectionModal);
    setCollectionLoading(true);
    handleCollectionDetailsPrefill(id);
    setColId(id);
  };

  const handleCreateCollectionModalClose = () => {
    setCreateCollectionModalOpen(false);
  };

  const handleEditCollectionModalOpen = () => {
    setEditCollectionModalOpen(true);
  };

  const handleEditCollectionModalClose = () => {
    setEditCollectionModalOpen(false);
  };

  const handleActiveTab = (name) => {
    setActiveTab(name);
    if (name === MY_NOTIFICATIONS) {
      setCounter(0);
    }
    closeDashboardDrawer(false);
  };

  const setCollectionIdFromCollectionPage = (id) => {
    setColId(id);
  };

  const handleSwitch = (userSpaceId) => {
    const userTeamsData = JSON.parse(localStorage.getItem("userTeams"));
    const selectedSpace = userTeamsData.filter(
      (team) => team?.spaceID === userSpaceId
    )?.[0];
    localStorage.setItem("userData", JSON.stringify(selectedSpace));
    window.location.href = DASHBOARD_PAGE;
  };

  const renderDashboardSideBar = (
    <div className="tank-drawer side-nav">
      <div className="sidebar logo">
        <Link to={DASHBOARD_PAGE}>
          <Logo imageUrl={TANK_LOGO_BLUE} />
        </Link>
      </div>
      {isTrialPlanActive && (
        <NewQuestionButton
          handleOnClick={renderModal}
          btnText={`+ ${NEW_QUERY}`}
        />
      )}
      <div className="sidebar-items-container">
        {!isTrialPlanActive ? (
          <>
            <List>
              <div className="menu-items">
                <Link to={BILLING_SETTINGS_PAGE}>{"Billing"}</Link>
              </div>
            </List>
          </>
        ) : (
          <>
            <List>
              {drawerMenuItems.map((item) => (
                <div key={item.id} className="menu-items">
                  {item.to !== null ? (
                    <Link
                      key={item.menuItem}
                      to={item.to}
                      onClick={() => handleActiveTab(item.menuItem)}
                      className={item.to === pathname ? "active" : ""}
                    >
                      {item.menuItem === MY_NOTIFICATIONS
                        ? `${MY_NOTIFICATIONS} ${counter > 0 ? counter : ""}`
                        : item.menuItem}
                    </Link>
                  ) : (
                    <ListItemText
                      className="drawer-item"
                      primary={item.menuItem}
                      onClick={
                        item.menuItem === INVITE_TEAM_MEMBERS
                          ? handleAddMembersModalOpen
                          : null
                      }
                    />
                  )}
                  {item.menuItem === COLLECTIONS &&
                  hasSuperUserPermission(userRole) ? (
                    <>
                      <AddBoxOutlinedIcon
                        onClick={handleCreateCollectionModalOpen}
                        fontSize="small"
                      />
                    </>
                  ) : null}
                  {item.menuItem === INVITE_TEAM_MEMBERS ? (
                    <>
                      <AddBoxOutlinedIcon
                        fontSize="small"
                        onClick={handleAddMembersModalOpen}
                      />
                    </>
                  ) : null}
                </div>
              ))}
            </List>
            <div className="empty-block"></div>
            {userRole === ROLE_ADMIN && isTrialPlanActive ? (
              <div className="second-items">
                <List>
                  {menuItemsBottom.map((item) => (
                    <div key={item.id} className="menu-items">
                      <Link key={item.menuItem} to={item.to}>
                        {item.menuItem}
                      </Link>
                    </div>
                  ))}
                </List>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );

  const renderSettingsSideBar = (
    <div className="tank-drawer settings">
      <div className="sidebar logo">
        <Link to={DASHBOARD_PAGE}>
          <Logo imageUrl={TANK_LOGO_BLUE} />
        </Link>
      </div>
      <Link to={DASHBOARD_PAGE} className="btn-navigate">
        Back to Dashboard
      </Link>
      <div className="settings-drawer">
        <p className="team-name-title">Team Name</p>
        <p className="team-name">{signedInUserData?.teamName}</p>
      </div>
      {isTrialPlanActive ? (
        <div className="sidebar-items-container">
          <List>
            {settingsDrawerItems.map((item) => (
              <div key={item.id} className="menu-items">
                <Link
                  key={item.menuItem}
                  to={item.to}
                  onClick={() => handleActiveTab(item.menuItem)}
                  className={activeTab === item.menuItem ? "active" : ""}
                >
                  {item.menuItem}
                </Link>
              </div>
            ))}
          </List>
        </div>
      ) : null}
    </div>
  );

  const drawer = (
    <>{!isSettingsPage ? renderDashboardSideBar : renderSettingsSideBar}</>
  );

  useEffect(() => {
    setUserPlanData();
  }, []);

  useEffect(() => {
    setCollectionTitle(collectionName);
  }, [collections]);

  useEffect(() => {
    if (!listening) {
      const events = new EventSource(
        `${subscribeEvents}/${spaceID}/${userTankID}`
      );

      events.onmessage = (event) => {
        const parsedData = JSON.parse(event.data);
        if (parsedData && parsedData.message) {
          setCounter((prevState) => prevState + 1);
        }

        // setFacts((facts) => facts.concat(parsedData));
      };

      setListening(true);
    }
  }, [listening]);

  if (!questions) {
    return null;
  }

  const handleNewQuestion = async (colId, queTitle, queContent) => {
    const createQuestionData = {
      tankUserID: userTankID,
      username: userName,
      collectionId: colId,
      quesTitle: queTitle,
      quesDesp: queContent,
      answers: [],
      spaceID: spaceID,
      mentions: selectedMembers,
    };

    try {
      if (queTitle.length <= QUERY_CHAR_LIMIT) {
        setQueryLengthError(false);
        await axios.post(`${createNewQuestion}`, createQuestionData);

        fetchQuestions(colId);
        setQuesAdded(true);
      } else {
        setQueryLengthError(true);
      }
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  const handleQuestionSaveDraft = (queTitle, quesContent, colId) => {
    if (queTitle.length <= QUERY_CHAR_LIMIT) {
      setQueryLengthError(false);
      saveQuestionAsDraft(
        queTitle,
        quesContent,
        colId,
        userTankID,
        creatorID,
        spaceID,
        selectedMembers
      );
      renderModal();
    } else {
      setQueryLengthError(true);
    }
  };

  const handleEditCollection = async (colName, colDesc, colColor, colGurus) => {
    const updatedCollection = {
      collectionName: colName,
      collectionDesp: colDesc,
      colorCode: colColor,
      guru: colGurus,
    };

    await axios.patch(
      `${editCollection}?collectionId=${colId}&spaceID=${spaceID}`,
      updatedCollection
    );

    fetchCollections(colId);
    setEditCollectionModalOpen(!openEditCollectionModal);
  };

  const closeCollectionDeleteModal = () => {
    setShowCollectionDeleteModal(false);
    setCollectionDeleteId("");
  };

  const handleCollectionDelete = async (id) => {
    await axios.delete(`${deleteCollection}${id}`);
    fetchCollections(id);
    closeCollectionDeleteModal();
  };

  const handleOptionSelection = (val) => {
    const members = val.map((member) => member.tankUserID);
    setSelectedMembers(members);
  };

  const closeDashboardDrawer = (close) => {
    setAnchor(close);
  };

  const openDashboardDrawer = (open) => {
    setAnchor(open);
  };

  const sideNav = () => (
    <div className={`${classes.root} side-nav`}>
      <CssBaseline />
      <div className={classes.drawer}>
        <Hidden smUp implementation="css" className="mobile-drawer">
          <Drawer
            variant="temporary"
            anchor={"left"}
            open={anchor}
            onClose={() => closeDashboardDrawer(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css" className="desktop-drawer">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    </div>
  );

  const openCollectionDeleteModal = (collecId) => {
    setCollectionDeleteId(collecId);
    setShowCollectionDeleteModal(true);
  };

  const mainDashboard = () => (
    <>
      {showCollectionDeleteModal ? (
        <CollectionDeleteModal
          cancelText={"CANCEL"}
          confirmText={"DELETE"}
          // moveToTrash={'MOVE TO TRASH'}
          close={closeCollectionDeleteModal}
          open={showCollectionDeleteModal}
          onCollectionDelete={() => handleCollectionDelete(collectionDeleteId)}
        />
      ) : null}
      <div className="collections-container page-top dashboard-vert-gutters">
        <div className="page-title-container">
          <p className="page-title-text">Overview</p>
          <p className="page-description-text">
            Find an overview of all your collections here.
          </p>
        </div>
        {loading ? (
          <>
            <div className="add-new-collection">
              <Shimmer variant="rect" width={260} height={44} />
            </div>
            <CollectionShimmer variant="rect" width="100%" height={153} />
            <CollectionShimmer variant="rect" width="100%" height={153} />
            <CollectionShimmer variant="rect" width="100%" height={153} />
          </>
        ) : (
          <>
            <div className="add-new-collection" key="add collection">
              <div className="collection-search-container">
                <InputField
                  placeholderText={"Search for collections"}
                  inputValue={collectionSearchTerm}
                  type={"text"}
                  inputTag={"input"}
                  handleOnChange={setCollectionSearchTerm}
                />
                <div className="collection-created-by">
                  <select>
                    <option value="none">Created By</option>
                    {collectionData?.map((collectionItem) => (
                      <option value={collectionItem?.creatorID}>
                        {collectionItem?.creatorID}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <TankButton
                btnText={"+ Create a Collection"}
                btnOnClick={handleCreateCollectionModalOpen}
              />
            </div>
            <Grid container spacing={6}>
              {collectionData &&
                collectionData.map((col) => {
                  const {
                    collectionId,
                    creatorID,
                    collectionName,
                    collectionDesp,
                    colorCode,
                    scope,
                    createdAt,
                    tankUserID,
                  } = col;
                  return (
                    <Collection
                      key={collectionId}
                      collecId={collectionId}
                      creatorID={creatorID}
                      userTankID={userTankID}
                      userClientID={spaceID}
                      collectionName={collectionName}
                      collectionDesp={collectionDesp}
                      colorCode={colorCode}
                      createdAt={createdAt}
                      handleEditCollectionModalOpen={
                        handleEditCollectionModalOpen
                      }
                      renderEditCollectionModal={renderEditCollectionModal}
                      handleCollectionDelete={handleCollectionDelete}
                      openCollectionDeleteModal={openCollectionDeleteModal}
                      userRole={userRole}
                      collectionCreatorID={tankUserID}
                      collectionScope={scope}
                    />
                  );
                })}
            </Grid>
          </>
        )}
      </div>
    </>
  );

  const renderTrialPlanExpiredMessage = () => (
    <div className="trial-expiry">
      <h1>Your free trial has expired.</h1>
      <p>Please select a plan from our pricing page to continue.</p>
      <Link to={BILLING_SETTINGS_PAGE} className="btn-navigate">
        Go to billing
      </Link>
    </div>
  );

  const renderSubscriptionExpiredMessage = () => (
    <div className="subscription-expiry">
      <h1>You don't have an active plan</h1>
      <p>Please select a plan from our pricing page to continue.</p>
      <Link to={BILLING_SETTINGS_PAGE} className="btn-navigate">
        Go to billing
      </Link>
    </div>
  );

  const renderContent = () => {
    switch (signedInUserData) {
      case null:
        return <Redirect to={LOGOUT_PAGE} />;
      default:
        return (
          <>
            {sideNav()}
            <div className="adjusted-container full-page-height">
              <DashboardHeader
                currentTeamData={signedInUserData}
                userTeams={userTeams}
                handleSwitch={handleSwitch}
                handleTeamSwitch={handleTeamSwitch}
                openDashboardDrawer={openDashboardDrawer}
                userPlan={userPlan}
                showSearch={isTrialPlanActive}
              />
              <div className="dashboard screen-max-width dashboard-gutters page-loader-pos">
                {!isTrialPlanActive || isSubscriptionInactive ? (
                  <>
                    <Switch>
                      <Route
                        exact
                        path={DASHBOARD_PAGE}
                        render={() =>
                          !isTrialPlanActive
                            ? renderTrialPlanExpiredMessage()
                            : renderSubscriptionExpiredMessage()
                        }
                      />
                      <Route
                        path={SETTINGS_PAGE}
                        render={(props) => (
                          <SettingsPage
                            {...props}
                            currentTeamData={signedInUserData}
                          />
                        )}
                      />
                    </Switch>
                  </>
                ) : (
                  <>
                    <Switch>
                      <Route
                        exact
                        path={`${COLLECTION_QUESTIONS_PAGE}/:id`}
                        render={(props) => (
                          <CollectionQuestionsPage
                            {...props}
                            currentTeamData={signedInUserData}
                            renderModal={renderModal}
                            updatedQues={questions}
                            isInterests={false}
                            userTankID={userTankID}
                            userClientID={spaceID}
                            creatorID={creatorID}
                            userRole={userRole}
                            setCollectionIdFromCollectionPage={
                              setCollectionIdFromCollectionPage
                            }
                            teamMembers={
                              teamMembers && teamMembers.length
                                ? teamMembers
                                : []
                            }
                            selectedMembers={selectedMembers}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`${QUESTION_DETAIL_PAGE}/:id`}
                        render={(props) => (
                          <QuestionDetailPage
                            {...props}
                            colId={collectionId}
                            updateQuestion={fetchQuestions}
                            currentTeamData={signedInUserData}
                            userTankID={userTankID}
                            userClientID={spaceID}
                            creatorID={creatorID}
                            userRole={userRole}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`${GET_STARTED_PAGE}/:id`}
                        render={(props) => (
                          <GetStartedQuestionDetailPage {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={MY_INTERESTS_PAGE}
                        render={(props) => (
                          <InterestsPage
                            {...props}
                            currentTeamData={signedInUserData}
                            renderModal={renderModal}
                            updatedQues={questions}
                            userTankID={userTankID}
                            userClientID={spaceID}
                            creatorID={creatorID}
                            userRole={userRole}
                            selectedMembers={selectedMembers}
                          />
                        )}
                      />
                      <Route
                        path={SETTINGS_PAGE}
                        render={(props) => (
                          <SettingsPage
                            {...props}
                            currentTeamData={signedInUserData}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={MY_DRAFTS_PAGE}
                        render={(props) => (
                          <MyDraftsPage
                            {...props}
                            currentTeamData={signedInUserData}
                            userTankID={userTankID}
                            userClientID={spaceID}
                            creatorID={creatorID}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={MY_POSTS_PAGE}
                        render={(props) => (
                          <MyPostsPage
                            {...props}
                            currentTeamData={signedInUserData}
                            userTankID={userTankID}
                            userClientID={spaceID}
                            creatorID={creatorID}
                            userRole={userRole}
                            selectedMembers={selectedMembers}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={DASHBOARD_PAGE}
                        render={(props) => (
                          <NotificationsPage
                            {...props}
                            userTankID={userTankID}
                            userClientID={spaceID}
                            name={""}
                            currentTeamData={signedInUserData}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={ANALYTICS_PAGE}
                        render={() => (
                          <AnalyticsPage
                            spaceID={spaceID}
                            userPlan={userPlan}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={COLLECTIONS_PAGE}
                        render={mainDashboard}
                      />
                    </Switch>
                    {isModal ? (
                      <NewQuestionModal
                        addQues={handleNewQuestion}
                        open={isModal}
                        close={renderModal}
                        userClientID={spaceID}
                        handleQuestionSaveDraft={handleQuestionSaveDraft}
                        colId={colId}
                        collectionData={collectionData}
                        teamMembers={
                          teamMembers && teamMembers.length ? teamMembers : []
                        }
                        teamMemberNameKey={"name"}
                        handleOptionSelection={handleOptionSelection}
                        feedbackMsgEnabled={quesAdded}
                        queryLengthError={queryLengthError}
                      />
                    ) : null}
                    {openCreateCollectionModal ? (
                      <CreateCollectionModal
                        currentTeamData={signedInUserData}
                        open={openCreateCollectionModal}
                        close={handleCreateCollectionModalClose}
                        updateCollections={fetchCollections}
                        userClientID={spaceID}
                        teamMembers={
                          teamMembers && teamMembers.length ? teamMembers : []
                        }
                        teamMemberNameKey={"name"}
                        handleOptionSelection={handleOptionSelection}
                        selectedMembers={selectedMembers}
                        userRole={userRole}
                        userTankID={userTankID}
                      />
                    ) : null}
                    {openEditCollectionModal ? (
                      <EditCollectionModal
                        open={openEditCollectionModal}
                        close={handleEditCollectionModalClose}
                        loading={collectionLoading}
                        collecName={collecName}
                        collecDesc={collecDesc}
                        collecColor={collecColor}
                        collecScope={collecScope}
                        collecGurus={collecGurus}
                        handleEditCollection={handleEditCollection}
                        userClientID={spaceID}
                        teamMembers={
                          teamMembers && teamMembers.length ? teamMembers : []
                        }
                        teamMemberNameKey={"name"}
                        selectedMembers={selectedMembers}
                        handleOptionSelection={handleOptionSelection}
                        userRole={userRole}
                      />
                    ) : null}
                    {openAddMembersModal ? (
                      <AddTeamMembersModal
                        open={openAddMembersModal}
                        close={handleAddMembersModalClose}
                        currentTeamData={signedInUserData}
                      />
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="dashboard-content">
      {!isDashboardReady ? (
        <LoaderWithBackdrop
          show={!isDashboardReady}
          label={"Loading dashboard..."}
        />
      ) : (
        renderContent()
      )}
    </div>
  );
};

Dashboard.propTypes = {
  window: PropTypes.func,
};

export default Dashboard;
