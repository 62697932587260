import React from 'react';

import Shimmer from '..';

import './CollectionShimmer.scss';

const CollectionShimmer = props => {
  const { variant, width, height } = props;

  return (
    <div className='collection-shimmer'>
      <Shimmer variant={variant} width={width} height={height} />
    </div>
  );
}

export default CollectionShimmer;
