import React from 'react';

import Shimmer from '..';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './TableShimmer.scss';

const TableShimmer = props => {
  const { variant, width } = props;

  return (
    <div className='table-shimmer'>
      <TableContainer>
        <Table aria-label="shimmer table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#e2e2e2' }}>
              <TableCell><Shimmer variant={variant} width={width} height={20} /></TableCell>
              {[1, 2, 3, 4].map(cell => <TableCell key={cell} align="right" style={{ padding: '16px 24px' }}><Shimmer variant={variant} width={width} height={20} /></TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3].map((row) => (
              <TableRow key={row}>
                <TableCell component="th" scope="row"><Shimmer variant={variant} width={width} height={45} /></TableCell>
                <TableCell align="right" style={{ padding: '16px 24px' }}><Shimmer variant={variant} width={width} height={45} /></TableCell>
                <TableCell align="right" style={{ padding: '16px 24px' }}><Shimmer variant={variant} width={width} height={45} /></TableCell>
                <TableCell align="right" style={{ padding: '16px 24px' }}><Shimmer variant={variant} width={width} height={45} /></TableCell>
                <TableCell align="right" style={{ padding: '16px 24px' }}>
                  <Shimmer variant={variant} width={width} height={45} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableShimmer;
