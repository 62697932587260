import React, { useState } from "react";

import {
  formatDateDMY,
  isCurrentUserCreated,
  hasAdminModificationPriviledge,
} from "../../utils";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip from "@material-ui/core/Chip";

import DeleteModal from "../Modals/DeleteModal/DeleteModal";

import {
  DELETE_RESPONSE_CONFIRM,
  ANSWERED_BY,
  ANSWER_VERIFIED,
} from "../../constants/Answer";
import { CANCEL, CONFIRM } from "../../constants/ButtonLabel";

import "./Answer.scss";

const Answer = (props) => {
  const {
    answerData: {
      answerId,
      answerDesp,
      answeredBy,
      createdTime,
      tankUserID,
      verifiedState,
    },
    quesID,
    deleteAnswer,
    openEditModal,
    currentTeamData,
    userRole,
  } = props;

  const isAnswerVerified = verifiedState === ANSWER_VERIFIED;

  const isCreatedByLoggedInUser = isCurrentUserCreated(
    tankUserID,
    currentTeamData?.tankUserID
  );
  const hasModificationAccess = hasAdminModificationPriviledge(
    userRole,
    tankUserID,
    currentTeamData?.tankUserID
  );

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const confirmDelete = () => (
    <DeleteModal
      confirmDeleteMsg={DELETE_RESPONSE_CONFIRM}
      cancelText={CANCEL}
      confirmText={CONFIRM}
      onDelete={() => deleteAnswer(quesID, answerId)}
      close={handleClose}
      open={open}
    />
  );

  const renderAnswerVerificationStatus = () => (
    <span className="verification-label">
      <Chip
        className={`verification-icon ${
          isAnswerVerified ? "verified" : "pending"
        }`}
        avatar={<CheckCircleIcon fontSize="small" />}
        label={isAnswerVerified ? "Verified" : "Pending verification"}
        clickable={false}
      />
    </span>
  );

  return (
    <>
      {deleteAnswer !== undefined ? confirmDelete() : null}
      <div key={answerId} className="answer-entry">
        <div className="answer-block">
          <p
            className="user-answer"
            dangerouslySetInnerHTML={createMarkup(answerDesp)}
          />
          {createdTime !== undefined ? (
            <p className="answer-detail">
              {ANSWERED_BY}: {answeredBy} on {formatDateDMY(createdTime)}
            </p>
          ) : null}
          {renderAnswerVerificationStatus()}
        </div>
        {deleteAnswer !== undefined &&
        (isCreatedByLoggedInUser || hasModificationAccess) ? (
          <div className="answer-modify">
            {hasModificationAccess ? (
              <DeleteOutlineOutlinedIcon
                onClick={handleOpen}
                fontSize="small"
              />
            ) : (
              <>
                <EditOutlinedIcon
                  onClick={() => openEditModal(answerId, quesID)}
                  fontSize="small"
                />
                <DeleteOutlineOutlinedIcon
                  onClick={handleOpen}
                  fontSize="small"
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Answer;
