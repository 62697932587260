import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

const Shimmer = props => {
  const { variant, width, height } = props;

  return <Skeleton variant={variant} width={width} height={height} animation="pulse" />
}

export default Shimmer;
