import React from 'react';
import { Link } from "react-router-dom";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { COLLECTION_QUESTIONS_PAGE } from '../../constants/PageRoutes';

import './BackButton.scss';

const BackButton = ({questionCollecId}) => {
  return (
    <div className="back-btn">
      <Link className="back-btn-item" to={`${COLLECTION_QUESTIONS_PAGE}/${questionCollecId}`}>
        <ArrowBackIosIcon fontSize="small" />
        Back
      </Link>
    </div>
  );
}

export default BackButton;
