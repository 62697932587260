import React from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { FEATURES_DATA } from "../../constants/LandingPage/FeaturesSection";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import Slider from "react-slick";
import {
  SIGNUP_PAGE,
  INTEGRATIONS_PAGE,
  PRICING_PAGE,
} from "../../constants/PageRoutes";

import "./LandingPage.scss";

const LandingPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="tank-landing space-top">
      <section className="intro-section">
        <img
          className="beige-shape"
          src={require("../../assets/images/beige-shape.png")}
          alt="intro"
        />
        <div className="inner-section">
          <div className="intro-inner screen-max-width">
            <Grid container spacing={4} className="intro-container">
              <Grid item xs={12} md={6} lg={6} className="intro-info">
                <h1 className="section-title">
                  Remote teams, centralized information.
                </h1>
                <p className="subheading intro">
                  One tool for your team to build knowledge efficiently, resolve
                  queries faster, and collaborate effectively.
                </p>
                <div className="get-started-container">
                  <Link to={SIGNUP_PAGE} className="get-started-btn">
                    Try Tank for Free
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="assets-container">
                <div className="asset-transform">
                  <div className="asset-transform-inner">
                    <div className="asset-wrap one">
                      <img
                        src={require("../../assets/images/analyticslp.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap two">
                      <img
                        src={require("../../assets/images/docs.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap three">
                      <img
                        src={require("../../assets/images/onboard.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap four">
                      <img
                        src={require("../../assets/images/ques-ans2.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap five">
                      <img
                        src={require("../../assets/images/remote-work.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap six">
                      <img
                        src={require("../../assets/images/search2.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap seven">
                      <img
                        src={require("../../assets/images/team-work.png")}
                        alt="name"
                      />
                    </div>
                    <div className="asset-wrap eight">
                      <img
                        src={require("../../assets/images/slacklp.png")}
                        alt="name"
                      />
                    </div>
                  </div>
                </div>
                <div className="feature-wheel-asset">
                  <img
                    src={require("../../assets/images/center-wheel.png")}
                    alt="wheel"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <section className="features-section">
        <div className="features-block screen-max-width">
          <h2 className="section-title center">
            Tank offers solutions for every team in your organization
          </h2>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Grid className="features-blocks-wrapper" container spacing={10}>
                {FEATURES_DATA.map((feature, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    className="feat-grid-item full-width-block"
                  >
                    <FeatureCard>
                      <Grid container>
                        <Grid item xs={12} lg={12} className="feat-content">
                          <div className="feature-img">
                            <img src={feature.img} alt={feature.featureTitle} />
                          </div>
                          <p className="feat-title">{feature.featureTitle}</p>
                          <p className="feat-desc">{feature.featureDesc}</p>
                        </Grid>
                      </Grid>
                    </FeatureCard>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="slack-feat feature-block solid-grey-bg">
        <div className="screen-max-width">
          <div className="section-info">
            <h2 className="section-title center">
              Managing information made easier with our Slack integration
            </h2>
            <p className="subheading">
              Search information or post queries directly to your Tank workspace
              from any Slack channel.
            </p>
            <div className="learn-more-btn">
              <Link className="btn-navigate" to={INTEGRATIONS_PAGE}>
                <img
                  alt="Add to Slack"
                  height="40"
                  width="139"
                  src="https://platform.slack-edge.com/img/add_to_slack.png"
                  srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                />
              </Link>
            </div>
          </div>
          <div className="slack-asset section-asset">
            <Slider {...settings}>
              <div>
                <img
                  src={require("../../assets/images/Post_question_Slack_creative.png")}
                  alt="clarity"
                />
              </div>
              <div>
                <img
                  src={require("../../assets/images/Search_Slack_Creative.png")}
                  alt="clarity"
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="collec-feat feature-block solid-grey-bg">
        <div className="bg-ele"></div>
        <img
          className="shapes-bg"
          src={require("../../assets/images/shapes-bg.png")}
          alt="collections"
        />
        <div className="screen-max-width">
          <div className="section-info">
            <h2 className="section-title center">
              Have greater control over your team’s knowledge with Collections
            </h2>
            <p className="subheading">
              Collections enable you to categorise information in a way that’s
              relevant to you and your team.
            </p>
          </div>
          <div className="collec-asset section-asset shadow">
            <img
              src={require("../../assets/images/Collections_creative.png")}
              alt="collec"
            />
          </div>
        </div>
      </section>
      <section className="clarity feature-block alt-white-section">
        <div className="screen-max-width">
          <div className="section-info">
            <h2 className="section-title center">
              Tank brings clarity at the centre of your team.
            </h2>
            <div className="subheading">
              With Tank being the single source of truth, every member of the
              team is aware of any developments within the team.
            </div>
          </div>
          <div className="clarity-asset section-asset">
            <img
              src={require("../../assets/images/clarity-img.png")}
              alt="clarity"
            />
          </div>
        </div>
      </section>
      <section className="remote-jobs-easy feature-block alt-white-section">
        <div className="screen-max-width">
          <div className="section-info">
            <h2 className="section-title center">Make remote jobs easy</h2>
            <p className="subheading">
              Tank’s intranet makes it easier for remote teams to share and grow
              their knowledge enabling them to solve tasks faster from anywhere
              and at any hour.
            </p>
          </div>
          <div className="remote-jobs-easy-asset section-asset">
            <img
              src={require("../../assets/images/remote-work-easy.png")}
              alt="clarity"
            />
          </div>
        </div>
      </section>
      <section className="pricing-plans alt-white-section">
        <div className="screen-max-width">
          <div className="section-info">
            <h2 className="section-title center">
              Plans that start free and grow with you
            </h2>
            <div className="subheading">
              Simple, transparent pricing that grows with you.
            </div>
          </div>
          <div className="btns-wrapper">
            <Link to={SIGNUP_PAGE} className="primary-btn start-free">
              Start for free
            </Link>
            <Link to={PRICING_PAGE} className="primary-btn detail-plan">
              Detailed plan offerings
            </Link>
          </div>
          <Grid container spacing={6} className="plans">
            <Grid item xs={12} sm={4} md={4} lg={3} className="plan-type">
              <div className="plan-info-container">
                <div className="plan-info">
                  <p className="type">Free</p>
                  <p className="amount">
                    <span>&#8377;</span>0
                  </p>
                  <p className="cycle">/user/month</p>
                </div>
                <p className="plan-feature">Pro Plan (14 Days Trial)</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3} className="plan-type">
              <div className="plan-info-container">
                <div className="plan-info">
                  <p className="type">Basic</p>
                  <p className="amount">
                    <span>&#8377;</span>20
                  </p>
                  <p className="cycle">/user/month</p>
                </div>
                <p className="plan-feature">50 Collections</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3} className="plan-type">
              <div className="plan-info-container">
                <div className="plan-info">
                  <p className="type">Pro</p>
                  <p className="amount">
                    <span>&#8377;</span>50
                  </p>
                  <p className="cycle">/user/month</p>
                </div>
                <p className="plan-feature">
                  Unlimited Collections and many more
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
