import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Button from "@material-ui/core/Button";
import { editSpace } from '../../../../api/api';
import { isOk } from '../../../../utils';

import InputField from '../../../../components/InputField/InputField';
import Spinner from '../../../../components/Loader/Spinner';

const TeamName = props => {
  const { teamName: currentTeamName, currentSpaceID } = props;
  const [showNameConfig, setShowNameConfig] = useState(false);
  const [showTeamNameModifyBtn, setShowTeamNameModifyBtn] = useState(false);
  const [teamName, setTeamName] = useState(props.teamName);
  const [isTeamNameUpdating, setIsTeamNameUpdating] = useState(false);

  const toggleNameConfig = () => {
    setShowNameConfig(!showNameConfig);
  }

  const handleTeamNameChange = val => {
    setTeamName(val);
  }

  const resetEnteredTeamName = () => {
    setTeamName(currentTeamName);
  }

  useEffect(() => {
    if ((teamName.trim() === currentTeamName) || (teamName.trim() === '')) {
      setShowTeamNameModifyBtn(false);
    } else {
      setShowTeamNameModifyBtn(true);
    }
  }, [teamName, currentTeamName, showTeamNameModifyBtn]);

  useEffect(() => {
    if (!showNameConfig && (teamName !== currentTeamName)) {
      setTeamName(currentTeamName);
      setShowTeamNameModifyBtn(false);
    }
  }, [showNameConfig, currentTeamName, teamName]);

  const updateTeamName = () => {
    setIsTeamNameUpdating(true);

    const payload = {
      presentSpaceName: currentTeamName,
      updatedSpaceName: teamName
    };

    axios.post(`${editSpace}/${currentSpaceID}`, payload)
      .then(res => {
        console.log(res);
        if (isOk(res)) {
          const signedInUserData = JSON.parse(localStorage.getItem('userData'));
          const updatedData = {...signedInUserData, teamName};
          console.log(updatedData);
          localStorage.setItem('userData', JSON.stringify(updatedData));
          window.location.reload();
        }
      }).finally(() => setIsTeamNameUpdating(false));
  };

  return (
    <div className='team-config team-name'>
      <div className='section-toggle'>
        <p className='title'>Name</p>
        <Button
          onClick={toggleNameConfig}
          variant="contained"
          color="secondary"
          className='primary-btn'
        >
          {showNameConfig ? 'Close' : 'Expand'}
        </Button>
      </div>
      <p className='help-text'>Manage your team name</p>
      {
        showNameConfig
        ?
        <div className='config-content'>
          <div className="team-name-change">
            <InputField
              labelText={'Team name'}
              inputValue={teamName}
              type={'text'}
              inputTag={'input'}
              handleOnChange={handleTeamNameChange}
            />
          </div>
          {
            showTeamNameModifyBtn
            ?
            <div className="btns-container">
              <Button onClick={resetEnteredTeamName} disabled={isTeamNameUpdating} variant='contained' color='secondary' className='primary-btn reset grey-btn'>Reset</Button>
              <Button onClick={updateTeamName} disabled={isTeamNameUpdating} variant='contained' color='secondary' className='primary-btn'>Save</Button>
              {isTeamNameUpdating ? <Spinner color="blue" size={15} /> : null}
            </div>
            : null
          }
        </div> : null
      }
    </div>
  );
}

export default TeamName;
