import React, { useState, useEffect } from "react";
import axios from "axios";

import { v4 as uuidv4 } from "uuid";

import { createCollection } from "../../../api/api";
import {
  ENTER_COLLECTION_TITLE,
  ENTER_COLLECTION_DESCRIPTION,
  SELECT_COLOR,
  CREATE_COLLECTION_TITLE,
} from "../../../constants/Collection";

import { CANCEL, CREATE } from "../../../constants/ButtonLabel";

import Loader from "../../Loader/Loader";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";

import { useDebounce } from "../../../Hooks/useDebounce";

import ColorPalette from "../../ColorPalette/ColorPalette";
import Modal from "../Modal/Modal";
import InputField from "../../InputField/InputField";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import { isOnlyLetters, COLLECTION_NAME_CHAR_LIMIT } from "../../../utils";

import "./CreateCollectionModal.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DEBOUNCE_DELAY = 300;

const CreateCollectionModal = (props) => {
  const classes = useStyles();
  const {
    open,
    // close,
    updateCollections,
    userClientID,
    teamMembers,
    teamMemberNameKey,
    handleOptionSelection,
    selectedMembers,
    userTankID,
    currentTeamData,
  } = props;

  const collectionCreatorName = currentTeamData?.name;

  const [isLoading, setIsLoading] = useState(false);
  const [color, setColor] = useState("");
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDesc, setCollectionDesc] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [collectionCreated, setCollectionCreated] = useState(false);
  const [formError, setFormError] = useState(false);

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const debouncedCollectionTitle = useDebounce(collectionTitle, DEBOUNCE_DELAY);
  const debouncedCollectionDesc = useDebounce(collectionDesc, DEBOUNCE_DELAY);
  let isDataEntered = false;
  if (
    debouncedCollectionTitle.length > 0 &&
    debouncedCollectionTitle.length <= COLLECTION_NAME_CHAR_LIMIT &&
    debouncedCollectionDesc.length > 0 &&
    color !== "" &&
    selectedMembers.length > 0
  ) {
    isDataEntered = true;
  } else {
    isDataEntered = false;
  }

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  useEffect(() => {
    if (!open) {
      setCollectionTitle("");
      setColor("");
      setCollectionDesc("");
    }
  }, [open]);

  useEffect(() => {
    if (isDataEntered) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [
    debouncedCollectionTitle,
    debouncedCollectionDesc,
    color,
    selectedMembers,
    isDataEntered,
  ]);

  const createCollectionData = {
    collectionId: "",
    creatorID: collectionCreatorName,
    collectionName: debouncedCollectionTitle,
    collectionDesp: debouncedCollectionDesc,
    colorCode: color,
    scope: "Public",
    spaceID: userClientID,
    guru: selectedMembers,
    tankUserID: userTankID,
  };

  const postCreateCollectionData = () => {
    if (isOnlyLetters(collectionTitle) || isOnlyLetters(collectionDesc)) {
      setFormError(false);
      setIsLoading(true);
      axios
        .post(createCollection, createCollectionData)
        .then(() => {
          props.close();
        })
        .then(() => updateCollections(uuidv4()))
        .then(() => setCollectionCreated(true))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setFormError(true);
    }
  };

  const collectionTitleMarkup = (
    <InputField
      labelText={ENTER_COLLECTION_TITLE}
      inputValue={collectionTitle}
      type={"text"}
      inputTag={"input"}
      handleOnChange={setCollectionTitle}
    />
  );

  const collectionDescMarkup = (
    <InputField
      labelText={ENTER_COLLECTION_DESCRIPTION}
      inputValue={collectionDesc}
      type={"text"}
      inputTag={"textarea"}
      handleOnChange={setCollectionDesc}
    />
  );

  const collectionColorSelectMarkup = (
    <>
      <InputField labelText={SELECT_COLOR} />
      <FormControl className={`${classes.formControl} dropdown-comp colors`}>
        <Select
          labelId="demo-simple-select-label-1"
          id="demo-simple-select-1"
          value={color}
        >
          <MenuItem className="color-dropdown">
            <ColorPalette colorChange={handleColorChange} colorValue={color} />
          </MenuItem>
        </Select>
        <div
          className="selected-color"
          style={{ backgroundColor: color }}
        ></div>
      </FormControl>
    </>
  );

  return (
    <div className="new-collection">
      <Modal
        modalTitle={CREATE_COLLECTION_TITLE}
        modalDisplay={divStyle}
        close={props.close}
        cancelText={CANCEL}
        provisionType={CREATE}
        isDisabled={isValid}
        handleProvisioning={postCreateCollectionData}
        isLoading={isLoading}
        feedbackMsgEnabled={collectionCreated}
        feedbackMsg={"Collection created successfully"}
        formError={formError}
        formErrMsg={"Enter valid input"}
      >
        <div
          className={`tank-modal create-collection ${
            isLoading ? "creating-collec" : ""
          }`}
        >
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="collection-modal-data">
                  {collectionTitleMarkup}
                  {collectionDescMarkup}
                  <Grid container spacing={2} className="dd-group">
                    <Grid item xs={6} md={3} lg={3}>
                      {collectionColorSelectMarkup}
                    </Grid>
                    <Grid item xs={6} md={9} lg={9}>
                      <SearchDropdown
                        label={"Add Gurus"}
                        options={teamMembers}
                        teamMemberNameKey={teamMemberNameKey}
                        handleOptionSelection={handleOptionSelection}
                      />
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateCollectionModal;
