import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import InputField from '../InputField/InputField';

import './SelectDropdown.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tooltipIcon: {
    color: 'grey',
    fontSize: '15px'
  }
}));

const SelectDropdown = (props) => {
  const classes = useStyles();
  const { labelText, value, handleOnChange, options, isRequired, isTooltip, isUserMember, selectedTeam } = props;

  return (
    <>
      {
        labelText && labelText.length
        ?
          isTooltip ?
            <div className='label-tooltip'>
              <InputField labelText={labelText} isRequired={isRequired} />
              <Tooltip className={`${classes.tooltipIcon} tooltip-icon`} title="Only Private collections allowed for Members" placement='top'>
                <HelpOutlineIcon fontSize='small' />
              </Tooltip>
            </div>
          : <InputField labelText={labelText} isRequired={isRequired} />
        : null
      }
      <FormControl className={`${classes.formControl} dropdown-comp`}>
        <Select
          labelId="select-dropdown"
          id="demo-simple-select"
          value={value}
          onChange={handleOnChange}
          className="selected-option"
          disabled={isUserMember}
        >
          {options && options.map(s => {
            if (s) {
              if (typeof s === 'object' && ("id" in s)) return <MenuItem key={s.id} className="dropdown-menu-item" name={s.id} value={s.name}>{s.name}</MenuItem>;

              if (typeof s === 'string') return <MenuItem key={s} className="dropdown-menu-item" name={s} value={s}>{s}</MenuItem>;

              return <MenuItem key={s.spaceID} onClick={() => selectedTeam(s)} className="dropdown-menu-item" value={s.teamName}>{s.teamName}</MenuItem>;
            }
          })}
        </Select>
      </FormControl>
    </>
  );
}

export default SelectDropdown;
