import React from 'react';

import Modal from '../Modal/Modal';

import './CollectionDeleteModal.scss';

const CollectionDeleteModal = props => {
  const { cancelText, confirmText, close, open, onCollectionDelete, moveToTrash } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  return (
    <div className='delete-collection'>
      <Modal
        close={close}
        modalDisplay={divStyle}
        isDisabled={false}
        cancelText={cancelText}
        // moveToTrash={moveToTrash}
        provisionType={confirmText}
        handleProvisioning={onCollectionDelete}
      >
        <div className='collection-delete-modal'>
          <p className='delete-disclaimer'>Deleting the collection will remove all it's contents as well. We recommend that you move the queries that you think are important to you to another collection before going ahead.</p>
          {/* <p className='action'>
            <span>Purge:</span> Permanently deletes the collection and the queries inside.
          </p>
          <p className='action'>
            <span>Move to trash:</span> Temporarily move to trash if you want an option to restore later.
          </p> */}
        </div>
      </Modal>
    </div>
  );
}

export default CollectionDeleteModal;
