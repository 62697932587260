import React, { useState, useEffect } from 'react';

import { useGetCollections } from '../../../Hooks/getCollections';
import { collectionsList } from '../../../api/api';

import Modal from '../Modal/Modal';

import SelectDropdown from '../../SelectDropdown/SelectDropdown';

import './MoveQuestionModal.scss';

const MoveQuestionModal = props => {
  const { close, open, collectionTitle, userClientID, handleMoveQuestionToCollection, currentCollecId, userTankID } = props;

  const [collectionName, setCollectionName] = useState('');
  const [collecId, setCollecId] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const [collections] = useGetCollections(`${collectionsList}/${userClientID}/${userTankID}`);

  useEffect(() => {
    if (!open) {
      setCollectionName('');
      setIsDisabled(true);
    }
  }, [open]);

  useEffect(() => {
    if (collecId !== currentCollecId) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [collecId]);

  const collectionNames = [];
  if (collections && collections.data) {
    collections.data.map(collec => {
      collectionNames.push({
        id: collec.collectionId,
        name: collec.collectionName
      });
    });
  }

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const handleCollectionSelectChange = (event, child) => {
    setCollectionName(event.target.value);
    setCollecId(child.key.replace('.$', ''));
  };

  return (
    <div className='move-query'>
      <Modal
        close={close}
        modalDisplay={divStyle}
        isDisabled={isDisabled}
        cancelText={'CANCEL'}
        provisionType={'MOVE'}
        modalTitle={'Move to another collection'}
        handleProvisioning={() => handleMoveQuestionToCollection(collecId)}
      >
        <SelectDropdown
          labelText={'Select collection'}
          value={collectionName === '' ? collectionTitle : collectionName}
          handleOnChange={handleCollectionSelectChange}
          options={collectionNames}
        />
      </Modal>
    </div>
  );
}

export default MoveQuestionModal;
