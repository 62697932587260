import React from "react";
import DefaultAvatarImg from "../DefaultAvatarImg/DefaultAvatarImg";
import './UserAvatarIcon.scss';

const UserAvatarIcon = (props) => {
  const { userImage, size = 'medium' } = props;

  return (
    <span className={`user-dp ${size}`}>
      {userImage ? (
        <img src={userImage?.[0]?.dp || userImage} alt="avatar" />
      ) : (
        <DefaultAvatarImg />
      )}
    </span>
  );
};

export default UserAvatarIcon;
