import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import InputField from '../InputField/InputField';

import './Repeater.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const Repeater = (props) => {
  const classes = useStyles();

  const HEIGHT_THRESHOLD = 200;

  const [containerHeight, setContainerHeight] = useState(0);
  const ref = useRef(null);

  const { emailValue, emailsList, handleOnKeyDown, handleOnEmailChange, handleEmailDelete, emailError, handleEmailPaste } = props;

  useEffect(() => {
    if (emailsList.length) {
      setContainerHeight(ref.current.clientHeight);
    }
  }, [emailsList]);

  return (
    <div className={`repeater-control ${emailError && 'has-error'}`}>
      {
        emailsList.length ? 
        <div ref={ref} className={`${classes.root} email-chips ${(containerHeight > HEIGHT_THRESHOLD) ? 'scroll' : ''}`}>
          {
            emailsList.map(email => (
              <Chip
                key={email}
                label={email}
                onDelete={() => handleEmailDelete(email)}
                variant="outlined"
              />
            ))
          }
        </div> : null
      }
      <InputField
        inputValue={emailValue}
        type={'email'}
        inputTag={'input'}
        placeholderText={'Add email address and press Enter/return key'}
        handleOnKeyDown={handleOnKeyDown}
        handleOnChange={handleOnEmailChange}
        handleOnPaste={handleEmailPaste}
      />
      {emailError && <p className="error">{emailError}</p>}
    </div>
  );
}

export default Repeater;
