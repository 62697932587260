import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Spinner from "../../components/Loader/Spinner";

import {
  USER_SIGN_IN,
  CURRENT_USER,
  CURRENT_USER_DATA,
  USER_SIGN_UP,
  ACTION_ID,
  SET_ONBOARD_USER_DATA,
  TEAM_INVITE,
} from "../../actions/types";

import Modal from "../../components/Modals/Modal/Modal";

import { signInCheck, verifyEmail, getUserById } from "../../api/api";
import { isEmailValid, isOk } from "../../utils";
import { TANK_LOGO_BLUE } from "../../constants";
import {
  ORGANIZATION_PAGE,
  SETTING_UP_PAGE,
  HOME_PAGE,
  FORGOT_PASSWORD_PAGE,
  GOOGLE_AUTH_PAGE,
  SIGNUP_PAGE,
} from "../../constants/PageRoutes";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import Logo from "../../components/Logo/Logo";

import "./UserSignInPage.scss";

const REDIRECT_DELAY = 2000;
class UserSignInPage extends Component {
  state = {
    userEnteredEmail: "",
    userEnteredPassword: "",
    userSelectedTeam: "",
    userTeamsList: [],
    noTeamsData: false,
    userDataFromAPI: [],
    loading: false,
    getStartedUser: "",
    emailBtnDisabled: true,
    errMsg: "",
    passwordMsg: "",
    signInBtnDisabled: true,
    emailErr: false,
    passwordErr: false,
    formErr: false,
    formErrMsg: "",
    credentialsErr: false,
    showPassword: false,
    unverifedEmail: false,
    showVerificationEmailSentModal: false,
    isVerifyEmailInProgress: false,
    verifyEmailErrMsg: "",
    existingUserId: "",
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;

    const urlParams = new URLSearchParams(search);
    const userEmailVal = urlParams.get("email");
    const toVerifyUserId = urlParams.get("userId");

    if (userEmailVal?.length) {
      this.setState({ getStartedUser: userEmailVal });
      this.getTeamsFromEmail(userEmailVal);
    }

    if (toVerifyUserId?.length) {
      this.setState({ existingUserId: toVerifyUserId });
      axios
        .get(`${getUserById}/${toVerifyUserId}`)
        .then((getUserByIdResponse) => {
          if (isOk(getUserByIdResponse)) {
            this.setState({
              userEnteredEmail: getUserByIdResponse?.data?.data?.email,
            });
          }
        });
    }
  }

  emailValidityCheck = () => {
    if (
      isEmailValid(this.state.userEnteredEmail) ||
      this.state.userEnteredEmail.trim() === ""
    ) {
      this.setState({ emailErr: false, errMsg: "" });
      this.setState({ emailBtnDisabled: false, signInBtnDisabled: false });
      this.setState({ signInBtnDisabled: false });
    } else {
      this.setState({ emailErr: true, errMsg: "Invalid email address" });
    }
  };

  passwordValidityCheck = () => {
    if (this.state.userEnteredPassword.trim() === "") {
      this.setState({ passwordErr: true, passwordMsg: "Password is required" });
    } else {
      this.setState({ passwordErr: false, passwordMsg: "" });
    }
  };

  handleUserPasswordChange = (password) => {
    this.setState({
      userEnteredPassword: password,
      passwordErr: false,
      passwordMsg: "",
    });
  };

  handleUserEmailChange = (email) => {
    this.setState({ userEnteredEmail: email }, () => {
      if (isEmailValid(this.state.userEnteredEmail)) {
        this.setState({ emailBtnDisabled: false });
        this.setState({ signInBtnDisabled: false });
      } else {
        this.setState({ emailBtnDisabled: true });
        this.setState({ signInBtnDisabled: true });
      }
    });
    if (email.length === 0) {
      this.setState({ userTeamsList: [], noTeamsData: false, errMsg: "" });
    }
  };

  // handleUserEmailChange = email => {
  //   this.setState({ userEnteredEmail: email });
  //   if (email.length === 0) {
  //     this.setState({ userTeamsList: [], noTeamsData: false, errMsg: '' });
  //   }
  // }

  selectedTeamData = (name) => {
    const { setCurrentUser } = this.props;
    const selectedTeam = this.state.userTeamsList.filter(
      (team) => team.teamName === name
    );

    this.setState(
      {
        userDataFromAPI: {
          ...this.state.userDataFromAPI,
          activeSpaceID: selectedTeam[0].spaceID,
          activeTeamName: selectedTeam[0].teamName,
        },
      },
      () => setCurrentUser(this.state.userDataFromAPI)
    );
  };

  handleTeamSelectionChange = (e) => {
    this.setState({ userSelectedTeam: e.target.value }, () => {
      this.selectedTeamData(this.state.userSelectedTeam);
      if (this.state.getStartedUser === "") {
        if (!isEmailValid(this.state.userEnteredEmail)) {
          this.setState({ signInBtnDisabled: true });
        } else {
          this.setState({ signInBtnDisabled: false });
        }
      } else {
        this.setState({ signInBtnDisabled: false });
      }
    });
  };

  getTeamsFromEmail = (emailVal) => {
    const userEmailID = {
      emailID: emailVal,
    };
    axios
      .post(signInCheck, userEmailID)
      .then((res) => {
        const userTeams = res?.data?.data;
        if (userTeams?.[0]?.teamName) {
          this.setState({
            userTeamsList: userTeams,
            userDataFromAPI: userTeams,
            noTeamsData: false,
          });
        } else {
          this.setState({
            noTeamsData: true,
            userTeamsList: [],
            userDataFromAPI: [],
          });
        }
        this.setState({ loading: true });
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  getUserTeamsFromEmail = () => {
    const { userEnteredEmail, errMsg, userSelectedTeam } = this.state;
    const userEmailID = {
      emailID: userEnteredEmail,
    };

    axios
      .post(signInCheck, userEmailID)
      .then((res) => {
        const userTeams = res?.data?.data;

        if (userTeams?.[0]?.teamName) {
          this.setState(
            {
              userTeamsList: userTeams,
              userDataFromAPI: userTeams,
              noTeamsData: false,
            },
            () => {
              if (userSelectedTeam !== "" && isEmailValid(userEnteredEmail)) {
                this.setState({ signInBtnDisabled: false });
              } else {
                this.setState({ signInBtnDisabled: true });
              }
            }
          );
        } else {
          this.setState({
            noTeamsData: true,
            userTeamsList: [],
            userDataFromAPI: [],
          });
        }
        this.setState({ loading: true });
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ errMsg: "Oops, something went wrong." }));
  };

  handleUserEmailKeyDown = (e) => {
    if (e.key === "Enter") {
      this.getUserTeamsFromEmail();
    }
  };

  setUser = async () => {
    const { userEnteredEmail, userEnteredPassword, getStartedUser } =
      this.state;
    const { userSignUpData } = this.props;

    const body = {
      username: userEnteredEmail || getStartedUser,
      password: userEnteredPassword,
    };

    try {
      const signInResponse = await axios.post("/auth/local/signin", body);
      const {
        location: { search },
        history = {},
      } = this.props;
      const urlParams = new URLSearchParams(search);
      const userInviteId = urlParams.get("actionid") || "";
      const userInviteTeamName = urlParams.get("teamname") || "";
      if (signInResponse?.data?.status === "Verified") {
        if (!userInviteId.length) {
          userSignUpData(signInResponse?.data);
          history.push(ORGANIZATION_PAGE);
        } else {
          const body = {
            emailInvited: this.state.userEnteredEmail,
            teamNameInvited: userInviteTeamName,
          };
          this.props.setOnboardingActionId("invite");
          this.props.setUserOnboardData({ teamName: userInviteTeamName });
          this.props.setInvitedTeamDetails(body);
          userSignUpData(signInResponse?.data);
          setTimeout(() => history.push(SETTING_UP_PAGE), REDIRECT_DELAY);
        }
      } else {
        this.setState({ unverifedEmail: true });
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        this.setState({ credentialsErr: true });
      } else {
        this.setState({
          formErr: true,
          formErrMsg: "Oops, something went wrong.",
        });
      }
    }
  };

  validateSignInForm = (e) => {
    e.preventDefault();

    const { userEnteredEmail, userEnteredPassword, getStartedUser } =
      this.state;

    if (
      !getStartedUser?.length &&
      userEnteredEmail.trim() === "" &&
      userEnteredPassword.trim() === ""
    ) {
      this.setState({
        emailErr: true,
        errMsg: "Email is required",
        passwordErr: true,
        passwordMsg: "Password is required",
      });
    } else if (userEnteredEmail.trim() === "" && !getStartedUser?.length) {
      this.setState({
        emailErr: true,
        errMsg: "Email is required",
      });
    } else if (userEnteredPassword.trim() === "") {
      this.setState({
        passwordErr: true,
        passwordMsg: "Password is required",
      });
    } else {
      this.setState({ formErr: false }, () => {
        this.setUser();
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  checkIsInvitedUser = () => {
    const {
      location: { search },
    } = this.props;
    const urlParams = new URLSearchParams(search);
    const inviteId = urlParams.get("actionid") || "";
    const inviteTeamName = urlParams.get("teamname") || "";
    const isInviteActionId = inviteId === "invite";
    const inviteDetails = {
      inviteId,
      inviteTeamName,
    };

    if (isInviteActionId) {
      window.localStorage.setItem(
        "inviteDetails",
        JSON.stringify(inviteDetails)
      );
    }

    window.location.href = GOOGLE_AUTH_PAGE;
  };

  closeShowVerificationEmailSentModal = () => {
    this.setState({ showVerificationEmailSentModal: false });
  };

  verifyEmail = () => {
    const { userEnteredEmail } = this.state;
    this.setState({ isVerifyEmailInProgress: true });
    axios
      .post(verifyEmail, { email: userEnteredEmail })
      .then((verifyEmailResponse) => {
        if (isOk(verifyEmailResponse)) {
          this.setState({ showVerificationEmailSentModal: true });
        }
      })
      .catch(() =>
        this.setState({ verifyEmailErrMsg: "Something went wrong!" })
      )
      .finally(() => this.setState({ isVerifyEmailInProgress: false }));
  };

  render() {
    const {
      userEnteredEmail,
      userTeamsList,
      errMsg,
      signInBtnDisabled,
      emailErr,
      passwordErr,
      passwordMsg,
      credentialsErr,
      formErr,
      formErrMsg,
      getStartedUser,
      showPassword,
      unverifedEmail,
      showVerificationEmailSentModal,
      isVerifyEmailInProgress,
      verifyEmailErrMsg,
      existingUserId,
    } = this.state;

    const modalDisplay = {
      display: showVerificationEmailSentModal ? "flex" : "none",
    };

    let teamsOptionsList = [];

    if (userTeamsList.length) {
      teamsOptionsList = userTeamsList.map((team) => {
        if ("teamName" in team) return team.teamName;
      });
    }

    return (
      <div className="user-sign-in full-page-height">
        <div className="screen-max-width">
          <div className="goto-tank">
            <span>GO BACK TO</span>
            <a className="logo-container" href={HOME_PAGE}>
              <Logo imageUrl={TANK_LOGO_BLUE} />
            </a>
          </div>
          <Grid container spacing={3} className="sign-in-wrapper">
            <Grid item md={6} lg={6} className="sign-in-intro sign-in-item">
              <div className="intro-text">
                <h2>Welcome back</h2>
                <p>
                  Sign in securely to catch up on your team’s latest updates
                </p>
              </div>
              <div className="sign-in-asset">
                <img
                  src={require("../../assets/images/sign-in.png")}
                  alt="sign in"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              className="sign-in-form sign-in-item"
            >
              <div>
                <h2>Sign In</h2>
                <form onSubmit={this.validateSignInForm}>
                  <TextField
                    id="sign-in-email"
                    label="Email address"
                    type="email"
                    autoComplete="current-email"
                    className="form-field email"
                    onChange={(e) => this.handleUserEmailChange(e.target.value)}
                    onBlur={this.emailValidityCheck}
                    value={
                      getStartedUser?.length ? getStartedUser : userEnteredEmail
                    }
                    error={emailErr}
                    helperText={errMsg}
                    disabled={getStartedUser?.length || existingUserId?.length}
                  />
                  <TextField
                    id="sign-in-password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    className="form-field password"
                    onChange={(e) =>
                      this.handleUserPasswordChange(e.target.value)
                    }
                    error={passwordErr}
                    helperText={passwordMsg}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon fontSize="small" />
                            ) : (
                              <VisibilityIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <NavLink
                    to={FORGOT_PASSWORD_PAGE}
                    className="forgot-password"
                  >
                    Forgot password?
                  </NavLink>
                  {credentialsErr ? (
                    <p className="err-msg form-err">
                      Invalid email address or password
                    </p>
                  ) : null}
                  {formErr ? (
                    <p className="err-msg form-err">
                      Oops, something went wrong
                    </p>
                  ) : null}
                  <div className="form-action-btns">
                    <Button className="primary-btn thin" type="submit">
                      Sign In
                    </Button>
                    <p className="or">
                      <span>OR</span>
                    </p>
                    <Button
                      onClick={this.checkIsInvitedUser}
                      // href={GOOGLE_AUTH_PAGE}
                      className="primary-btn thin google"
                    >
                      Sign In with Google
                    </Button>
                  </div>
                </form>
                {unverifedEmail && (
                  <p className="not-verified-msg error">
                    Email address is not verified. Please{" "}
                    <span
                      className="verify-link"
                      onClick={this.verifyEmail}
                      role="button"
                      tabIndex={0}
                      onKeyDown={this.verifyEmail}
                    >
                      verify
                    </span>{" "}
                    again.
                    {isVerifyEmailInProgress && (
                      <Spinner color="blue" size={15} />
                    )}
                  </p>
                )}
                {!!verifyEmailErrMsg.length && (
                  <p className="error">{verifyEmailErrMsg}</p>
                )}
              </div>
              <p className="entry-navigate join-free">
                Don’t have an account?{" "}
                <NavLink to={SIGNUP_PAGE}>Join free today</NavLink>
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="verification-modal">
          <Modal
            showCloseIcon={false}
            modalDisplay={modalDisplay}
            isDisabled={false}
            provisionType={"OK"}
            handleProvisioning={this.closeShowVerificationEmailSentModal}
          >
            <div className="tank-modal delete">
              <h3 className="confirm-delete-msg">
                {"Verification email sent. Please check your email."}
              </h3>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserSignInData: (data) => dispatch({ type: USER_SIGN_IN, payload: data }),
  setCurrentUser: (data) => dispatch({ type: CURRENT_USER, payload: data }),
  setCurrentUserData: (data) =>
    dispatch({ type: CURRENT_USER_DATA, payload: data }),
  userSignUpData: (data) => dispatch({ type: USER_SIGN_UP, payload: data }),
  setOnboardingActionId: (data) => dispatch({ type: ACTION_ID, payload: data }),
  setUserOnboardData: (data) =>
    dispatch({ type: SET_ONBOARD_USER_DATA, payload: data }),
  setInvitedTeamDetails: (data) =>
    dispatch({ type: TEAM_INVITE, payload: data }),
});

export default connect(null, mapDispatchToProps)(UserSignInPage);
