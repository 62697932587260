import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./LoaderWithBackdrop.scss";

const LoaderWithBackdrop = (props) => {
  const { show, label } = props;

  return (
    <Backdrop className="Loader__Backdrop" open={show}>
      <CircularProgress />
      <p className="fetching-data-label">{label}</p>
    </Backdrop>
  );
};

export default LoaderWithBackdrop;
