import React from 'react';

import './FeatureCard.scss';

const FeatureCard = props => (
  <div className="feature-card">
    {props.children}
  </div>
);

export default FeatureCard;
