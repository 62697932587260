import React, { useState, useEffect } from "react";

import Loader from "../../Loader/Loader";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";

import { useDebounce } from "../../../Hooks/useDebounce";

import {
  EDIT_COLLECTION,
  EDIT_COLLECTION_TITLE,
  EDIT_COLLECTION_DESCRIPTION,
  EDIT_COLOR,
} from "../../../constants/Collection";

import { CANCEL, SAVE } from "../../../constants/ButtonLabel";

import ColorPalette from "../../ColorPalette/ColorPalette";
import Modal from "../Modal/Modal";
import InputField from "../../InputField/InputField";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";

import "../CreateCollectionModal/CreateCollectionModal.scss";
import "./EditCollectionModal.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DEBOUNCE_DELAY = 300;

const EditCollectionModal = (props) => {
  const classes = useStyles();
  const {
    open,
    collecName,
    collecDesc,
    collecColor,
    collecGurus,
    handleEditCollection,
    loading,
    teamMembers,
    teamMemberNameKey,
    handleOptionSelection,
    selectedMembers,
  } = props;

  const [color, setColor] = useState("");
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDesc, setCollectionDesc] = useState("");
  const [gurus, setGurus] = useState([]);
  const [isValid, setIsValid] = useState(true);

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const debouncedCollectionTitle = useDebounce(collectionTitle, DEBOUNCE_DELAY);
  const debouncedCollectionDesc = useDebounce(collectionDesc, DEBOUNCE_DELAY);

  let isDataEntered = false;
  if (
    debouncedCollectionTitle.length > 0 &&
    debouncedCollectionDesc.length > 0 &&
    color !== "" &&
    selectedMembers.length > 0
  ) {
    isDataEntered = true;
  } else {
    isDataEntered = false;
  }

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  useEffect(() => {
    setCollectionTitle(collecName);
    setCollectionDesc(collecDesc);
    setColor(collecColor);
    setGurus(collecGurus);
  }, [collecName, collecDesc, collecColor, collecGurus]);

  useEffect(() => {
    if (isDataEntered) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [
    debouncedCollectionTitle,
    debouncedCollectionDesc,
    color,
    selectedMembers,
  ]);

  const handleCollectionNameChange = (name) => {
    setCollectionTitle(name);
  };

  const handleCollectionDescChange = (desc) => {
    setCollectionDesc(desc);
  };

  const collectionTitleMarkup = (
    <InputField
      labelText={EDIT_COLLECTION_TITLE}
      inputValue={collectionTitle}
      type={"text"}
      inputTag={"input"}
      handleOnChange={handleCollectionNameChange}
    />
  );

  const collectionDescMarkup = (
    <InputField
      labelText={EDIT_COLLECTION_DESCRIPTION}
      inputValue={collectionDesc}
      type={"text"}
      inputTag={"textarea"}
      handleOnChange={handleCollectionDescChange}
    />
  );

  const collectionColorSelectMarkup = (
    <>
      <InputField labelText={EDIT_COLOR} />
      <FormControl className={`${classes.formControl} dropdown-comp colors`}>
        <Select
          labelId="demo-simple-select-label-1"
          id="demo-simple-select-1"
          value={color}
        >
          <MenuItem className="color-dropdown">
            <ColorPalette colorChange={handleColorChange} colorValue={color} />
          </MenuItem>
        </Select>
        <div
          className="selected-color"
          style={{ backgroundColor: color }}
        ></div>
      </FormControl>
    </>
  );

  return (
    <div className="new-collection">
      <Modal
        modalTitle={EDIT_COLLECTION}
        modalDisplay={divStyle}
        close={props.close}
        cancelText={CANCEL}
        provisionType={SAVE}
        isDisabled={isValid}
        handleProvisioning={() =>
          handleEditCollection(
            debouncedCollectionTitle,
            debouncedCollectionDesc,
            color,
            selectedMembers
          )
        }
        isLoading={loading}
      >
        <div
          className={`tank-modal create-collection ${
            loading ? "creating-collec" : ""
          }`}
        >
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="collection-modal-data">
                  {collectionTitleMarkup}
                  {collectionDescMarkup}
                  <Grid container spacing={2} className="edit-color-gurus">
                    <Grid item xs={3}>
                      {collectionColorSelectMarkup}
                    </Grid>
                    <Grid item xs={9}>
                      <SearchDropdown
                        label={"Add Gurus"}
                        options={teamMembers}
                        teamMemberNameKey={teamMemberNameKey}
                        handleOptionSelection={handleOptionSelection}
                        selectedGurus={gurus}
                      />
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditCollectionModal;
