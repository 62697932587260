import React from 'react';

import './GreetingByTime.scss';

const GreetingByTime = props => {
  const { name } = props;

  let greetingMessage = null;

  const day = new Date();
  const hourOfDay = day.getHours();

  const renderGreeting = greeting => (
    <div className='greeting-block'>
      <p className='greeting-msg'>
        {`${greeting}`}
      </p>
    </div>
  );

  if (hourOfDay >= 0 && hourOfDay < 12) {
    return greetingMessage = renderGreeting('Good morning');
  } else if (hourOfDay >= 12 && hourOfDay <= 17) {
      return greetingMessage = renderGreeting('Good afternoon');
  } else {
      return greetingMessage = renderGreeting('Good evening');
  }
}

export default GreetingByTime;
