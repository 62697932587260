import React from 'react';

import { TANK_LOADER } from '../../constants';

import './Loader.scss';

const Loader = () => (
  <div className="tank-loader">
    <img src={TANK_LOADER} alt="loader" />
  </div>
);

export default Loader;
