import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { DRAWER_WIDTH } from "../../constants";

import { LOGOUT_PAGE } from "../../constants/PageRoutes";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UserAvatarIcon from "../UserAvatarIcon/UserAvatarIcon";

import Search from "../Search/Search";

import "./DashboardHeader.scss";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    color: "white",
  },
  flex: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const DashboardHeader = (props) => {
  const {
    userTeams,
    openDashboardDrawer,
    currentTeamData,
    handleSwitch,
    userPlan,
    showSearch,
  } = props;

  const expiryDateStr = userPlan?.trialExpiryDate;
  const expiryDate = new Date(expiryDateStr);

  const userDP = currentTeamData?.dp;
  const userEmail = currentTeamData?.email;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [daysDifference, setDaysDifference] = useState(null);

  const currentUserTeam = currentTeamData?.teamName;
  const currentSpaceID = currentTeamData?.spaceID;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStickyNav = () => {
    const headerEle = document.querySelector(".tank-header-nav");
    if (headerEle) {
      if (window.pageYOffset > 55) {
        headerEle.classList.add("sticky-nav");
      } else {
        headerEle.classList.remove("sticky-nav");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleStickyNav);

    return () => {
      document.removeEventListener("scroll", () => handleStickyNav);
    };
  }, []);

  useEffect(() => {
    // Calculate the difference in days
    const diffInMs = expiryDate - new Date();
    const daysDiff = Math.round(diffInMs / (1000 * 60 * 60 * 24));

    setDaysDifference(daysDiff);
  }, [expiryDate]);

  return (
    <div className={`${classes.root} tank-header dash-header`}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={`${classes.appBar} tank-header-nav dash ${
          userPlan?.trial ? "trial-active-banner" : ""
        }`}
      >
        {userPlan?.trial && daysDifference > 0 ? (
          <div className={`trial-banner`}>
            <span>
              {daysDifference} {daysDifference > 1 ? "days" : "day"} remaining
              on your trial period
            </span>
          </div>
        ) : null}
        <Toolbar
          className={`dash-toolbar ${!showSearch ? "trial-expired" : ""}`}
        >
          <div
            onClick={() => openDashboardDrawer(true)}
            className="mobile-nav-icon"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          {showSearch && <Search cuurentSpaceID={currentSpaceID} />}
          <div className="profile">
            <div className="user-team">
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                className="primary-btn"
              >
                {currentUserTeam}
              </Button>
              <StyledMenu
                id="customized-menu"
                className="teams-dropdown"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <span className="switch-teams">Switch teams</span>
                {userTeams?.length ? (
                  userTeams.map((team, idx) => {
                    if (team.teamName && team.teamName !== "")
                      return (
                        <StyledMenuItem
                          key={idx}
                          onClick={() => {
                            handleSwitch(team.spaceID);
                            handleClose();
                          }}
                          className={`${
                            currentUserTeam === team.teamName
                              ? "active-team"
                              : ""
                          }`}
                        >
                          {team.teamName}
                        </StyledMenuItem>
                      );
                  })
                ) : (
                  <StyledMenuItem>No teams</StyledMenuItem>
                )}
              </StyledMenu>
            </div>
            <div className="user-profile">
              <UserAvatarIcon userImage={userDP} size="large" />
            </div>
            <ListItem className="session-status nav-item">
              <NavLink to={LOGOUT_PAGE} className={classes.logo}>
                Logout
              </NavLink>
              <p>{userEmail}</p>
            </ListItem>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default DashboardHeader;
