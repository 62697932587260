import { ACTION_ID, USER_LOGOUT } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case ACTION_ID:
      return action.payload;
    case USER_LOGOUT:
      return state;
    default:
      return state;
  }
}
