import React, { useEffect, useState } from "react";
import axios from "axios";
import { isEmptyObject } from "../../../utils";
import { VisaIcon } from "../../../utils/card-icons";
import {
  stripeGetInvoices,
  getPaymentInfo,
  stripeCustomerDetails,
} from "../../../api/api";
import BillingPage from "../Billing/Billing";
import "./BillingDetailsPage.scss";

const BillingDetailsPage = (props) => {
  const {
    currentTeamData: { tankUserID, spaceID },
  } = props;
  const [billingDetails, setBillingDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [tankCustomerId, setTankCustomerId] = useState({});

  const getPaymentDetails = async () => {
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: tankCustomerId?.customerId,
      }),
    };

    try {
      const response = await fetch(
        `https://trytank.io${getPaymentInfo}`,
        payload
      );

      const respPaymentInfoData = await response.json();
      if (respPaymentInfoData?.data) {
        setPaymentDetails(respPaymentInfoData);
      }
    } catch (error) {
      console.error(`Download error: ${error?.message}`);
    }
  };

  const getInvoiceDetails = async () => {
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: tankCustomerId?.customerId,
      }),
    };

    try {
      const response = await fetch(
        `https://trytank.io${stripeGetInvoices}`,
        payload
      );
      const respInvoicesData = await response.json();
      console.log(response);
      if (respInvoicesData?.data) {
        setBillingDetails(respInvoicesData);
      }
    } catch (error) {
      console.error(`Download error: ${error?.message}`);
    }
  };

  const getCustomerId = async () => {
    const stripeTankUserId = tankUserID;
    const stripeSpaceID = spaceID;

    try {
      const customerIdData = await axios.get(
        `${stripeCustomerDetails}/${stripeTankUserId}/${stripeSpaceID}`
      );
      console.log("customerIdData --->>", customerIdData);
      setTankCustomerId(customerIdData?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (tankCustomerId?.customerId && isEmptyObject(billingDetails)) {
      getInvoiceDetails();
    }
  }, [billingDetails, tankCustomerId?.customerId]);

  useEffect(() => {
    if (tankCustomerId?.customerId && isEmptyObject(paymentDetails)) {
      getPaymentDetails();
    }
  }, [paymentDetails, tankCustomerId?.customerId]);

  useEffect(() => {
    if (isEmptyObject(tankCustomerId)) {
      getCustomerId();
    }
  }, [tankCustomerId]);

  const renderSubscriberForm = () => {
    return <BillingPage {...props} />;
  };

  const renderBillingDetails = () => (
    <div className="BillingDetailsPage page-top">
      <div className="billing-page-title-container">
        <h1 className="page-heading">Billing</h1>
        <p className="page-subheading">
          Manage your billing and payment details
        </p>
      </div>
      <div className="billing-plan-info-container">
        <div className="billing-plan-info-wrapper">
          <div className="billing-plan-info">
            <p className="plan-info">Pro Trial</p>
            <p className="plan-description">
              Includes all features of the Pro plan
            </p>
          </div>
          <div className="plan-amount-and-upgrade">
            <div className="plan-amount">
              <p className="amount">$0</p>
              <p className="trial-info">Trial plan expires in 7 days</p>
            </div>
            <button className="upgrade-btn btn-navigate">Upgrade Plan</button>
          </div>
        </div>
        <div className="billing-card-details">
          <div className="billing-card">
            <div className="card-img-wrapper">
              <p className="card-img">
                <VisaIcon />
              </p>
            </div>
            <div className="card-details">
              <p className="card-num">
                <span className="card-name">
                  {paymentDetails?.data?.[0]?.card?.brand}
                </span>{" "}
                ending in{" "}
                <span className="card-ending-num">
                  {paymentDetails?.data?.[0]?.card?.last4}
                </span>
              </p>
              <p className="card-exp">
                {paymentDetails?.data?.[0]?.card?.exp_month}/
                {paymentDetails?.data?.[0]?.card?.exp_year}
              </p>
              <p className="card-email">
                {paymentDetails?.data?.[0]?.billing_details?.email}
              </p>
            </div>
          </div>
          <div className="edit-card-container">
            <button className="edit-card-btn">Edit</button>
          </div>
        </div>
      </div>
    </div>
  );

  const checkSubscriberStatus = () => {
    if (tankCustomerId?.customerId) return renderBillingDetails();
    return renderSubscriberForm();
  };

  return <>{checkSubscriberStatus()}</>;
};

export default BillingDetailsPage;
