import React, { Component } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";

import { v4 as uuidv4 } from "uuid";

import {
  getAllNotifications,
  getActiveTasks,
  verifyAnswer,
  getAllUsersFromSpace,
  getStartedQues,
  updateMentionTask,
} from "../../api/api";

import { formatDateFromNow, mostRecentEntriesFirst, isOk } from "../../utils";
import {
  QUESTION_DETAIL_PAGE,
  GET_STARTED_PAGE,
} from "../../constants/PageRoutes";
import { ROLE_ADMIN } from "../../constants/UserRoles";
import { LOGOUT_PAGE } from "../../constants/PageRoutes";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Widget from "./Widget/Widget";
import GreetingByTime from "../../components/GreetingByTime/GreetingByTime";
import Loader from "../../components/Loader/Loader";
import RejectAnswerModal from "../../components/Modals/RejectAnswerModal/RejectAnswerModal";
import PreviewAnswerModal from "../../components/Modals/PreviewAnswerModal/PreviewAnswerModal";
import ViewQuestionModal from "../../components/Modals/ViewQuestionModal/ViewQuestionModal";
import DataError from "../../components/DataError/DataError";
import Modal from "../../components/Modals/Modal/Modal";

import "./NotificationsPage.scss";

class NotificationsPage extends Component {
  state = {
    notifications: [],
    notificationLoading: true,
    tasks: [],
    tasksLoading: true,
    openRejectModal: false,
    rejectAnswerContent: "",
    rejectAnswerModalBtnDisabled: true,
    activeRejectModalAnswerID: "",
    activeRejectModalQuesID: "",
    activeRejectModalAnswerBy: "",
    activeRejectModalSpaceID: "",
    activeRejectModalTankUserID: "",
    openPreviewModal: false,
    activePreviewAnswerDesc: "",
    activePreviewAnswerCreatedAt: "",
    activePreviewAnswerCreatedBy: "",
    activePreviewAnswerQuesID: "",
    spaceUsers: [],
    spaceUsersLoading: true,
    getStartedQueries: [],
    getStartedQuesLoading: true,
    openViewQuestionModal: false,
    activeViewQuestionID: "",
    disableGetStartedWidget: false,
    myTasksError: false,
    notificationsError: false,
    usersFromSpaceError: false,
    retryLoginError: false,
  };

  componentDidMount() {
    const { userClientID } = this.props;

    this.fetchNotifications();
    this.fetchActiveTasks();

    axios
      .get(`${getAllUsersFromSpace}${userClientID}`)
      .then((res) => {
        if (isOk(res)) {
          this.setState({ spaceUsers: res?.data?.data });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          this.setState({ retryLoginError: true });
        }
        this.setState({ usersFromSpaceError: true });
      })
      .finally(() => this.setState({ spaceUsersLoading: false }));

    if (!this.state.disableGetStartedWidget) {
      axios
        .get(getStartedQues)
        .then((res) => res)
        .then((data) => {
          if (data?.data?.data) {
            this.setState({ getStartedQueries: data?.data?.data }, () =>
              this.setState({ getStartedQuesLoading: false })
            );
          }
        });
    }
  }

  fetchNotifications = async () => {
    const { userTankID, userClientID } = this.props;
    const body = {
      spaceID: userClientID,
      tankUserID: userTankID,
    };
    await axios
      .post(getAllNotifications, body)
      .then((res) => {
        if (isOk(res)) {
          this.setState({ notifications: res?.data?.data }, () => {
            this.state.notifications &&
              this.state.notifications.length &&
              mostRecentEntriesFirst(this.state.notifications);
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          this.setState({ retryLoginError: true });
        }
        this.setState({ notificationsError: true });
      })
      .finally(() => this.setState({ notificationLoading: false }));
  };

  fetchActiveTasks = async () => {
    const { userTankID, userClientID } = this.props;
    const body = {
      spaceID: userClientID,
      tankUserID: userTankID,
    };

    await axios
      .post(getActiveTasks, body)
      .then((res) => {
        if (isOk(res)) {
          this.setState({ tasks: res?.data?.data }, () => {
            this.state.tasks &&
              this.state.tasks.length &&
              mostRecentEntriesFirst(this.state.tasks);
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          this.setState({ retryLoginError: true });
        }
        this.setState({ myTasksError: true });
      })
      .finally(() => this.setState({ tasksLoading: false }));
  };

  onChangeRejectAnswerContent = (val) => {
    this.setState({ rejectAnswerContent: val }, () => {
      if (this.state.rejectAnswerContent !== "") {
        this.setState({ rejectAnswerModalBtnDisabled: false });
      } else {
        this.setState({ rejectAnswerModalBtnDisabled: true });
      }
    });
  };

  renderRejectModal = (ansId, quesID, answeredBy, spaceID, tankUserID) => {
    this.setState({
      openRejectModal: true,
      activeRejectModalAnswerID: ansId,
      activeRejectModalQuesID: quesID,
      activeRejectModalAnswerBy: answeredBy,
      activeRejectModalSpaceID: spaceID,
      activeRejectModalTankUserID: tankUserID,
    });
  };

  closeRejectModal = () => {
    this.setState({
      openRejectModal: false,
      rejectAnswerContent: "",
      activeRejectModalAnswerID: "",
      activeRejectModalQuesID: "",
      activeRejectModalAnswerBy: "",
      activeRejectModalSpaceID: "",
      activeRejectModalTankUserID: "",
    });
  };

  renderPreviewModal = (answerDesp, createdTime, answeredBy, quesId) => {
    this.setState({
      openPreviewModal: true,
      activePreviewAnswerDesc: answerDesp,
      activePreviewAnswerCreatedAt: createdTime,
      activePreviewAnswerCreatedBy: answeredBy,
      activePreviewAnswerQuesID: quesId,
    });
  };

  closePreviewModal = () => {
    this.setState({
      openPreviewModal: false,
      activePreviewAnswerDesc: "",
      activePreviewAnswerCreatedAt: "",
      activePreviewAnswerCreatedBy: "",
      activePreviewAnswerQuesID: "",
    });
  };

  renderViewQuestionModal = (quesId) => {
    this.setState({
      openViewQuestionModal: true,
      activeViewQuestionID: quesId,
    });
  };

  closeViewQuestionModal = () => {
    this.setState({ openViewQuestionModal: false, activeViewQuestionID: "" });
  };

  createMarkup = (html) => {
    return { __html: html };
  };

  notificationItem = (
    notification,
    notificationTitle,
    notificationIcon,
    notificationOrigin
  ) => (
    <>
      <span className="notification-icon">{notificationIcon}</span>
      <div className="notify-content">
        <p
          className={`timestamp ${
            notification?.notificationContent?.origin?.toLowerCase() == "slack"
              ? "notification-origin-slack"
              : ""
          }`}
        >
          {formatDateFromNow(notification.createdAt)} via{" "}
          <span className="notification-origin"> {notificationOrigin} </span>
        </p>
        <p className="title">{notificationTitle} </p>
        <p
          className="mention-user"
          dangerouslySetInnerHTML={this.createMarkup(
            notification.notificationContent.message
          )}
        />
      </div>
    </>
  );

  notificationTitle = (notification) => {
    let notificationTitle = "";
    let notificationIcon = "";
    let notificationOrigin = "";
    if (
      notification?.notificationContent?.origin == "slack" ||
      notification?.notificationContent?.origin == "SLACK"
    ) {
      notificationOrigin = (
        <img
          src={require("../../assets/images/slack-logo.png")}
          alt="slackOrigin"
        />
      );
    } else {
      notificationOrigin = (
        <img
          src={require("../../assets/images/t-logo.png")}
          alt="slackOrigin"
        />
      );
    }

    if (notification.type === "VERIFICATION") {
      notificationTitle = "New verification request";
      notificationIcon = (
        <img
          src={require("../../assets/images/verified_final.png")}
          alt="verified"
        />
      );
    } else if (notification.type === "MENTION") {
      notificationTitle = "New mention";
      notificationIcon = (
        <img
          src={require("../../assets/images/mention_final.png")}
          alt="mention"
        />
      );
    } else if (notification.type === "QUESTION") {
      notificationTitle = "New query posted";
      notificationIcon = (
        <img
          src={require("../../assets/images/ques_final.png")}
          alt="question"
        />
      );
    } else if (notification.type === "ANSWER") {
      notificationTitle = "New response posted";
      notificationIcon = (
        <img
          src={require("../../assets/images/answer_final.png")}
          alt="answer"
        />
      );
    }
    return this.notificationItem(
      notification,
      notificationTitle,
      notificationIcon,
      notificationOrigin
    );
  };

  notificationRedirectLink = (notification, idx) => {
    const { quesID, ansID } = notification;

    if (quesID !== "" && ansID === "")
      return (
        <Link key={idx} to={`${QUESTION_DETAIL_PAGE}/${quesID}`}>
          <div
            className={`notify-block ${
              notification?.type === "VERIFICATION" ? "verify-task" : ""
            }`}
          >
            {this.notificationTitle(notification)}
          </div>
        </Link>
      );

    return (
      <div key={idx}>
        <div className="notify-block">
          {this.notificationTitle(notification)}
        </div>
      </div>
    );
  };

  noCatchUpItemsAvailable() {
    const { notifications } = this.state;
    if (!notifications.length) {
      return (
        <div className="no-catchup-items">
          <div className="no-catchup-asset">
            <img
              src={require("../../assets/images/no-notifications.png")}
              alt="no notifications"
            />
          </div>
          <p>No updates at this moment. Please check back later.</p>
        </div>
      );
    } else return null;
  }

  renderNotifications() {
    const { notifications, notificationsError } = this.state;

    if (notificationsError) return <DataError isPositionAbsolute={true} />;

    if (notifications?.length) {
      return (
        <div className="notify">
          {notifications.map((notification, idx) =>
            this.notificationRedirectLink(notification, idx)
          )}
        </div>
      );
    }
    return this.noCatchUpItemsAvailable();
  }

  submitRejectResponse = async () => {
    const {
      activeRejectModalAnswerID,
      activeRejectModalQuesID,
      activeRejectModalAnswerBy,
      activeRejectModalSpaceID,
      rejectAnswerContent,
      activeRejectModalTankUserID,
    } = this.state;
    const body = {
      quesID: activeRejectModalQuesID.quesId,
      answerId: activeRejectModalAnswerID,
      answeredBy: activeRejectModalAnswerBy,
      spaceID: activeRejectModalSpaceID,
      reason: rejectAnswerContent,
      verifiedState: false,
      tankUserID: activeRejectModalTankUserID,
    };

    try {
      await axios.post(verifyAnswer, body);

      this.closeRejectModal();
      this.fetchActiveTasks();
      this.fetchNotifications();
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  onVerifyAnswer = async (
    quesId,
    answerId,
    answeredBy,
    spaceID,
    tankUserID
  ) => {
    const body = {
      quesID: quesId.quesId,
      answerId: answerId,
      answeredBy: answeredBy,
      spaceID: spaceID,
      verifiedState: true,
      tankUserID: tankUserID,
    };

    await axios.post(verifyAnswer, body);

    this.fetchActiveTasks();
    this.fetchNotifications();
  };

  updateMentionTypeTask = (questionId) => {
    const {
      currentTeamData: { spaceID, tankUserID },
    } = this.props;

    axios
      .patch(`${updateMentionTask}/${spaceID}/${tankUserID}/${questionId}`)
      .then(() => this.fetchActiveTasks());
  };

  renderTaskTypes = (task) => {
    if (task.type === "VERIFICATION") {
      const {
        answer: { answerId, answeredBy, quesId, answerDesp, createdTime },
        spaceID,
        tankUserID,
      } = task.metadata;

      return (
        <>
          <span className="task-type validate"></span>
          <div>
            <p className="task-title mention-user">
              <span>{task.metadata.creator}</span> wants you to validate an
              answer.
            </p>
            <p className="timestamp">{formatDateFromNow(createdTime)} </p>
          </div>
          <div>
            <Button
              onClick={() =>
                this.renderPreviewModal(
                  answerDesp,
                  createdTime,
                  answeredBy,
                  quesId
                )
              }
              className="primary-btn mini preview"
            >
              Preview
            </Button>
            <Button
              onClick={() =>
                this.onVerifyAnswer(
                  quesId,
                  answerId,
                  answeredBy,
                  spaceID,
                  tankUserID
                )
              }
              className="primary-btn mini green outline"
            >
              Verify
            </Button>
            <Button
              onClick={() =>
                this.renderRejectModal(
                  answerId,
                  quesId,
                  answeredBy,
                  spaceID,
                  tankUserID
                )
              }
              className="primary-btn mini red outline"
            >
              Reject
            </Button>
          </div>
        </>
      );
    }

    if (task.type === "MENTION") {
      const {
        metadata: { quesId, creator },
        createdAt,
      } = task;

      return (
        <>
          <span className="task-type mention"></span>
          <div>
            <p className="task-title mention-user">
              <span>{creator}</span>&nbsp;mentioned you in a question.
            </p>
            <p className="timestamp">{formatDateFromNow(createdAt)}</p>
          </div>
          <div className="task-btn-wrapper">
            <Button
              onClick={() => this.renderViewQuestionModal(quesId)}
              className="primary-btn mini"
            >
              View question
            </Button>
            <Button
              onClick={() => this.updateMentionTypeTask(quesId)}
              className="primary-btn mini green"
            >
              Done
            </Button>
          </div>
        </>
      );
    }
  };

  disableWidget = () => {
    this.setState({ disableGetStartedWidget: true });
  };

  renderMyTasks = (myTasksData) => {
    const { myTasksError } = this.state;

    if (myTasksError) return <DataError isPositionAbsolute={true} />;

    if (myTasksData?.length)
      return myTasksData.map((task) => (
        <div key={uuidv4()} className="widget-entry task-item">
          {this.renderTaskTypes(task)}
        </div>
      ));

    return (
      <div className="no-tasks-container">
        <div className="no-tasks-asset">
          <img
            src={require("../../assets/images/no-tasks.png")}
            alt="no tasks"
          />
        </div>
      </div>
    );
  };

  renderUsersFromSpace = (spaceUsersData) => {
    const { usersFromSpaceError } = this.state;

    if (usersFromSpaceError) return <DataError isPositionAbsolute={true} />;

    if (spaceUsersData?.length)
      return spaceUsersData.map((user) => (
        <div key={uuidv4()} className="widget-entry">
          <span className="task-type space-user"></span>
          <div className="name-container">
            <div className="member-name-info">
              <p className="name">
                {user.name} {user?.lastName}
                {user?.role === ROLE_ADMIN ? (
                  <SupervisorAccountOutlinedIcon
                    className="admin-user-indicator"
                    fontSize="small"
                  />
                ) : null}
                {user?.onboardedVia === "SLACK" ? (
                  <div className="member-origin">
                    <img
                      src={require("../../assets/images/slack-logo.png")}
                      alt="origin slack"
                    />
                  </div>
                ) : null}
              </p>
              <span className="member-email">{user?.email}</span>
            </div>
          </div>
          <div className="space-user-dp">
            <img
              src={
                user?.dp
                  ? user?.dp
                  : require("../../assets/images/default-avatar.png")
              }
              alt="user image"
            />
          </div>
        </div>
      ));
  };

  redirectToLoginPage = () => {
    window.location.href = LOGOUT_PAGE;
  };

  render() {
    const {
      notifications,
      notificationLoading,
      tasks,
      tasksLoading,
      openRejectModal,
      rejectAnswerContent,
      rejectAnswerModalBtnDisabled,
      openPreviewModal,
      activePreviewAnswerDesc,
      activePreviewAnswerCreatedAt,
      activePreviewAnswerCreatedBy,
      activePreviewAnswerQuesID,
      spaceUsers,
      spaceUsersLoading,
      getStartedQueries,
      getStartedQuesLoading,
      openViewQuestionModal,
      activeViewQuestionID,
      disableGetStartedWidget,
      retryLoginError,
    } = this.state;

    const modalDisplay = {
      display: "flex",
    };

    return (
      <>
        <div className="section-head">
          <GreetingByTime name={this.props.name} />
        </div>
        <div className="notifications">
          <Grid className="widgets-container" container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              {!disableGetStartedWidget ? (
                <div className="get-started-widget widget-item">
                  <Widget
                    title={"Get Started"}
                    icon={"launch.png"}
                    disableWidgetIcon={true}
                    disableWidget={this.disableWidget}
                  >
                    {getStartedQuesLoading ? (
                      <Loader />
                    ) : getStartedQueries.length ? (
                      getStartedQueries.map((getStarted) => (
                        <p key={getStarted.quesId} className="entry">
                          <Link to={`${GET_STARTED_PAGE}/${getStarted.quesId}`}>
                            <span>{getStarted.quesTitle}</span>
                          </Link>
                        </p>
                      ))
                    ) : (
                      <div className="no-tasks-container">
                        <p>No data available</p>
                      </div>
                    )}
                  </Widget>
                </div>
              ) : null}
              <div className="tasks-widget widget-item">
                <Widget
                  title={`My Tasks ${
                    !tasksLoading ? `(${tasks?.length})` : ""
                  }`}
                  icon={"task.png"}
                  tasks={tasks}
                >
                  {tasksLoading ? <Loader /> : this.renderMyTasks(tasks)}
                </Widget>
              </div>
              <div className="space-users-widget widget-item">
                <Widget
                  title={`Team Members ${
                    !spaceUsersLoading ? `(${spaceUsers?.length})` : ""
                  }`}
                  icon={"team-work.png"}
                >
                  {spaceUsersLoading ? (
                    <Loader />
                  ) : (
                    this.renderUsersFromSpace(spaceUsers)
                  )}
                </Widget>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div
                className={`notify-container ${
                  notifications && !notifications.length ? "no-items" : ""
                }`}
              >
                {notificationLoading ? (
                  <Loader />
                ) : (
                  <>{this.renderNotifications()}</>
                )}
              </div>
            </Grid>
          </Grid>
          {openRejectModal ? (
            <RejectAnswerModal
              open={openRejectModal}
              close={this.closeRejectModal}
              inputValue={rejectAnswerContent}
              handleOnChange={this.onChangeRejectAnswerContent}
              rejectAnswerModalBtnDisabled={rejectAnswerModalBtnDisabled}
              submitRejectResponse={this.submitRejectResponse}
            />
          ) : null}
          {openPreviewModal ? (
            <PreviewAnswerModal
              open={openPreviewModal}
              close={this.closePreviewModal}
              previewAnswerDesc={activePreviewAnswerDesc}
              previewAnswerCreatedAt={activePreviewAnswerCreatedAt}
              previewAnswerCreatedBy={activePreviewAnswerCreatedBy}
              previewAnswerQuesID={activePreviewAnswerQuesID}
            />
          ) : null}
          {openViewQuestionModal ? (
            <ViewQuestionModal
              open={openViewQuestionModal}
              close={this.closeViewQuestionModal}
              activeViewQuestionID={activeViewQuestionID}
            />
          ) : null}
        </div>
        {retryLoginError ? (
          <div className="retry-login-modal">
            <Modal
              close={this.redirectToLoginPage}
              modalDisplay={modalDisplay}
              isDisabled={false}
              provisionType={"ok"}
              handleProvisioning={this.redirectToLoginPage}
            >
              <div className="tank-modal delete">
                <p className="confirm-delete-msg">
                  {"Something went wrong. Please log in again."}
                </p>
              </div>
            </Modal>
          </div>
        ) : null}
      </>
    );
  }
}

export default NotificationsPage;
