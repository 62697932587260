import moment from "moment";
import axios from "axios";

import { saveAsDraftQuestion, saveAsDraftAnswer } from "../api/api";

import { ROLE_MEMBER } from "../constants/UserRoles";

export const PLAN_TYPES = {
  NO_PLAN: "NOPLAN",
  TRIAL_PLAN: "TRIAL",
  BASIC_PLAN: "BASIC",
  PRO_PLAN: "PRO",
};

export const COLLECTION_NAME_CHAR_LIMIT = 100;
export const QUERY_CHAR_LIMIT = 150;
export const TEAM_NAME_CHAR_LIMIT = 50;

export function formatDateDMY(date) {
  return moment(date, ["YYYY-MM-DD", "MM-DD-YYYY"]).format("Do MMM 'YY");
}

export function formatDateFromNow(date) {
  return moment(date).fromNow();
}

export function getCurrentTime() {
  return moment().format().split("+")[0];
}

export function getCurrentMonth() {
  const currMonth = moment().month() + 1;

  if (currMonth?.toString()?.length === 1) return `0${moment().month() + 1}`;

  return moment().month() + 1;
}

export function isOk(response) {
  return response?.status === 200;
}

export function saveQuestionAsDraft(
  queTitle,
  quesContent,
  colId,
  userTankID,
  creatorID,
  userClientID,
  selectedMembers = []
) {
  const createQuestionSaveDraft = {
    tankUserID: userTankID,
    creatorID: creatorID,
    spaceID: userClientID,
    quesTitle: queTitle,
    quesDesp: quesContent,
    collectionId: colId,
    mentions: selectedMembers || [],
  };
  axios.post(saveAsDraftQuestion, createQuestionSaveDraft);
}

export function saveAnswerAsDraft(
  answerDesc,
  userTankID,
  userClientID,
  creatorID,
  questionId,
  quesTitle
) {
  const createAnswerSaveDraft = {
    tankUserID: userTankID,
    creatorID: creatorID,
    quesId: questionId,
    spaceID: userClientID,
    ansDesp: answerDesc,
    quesTitle: quesTitle,
  };
  axios.post(saveAsDraftAnswer, createAnswerSaveDraft);
}

export function hasSuperUserPermission(userRole) {
  return userRole !== ROLE_MEMBER;
}

export function isEmailValid(email) {
  return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
}

export function mostRecentEntriesFirst(data) {
  return data.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
}

export function isOnlyLetters(input) {
  return /^[A-Za-z\s]*$/.test(input);
}

export const isLettersNumbersAndSpecialChars = (input) =>
  /^[a-zA-Z0-9&._-]+$/.test(input);

export function isEmpty(input) {
  return input.trim() === "";
}

export const isEmptyObject = (obj) => {
  if (obj == null || typeof obj === "string") {
    return true;
  }

  if (obj.keys !== "undefined") {
    return Object.keys(obj).length === 0;
  }

  return true;
};

export const isCurrentUserCreated = (creatorId, tankUserId) => {
  return creatorId === tankUserId;
};

export const hasAdminModificationPriviledge = (
  userRole,
  creatorId,
  tankUserId
) => {
  return (
    hasSuperUserPermission(userRole) &&
    !isCurrentUserCreated(creatorId, tankUserId)
  );
};

export const isTrialPeriodActive = (plan) =>
  plan?.name === PLAN_TYPES.TRIAL_PLAN && plan?.trial;

export const isSubscriptionPlanActive = (plan) =>
  plan?.name === (PLAN_TYPES.BASIC_PLAN || PLAN_TYPES.PRO_PLAN);

export const isSubscriptionEnded = (plan) =>
  plan?.name === "END_OF_PLAN" && !plan?.trial;
