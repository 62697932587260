import React from 'react';
import { Link } from 'react-router-dom';

import Modal from '../Modal/Modal';
import Grid from "@material-ui/core/Grid";

import { formatDateFromNow } from '../../../utils';
import {QUESTION_DETAIL_PAGE} from '../../../constants/PageRoutes';

import './PreviewAnswerModal.scss';

const PreviewAnswerModal = props => {
  const { open, close, previewAnswerDesc, previewAnswerCreatedAt, previewAnswerCreatedBy, previewAnswerQuesID } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const createMarkup = (html) => {
    return { __html: html };
  }

  return (
    <div className="preview-answer-modal">
      <Modal
        close={close}
        modalDisplay={divStyle}
        isLoading={false}
      >
        <Grid container className="preview-content-wrapper">
          <Grid item xs={9} className="preview-content">
            <p dangerouslySetInnerHTML={createMarkup(previewAnswerDesc)} />
          </Grid>
          <Grid item xs={3} className="preview-content-details">
            <div className="preview-content-entry">
              <p className="content-label">Created:</p>
              <p className="timestamp">{formatDateFromNow(previewAnswerCreatedAt)}</p>
            </div>
            <div className="preview-content-entry">
              <p className="content-label">Created by:</p>
              <p className="timestamp">{previewAnswerCreatedBy}</p>
            </div>
            <div className="preview-content-entry">
              <Link to={`${QUESTION_DETAIL_PAGE}/${previewAnswerQuesID.quesId}`}>Go to query</Link>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </div>
  )
}

export default PreviewAnswerModal;
