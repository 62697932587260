import React from "react";
import { Redirect, Route } from "react-router-dom";
import {isEmptyObject} from '../../utils';

import {SIGN_IN_PAGE, DASHBOARD_PAGE} from '../../constants/PageRoutes';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isLoggedIn = restOfProps.loggedIn || !isEmptyObject(restOfProps?.signedUpUser) || !isEmptyObject(restOfProps?.currentUser);

  // if (window.location.pathname === DASHBOARD_PAGE) {
  //   localStorage.setItem('redirectTo', window.location.pathname);
  // }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isLoggedIn ? <Component {...props} {...restOfProps} /> : <Redirect to={`${SIGN_IN_PAGE}?pr`} />
      }
    />
  );
}

export default ProtectedRoute;
