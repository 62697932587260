import { useState, useEffect } from 'react';
import axios from "axios";

export const useGetCollections = url => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchCollections(url) {
    const res = await axios.get(url);
    setCollections(res.data);
  }

  useEffect(() => {
    setLoading(true);
    fetchCollections(url);
    setLoading(false);
  }, [url]);

  return [collections];
}
