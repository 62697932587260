import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  getMyDrafts,
  getCollectionDetailsByID,
  createNewQuestion,
  addAnswer,
  deleteQuestionDraft,
  deleteAnswerDraft,
  updateAnswerDraft,
  updateQuestionDraft
} from '../../api/api';

import { isOk } from '../../utils';

import { MY_QUERIES_DRAFT, MY_RESPONSES_DRAFT, CONFIRM_DRAFT_DELETE } from '../../constants/Draft';
import { CANCEL, CONFIRM } from '../../constants/ButtonLabel';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Question from '../../components/Question/Question';
import Loader from '../../components/Loader/Loader';
import AnswerItem from '../../components/AnswerItem/AnswerItem';

import EditQuestionModal from "../../components/Modals/EditQuestionModal/EditQuestionModal";
import EditAnswerModal from '../../components/Modals/EditAnswerModal/EditAnswerModal';
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal';

import './MyDraftsPage.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const MyDraftsPage = props => {
  const classes = useStyles();
  const { userTankID, userClientID, creatorID, currentTeamData } = props;
  const [myAnswerDrafts, setMyAnswerDrafts] = useState([]);
  const [myQuestionDrafts, setMyQuestionDrafts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [collectionTitle, setCollectionTitle] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);

  const [questionId, setQuestionId] = useState('');
  const [answerFromResponse, setAnswerFromResponse] = useState('');
  const [draftID, setDraftID] = useState('');
  const [deleteActionType, setDeleteActionType] = useState('');

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function fetchMyDrafts() {
    const getDraftsData = {
      tankUserID: userTankID,
      spaceID: userClientID
    }
    const draftsData = await axios.post(getMyDrafts, getDraftsData);
    if (draftsData?.data?.data?.AnsDrafts) {
      setMyAnswerDrafts(draftsData?.data?.data?.AnsDrafts);
    }
    if (draftsData?.data?.data?.quesDrafts) {
      setMyQuestionDrafts(draftsData?.data?.data?.quesDrafts);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchMyDrafts();
  }, []);

  useEffect(() => {
    if (editModalOpen === false) {
      setQuestionId('');
      setAnswerFromResponse('');
    }
  }, [editModalOpen]);

  const renderEditDraftQuesModal = (idx, quesDraftId) => {
    const draftQuestion = myQuestionDrafts[idx];
    setDraftID(quesDraftId);
    setSelectedQuestion(draftQuestion);
    if (draftQuestion.collectionId !== '') {
      axios.get(`${getCollectionDetailsByID}${draftQuestion.collectionId}`)
        .then(res => setCollectionTitle(res?.data?.[0]?.collectionName));
    }
    setOpenEditModal(true);
  }

  const closeModal = () => {
    setOpenEditModal(false);
    setCollectionTitle('');
    setSelectedQuestion({});
  }

  console.log(selectedQuestion);

  const handleNewQuestion = (queTitle, queContent, _) => {
    const createQuestionData = {
      tankUserID: userTankID,
      username: currentTeamData?.name,
      collectionId: selectedQuestion?.collectionId,
      quesTitle: queTitle?.length ? queTitle : selectedQuestion?.quesTitle,
      quesDesp: queContent?.length ? queContent : selectedQuestion?.quesDesp,
      answers: [],
      spaceID: userClientID,
      mentions: selectedQuestion?.mentions || []
    };

    axios.post(`${createNewQuestion}`, createQuestionData).then(res => {
      if (isOk(res)) {
        axios.delete(`${deleteQuestionDraft}${draftID}`)
        .then(res => {
          if (isOk(res)) {
            fetchMyDrafts();
          }
        });
      }
    });
    closeModal();
  };

  const handleQuestionSaveDraft = (questionTitle, editorContent, collecId) => {
    const body = {
      quesTitle: questionTitle,
      quesDesp: editorContent,
      collectionId: collecId
    }
    axios.patch(`${updateQuestionDraft}${draftID}`, body)
      .then(() => fetchMyDrafts());

    closeModal();
  }

  const handleAnswerSaveDraft = ansContentUpdate => {
    const body = {
      ansDesp: ansContentUpdate
    }
    axios.patch(`${updateAnswerDraft}${draftID}`, body)
      .then(() => fetchMyDrafts());

    closeEditAnswerModal();
  }

  const toggleEditAnswerDraftModal = (quesId, ansDesc, answerDraftId) => {
    setEditModalOpen(prevState => !prevState);
    setQuestionId(quesId);
    setAnswerFromResponse(ansDesc);
    setDraftID(answerDraftId);
  }

  const closeEditAnswerModal = () => {
    setEditModalOpen(prevState => !prevState);
  }

  const deleteDraft = (draftId, actionType) => {
    if (actionType === 'answer') {
      axios.delete(`${deleteAnswerDraft}${draftId}`).then((res) => {
        if (isOk(res)) {
          fetchMyDrafts();
        }
      });
    } else {
      axios.delete(`${deleteQuestionDraft}${draftId}`)
        .then(res => {
          if (isOk(res)) {
            fetchMyDrafts();
          }
        });
    }
    closeDeleteModal();
  }

  const handleAnswerAdd = answerToSave => {
    const addAnswerObj = {
      answerDesp: answerToSave?.length ? answerToSave : answerFromResponse,
      answeredBy: currentTeamData?.name,
      tankUserID: userTankID
    };

    axios.patch(`${addAnswer}${questionId}`, addAnswerObj).then(res => {
      if (isOk(res)) {
        axios.delete(`${deleteAnswerDraft}${draftID}`).then((res) => {
          if (isOk(res)) {
            fetchMyDrafts();
          }
        });
      }
    });
    closeEditAnswerModal();
  };

  const closeDeleteModal = () => {
    setToggleDeleteModal(prevState => !prevState);
  }

  const openDeleteModal = (draftId = '', actionType) => {
    setDeleteActionType(actionType);
    setDraftID(draftId);
    setToggleDeleteModal(prevState => !prevState);
  }

  const confirmDelete = () => (
    <DeleteModal
      confirmDeleteMsg={CONFIRM_DRAFT_DELETE}
      cancelText={CANCEL}
      confirmText={CONFIRM}
      onDelete={() => deleteDraft(draftID, deleteActionType)}
      close={openDeleteModal}
      open={toggleDeleteModal}
    />
  );

  const renderContent = () => {
    const hasQuestionsDrafts = myQuestionDrafts?.length;
    const hasAnswersDrafts = myAnswerDrafts?.length;
    const noDraftsAdded = !hasQuestionsDrafts && !hasAnswersDrafts;

    return (
      <>
        <div className={`${classes.root} tabs`}>
          {noDraftsAdded ? (
            <h2 className="no-data">No drafts added</h2>
          ) : (
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {hasQuestionsDrafts ? (
                <Tab label={MY_QUERIES_DRAFT} {...a11yProps(0)} />
              ) : null}
              {hasAnswersDrafts ? (
                <Tab label={MY_RESPONSES_DRAFT} {...a11yProps(1)} />
              ) : null}
            </Tabs>
          )}
        </div>
        {hasQuestionsDrafts ? (
          <TabPanel value={value} index={0}>
            <div className="ques-draft table-card">
              <table>
                <tbody>
                  {myQuestionDrafts.map((question, idx) => (
                    <Question
                      key={idx}
                      quesId={idx}
                      quesDraftId={question.draftID}
                      quesTitle={question.quesTitle}
                      toRenderData={false}
                      renderEditDraftQuesModal={renderEditDraftQuesModal}
                      openDeleteModal={openDeleteModal}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </TabPanel>
        ) : null}
        {hasAnswersDrafts ? (
          <TabPanel
            value={value}
            index={hasAnswersDrafts && !hasQuestionsDrafts ? 0 : 1}
          >
            <div className="answer-item table-card">
              <table>
                <tbody>
                  {myAnswerDrafts.map((answer, idx) => (
                    <AnswerItem
                      key={idx}
                      answerDraftId={answer.draftID}
                      draftAnswer={answer}
                      toggleEditAnswerDraftModal={toggleEditAnswerDraftModal}
                      openDeleteModal={openDeleteModal}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </TabPanel>
        ) : null}
      </>
    );
  }

  return (
    <>
      {toggleDeleteModal ? confirmDelete() : null}
      <div className='my-drafts screen-max-width page-top question-dash'>
        {isLoading ? <Loader /> : renderContent()}
      </div>
      {
        openEditModal ?
        <EditQuestionModal
          quesTitle={selectedQuestion?.quesTitle}
          quesDesc={selectedQuestion?.quesDesp}
          updateQues={handleNewQuestion}
          close={closeModal}
          open={openEditModal}
          collectionTitle={collectionTitle}
          userClientID={userClientID}
          handleQuestionSaveDraft={handleQuestionSaveDraft}
          isSaveDraft={true}
          userTankID={userTankID}
        /> : null
      }
      {
        editModalOpen ?
        <EditAnswerModal
          open={editModalOpen}
          close={closeEditAnswerModal}
          queID={questionId}
          setAnswer={answerFromResponse}
          updatedAns={handleAnswerAdd}
          handleAnswerSaveDraft={handleAnswerSaveDraft}
          isDraftAnswer={true}
          isSaveButtonDisabled={false}
        /> : null
      }
    </>
  )
}

export default MyDraftsPage;
