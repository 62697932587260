import React from 'react';

import './TermsAndConditionsPage.scss';

const TermsAndConditionsPage = () => (
  <div className='terms-conditions-page page-top'>
    <div className='tnc-container screen-max-width'>
      <h1 className='terms-of-use-title'>Terms of Use</h1>
      <div className='tnc-content-wrapper'>
        <div className='content'>
          <h2>Acceptance</h2>
          <p>
          By using, visiting or accessing our websites, you agree to both these Terms of Use and the associated Privacy Policy. If you don't agree to any of these terms, you can't use the Website. These Terms of Use apply to all users including users who are contribute any content.
          </p>
        </div>
        <div className='content'>
          <h2>Website Access</h2>
          <p>
          You can use the website as long as:
          </p>
          <ul>
            <li>It's for personal use</li>
            <li>You don't copy anything</li>
            <li>You don't alter anything maliciously</li>
            <li>You will otherwise follow these Terms of Use</li>
          </ul>
          <p>
          You might have to create an account to use the website. Keep your password secure and don't hack into other people's accounts. Don't use fake names or emails. If you get hacked, let us know right away because you are responsible for any activity coming from your account. The Company won't be held responsible for your losses, but you could be held responsible for any losses of the Company.
          </p>
          <p>
          Don't attack our servers please. We might shut your account down.
          </p>
        </div>
        <div className='content'>
          <h2>Payment</h2>
          <p>
          If you sign up for any paid plan, make sure you enter the correct credit card information and keep it updated. We'll bill you in advance for the term you selected. Your information will be stored with a third-party that is equipped to store and encrypt credit card information.
          </p>
          <p>
          If you have any issues with charges, let us know within 60 days of the charge. All payments are non-refundable. We can also change our prices in the future, and we'll notify you of that at least 30 days prior to the change. If we don't hear from you and you keep using the websites or the bot, we assume you accept the price changes. If your payment is late, the amount due will increase by 1.5% per month of the outstanding balance, or the maximum permitted by law, whichever is lower, plus any collection expenses. You're also responsible for any taxes on the paid plan.
          </p>
        </div>
        <div className='content'>
          <h2>Mobile Services</h2>
          <p>
          Like everything else in the world, you can access the website on your mobile device. Don't text and drive. Don't use the website while operating any kind of vehicle. Keep your eyes on the road! We won't be held responsible for any unlawful use of our solutions.
          </p>
        </div>
        <div className='content'>
          <h2>Intellectual Property Rights</h2>
          <p>
          Pretty simple: all of the Company's content belongs to the Company. Any Company content is provided AS IS for your information only, so don't copy it or try to break any copy-protection implemented.
          </p>
        </div>
        <div className='content'>
          <h2>User Submissions</h2>
          <p>
          Also pretty simple: your content belongs to you. We do our best to protect you but can't guarantee any confidentiality in respect to your content.
          </p>
          <p>
          You're responsible for your content. So make sure:
          </p>
          <ul>
            <li>You own the content</li>
            <li>You have written consent to use content that you don't own</li>
          </ul>
          <p>
          If you upload any content for "public use", we may use it to promote the website. We'll let you know ahead of time if we want to use your content (even though we don't have to), and respect your wishes if you refuse. We're nice like that.
          </p>
          <p>
          In addition to above, if we use your content publically, that means any users of the website will be able to see it (but not copy it).
          </p>
          <p>
          For User Submissions, you also agree that you won't:
          </p>
          <ul>
            <li>Submit any copyrighted material</li>
            <li>Publish lies or misleadings that could harm us or any third-parties</li>
            <li>Submit any illegal, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive, or encourages conduct that would be considered a criminal offence, give rise to rioting, violate any law, or is otherwise inappropriate</li>
            <li>Submit material that includes images of minors unless you are the mother, father, or duly appointed guardian of such minors or have permission from the minor's lawful parents or guardians to post images of the minors</li>
            <li>Post ads or spam of other businesses</li>
            <li>Pretend to be another person</li>
          </ul>
          <p>
          We don't endorse any User Submission or opinion and we won't be held liable for any User Submission. If we're notified of any copyright infringement, we'll remove the content in question.
          </p>
          <p>
          We also have the right to decide what is "appropriate". We're open-minded folks but there's some obvious lines that can't be crossed. If we find something we deem inappropriate, we'll remove it, and possible remove your account.
          </p>
          <p>
          You allow us to send cease-and-desist letters to infringing parties who use your content without authorization, unless you tell us it's ok. If we have to sue the infringing party, you're entitled to 0% of the funds we receive. This power will continue for as long as we use your content.
          </p>
          <p>
          When you use the websites or the bot, you may see content that was uploaded by others. We aren't held responsible if you find that content offsensive, and you waive any legal rights against us related to your objection of said content.
          </p>
          <p>
          When submitting content, you waive all rights in connection with the content, and leave it up to us whether we use your name in association with the content. We're reasonable though so we will let you know when we use your content and be respectful of your wishes. We'd never do anything to tarnish our name or your name.
          </p>
        </div>
        <div className='content'>
          <h2>Third Party Websites</h2>
          <p>The websites or the bot might have links to third-party websites that aren't owned by us, so we assume no responsibility for anything related to third-party websites.</p>
        </div>
        <div className='content'>
          <h2>Content Templates</h2>
          <p>
          The websites or the bot might have some content templates as an example. Don't use these as legally binding documents, they are examples only. We assume no responsibility, and you agree not to hold us responsible, if you choose to use them as legal documents.
          </p>
        </div>
        <div className='content'>
          <h2>Warranty and Indemnity</h2>
          <p>
          You agree to use our services at your own risk, and we don't guarantee that you won't encounter bugs (although we try). If the site is compromised, you may encounter viruses or similar problems, and we won't be held responsible for damage caused. We also don't officially endorse any third-party services.
          </p>
          <p>
          Your use of the site is your responsibility. Any legal issues as a result of using the websites, the bot, or viewing content, will exclude us in every way.
          </p>
          <p>
          You won't hold us responsible for any damages or costs related to:
          </p>
          <ul>
            <li>Your use of and access to the Website or the Bot</li>
            <li>Your violation of any aspect of these Terms of Use</li>
            <li>Your violation of any third party right, including copyright, property, or privacy rights</li>
            <li>Any claim that one of your User Submissions caused damage to a third party</li>
          </ul>
          <p>
          The above will hold true until the end of time.
          </p>
        </div>
        <div className='content'>
          <h2>Limitation of Liability</h2>
          <p>
          Once again, by using the websites or the bot, you agree that we will not be held responsible for any damages you incur, whether that is lost profits, lost business, lost data, hurt feelings, or being offended... or anything, really.
          </p>
        </div>
        <div className='content'>
          <h2>General</h2>
          <p>
          You're the age of majority or are a legal parent or guardian to a minor. By using the website or the bot, you agree to these Terms of Use, and it's like you signed a legal document saying you agreed to them.
          </p>
          <p>
          You agree that the websites, the bot, and these Terms of Use are governed by the laws of the Province of Ontario, Canada whose courts shall have exclusive jurisdiction.
          </p>
          <p>
          You agree to give us anything we need and do anything that is required in order to follow these Terms of Use.
          </p>
          <p>
          We have the right to modify these Terms of Use without warning. It's your responsibility to be aware of these Terms of Use and any changes, but we'll probably notify you because we're friendly Canadians.
          </p>
          <p>
          Each item in these Terms of Use is distinct and independent of other items. Any declaration of invalidity or unenforceability in a court of law will not affect the validity or enforceability of any other item.
          </p>
          <p>
          These Terms of Use will apply to all users and use of the websites or the bot until the end of time.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default TermsAndConditionsPage;
