import React, {useEffect} from 'react';
import axios from 'axios';

import { isEmailExists } from '../../api/api';

import Spinner from '../../components/Loader/Spinner';
import { SIGN_IN_PAGE, SIGNUP_PAGE } from '../../constants/PageRoutes';
import './LoadingPage.scss';

const LoadingPage = props => {
  const { history = {}, location: {search} } = props;

  const urlParams = new URLSearchParams(search);
  const userEmailVal = urlParams.get('email');

  useEffect(() => {
    if (userEmailVal?.length) {
      axios.post(isEmailExists, { emailID: userEmailVal })
        .then(res => {
          if (res?.data?.isExists) {
            history.push(`${SIGN_IN_PAGE}${search}`);
          } else {
            history.push(`${SIGNUP_PAGE}${search}`);
          }
        });
    }
  }, []);

  return (
    <div className="loading-page-container screen-max-width full-page-height">
      <div className="loading-page">
        <h3>Loading...</h3>
        <Spinner color='blue' size={20} />
      </div>
    </div>
  )
};

export default LoadingPage;
