import React, { useState, useEffect } from "react";
import axios from "axios";

import { questionDetails } from "../../../api/api";

import Modal from "../Modal/Modal";
import Answer from "../../Answer/Answer";

import "./ViewQuestionModal.scss";

const ViewQuestionModal = (props) => {
  const [viewQuesDetails, setViewQuesDetails] = useState([]);

  const { open, close, activeViewQuestionID } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  useEffect(() => {
    axios.get(`${questionDetails}${activeViewQuestionID}`).then((res) => {
      const resData = res?.data?.data?.[0];
      setViewQuesDetails(resData);
    });
  }, []);

  return (
    <div className="view-question-modal">
      <Modal close={close} modalDisplay={divStyle} isLoading={false}>
        {viewQuesDetails && (
          <>
            <h2>{viewQuesDetails.quesTitle}</h2>
            <p
              className="ques-desc"
              dangerouslySetInnerHTML={createMarkup(viewQuesDetails.quesDesp)}
            />
            <p className="creator">
              Created by: <span>{viewQuesDetails.userName}</span>
            </p>
            <div className="answers-container">
              {viewQuesDetails?.answers?.map((ans) => (
                <Answer answerData={ans} />
              ))}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default ViewQuestionModal;
