import React, { useEffect, useState } from "react";
import moment from "moment";

import ReactEcharts from "echarts-for-react";

import axios from "axios";

import { analyticsTeamActivity } from "../../api/api";
import { getCurrentTime, isEmptyObject, getCurrentMonth } from "../../utils";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import Grid from "@material-ui/core/Grid";

import Loader from "../../components/Loader/Loader";
import LoaderWithBackdrop from "../../components/Loader/LoaderWithBackdrop";

import "./AnalyticsPage.scss";

const AnalyticsPage = (props) => {
  const { userPlan, spaceID } = props;
  const [spaceActivity, setSpaceActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [collectionGraph, setCollectionGraph] = useState([]);
  const [daysAxis, setDaysAxis] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(moment().month());
  const [platformUsage, setPlatformUsage] = useState({
    slackUsage: [],
    tankUsage: [],
  });

  const [isFetchingData, setIsFetchingData] = useState(false);

  const months = [];

  for (let month = 0; month <= moment().month(); month++) {
    months.push(moment().month(month).format("MMMM"));
  }

  const ITEM_HEIGHT = 48;
  const NO_DATA_AVAILABLE_MSG = "No data available for the selected month";

  const isPlanValid = userPlan?.trial || userPlan?.name === "PRO";

  const showMonthChangeLoader = (showLoader) => {
    setIsFetchingData(showLoader);
    if (showLoader) document?.body?.classList?.add("fetching-month-data");
    else document?.body?.classList?.remove("fetching-month-data");
  };

  const getAnalyticsForSelectedMonth = (selectedMonth) => {
    const currentDate = getCurrentTime();
    const noOffsetDate = currentDate.replace(/[-+]\d{2}:\d{2}$/, ""); // Modify daylight savings date format.

    const body = {
      spaceID: spaceID,
      date: noOffsetDate,
      month: selectedMonth,
    };

    axios
      .post(analyticsTeamActivity, body)
      .then((res) => {
        console.log("RES ---->>>", res, res?.data);
        if (res?.data) {
          setSpaceActivity(res?.data);
          setIsLoading(false);
          showMonthChangeLoader(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showMonthChangeLoader(false);
      });
  };

  useEffect(() => {
    if (!isEmptyObject(userPlan)) {
      if (isPlanValid) {
        getAnalyticsForSelectedMonth(getCurrentMonth());
      } else {
        setIsLoading(false);
      }
    }
  }, [userPlan, isPlanValid]);

  useEffect(() => {
    const currentDay = parseInt(moment().format("DD"));
    let xAxisDays = [];
    for (let day = 1; day <= currentDay; day++) {
      xAxisDays.push(day);
    }
    setDaysAxis(xAxisDays);
  }, []);

  useEffect(() => {
    if (spaceActivity?.collection_activity) {
      const col = spaceActivity?.collection_activity.map((colActivity) => ({
        value: colActivity.value,
        name: colActivity.colName,
        questionsNum: colActivity.noOfQuestions,
      }));
      setCollectionGraph(col);
    }

    if (spaceActivity?.platform_usage?.length) {
      const slackData = [];
      const tankData = [];

      spaceActivity?.platform_usage?.forEach((item) => {
        if (item?.slack) {
          slackData.push(item?.slack);
        }
        if (item?.tank) {
          tankData.push(item?.tank);
        }
        if (item === 0) {
          slackData.push({ activity: {}, perc: 0.0 });
          tankData.push({ activity: {}, perc: 0.0 });
        }
      });
      setPlatformUsage({
        slackUsage: slackData,
        tankUsage: tankData,
      });
    }
  }, [spaceActivity]);

  const handleSelectedMonth = (month) => {
    showMonthChangeLoader(true);
    const selectedMonth = moment().month(month).format("M");
    let modifiedSelectedMonth = "";

    if (selectedMonth?.toString()?.length === 1)
      modifiedSelectedMonth = `0${selectedMonth}`;
    else modifiedSelectedMonth = selectedMonth;
    getAnalyticsForSelectedMonth(modifiedSelectedMonth);
  };

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index, month) => {
    setSelectedIndex(index);
    handleSelectedMonth(month);
    setAnchorEl(null);
  };

  const getPlatformUsageSlackData = () => {
    const graphDataSlack = platformUsage?.slackUsage?.map((m) => m?.perc);
    console.log("graphDataSlack --->>>", graphDataSlack);

    return graphDataSlack;
  };

  const getPlatformUsageTankData = () => {
    const graphDataTank = platformUsage?.tankUsage?.map((m) => m?.perc);
    console.log("graphDataTank --->>>", graphDataTank);

    return graphDataTank;
  };

  const renderAnalyticsContent = () => (
    <>
      <Grid container spacing={6} className="stats-container">
        <Grid item xs={4}>
          <div className="stat-item total-questions">
            <p className="stat-quantity">
              {spaceActivity?.question_statistics?.total_questions}
            </p>
            <p className="stat-label">Total questions</p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="stat-item total-members">
            <p className="stat-quantity">
              {spaceActivity?.total_users_in_space}
            </p>
            <p className="stat-label">Total team members</p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="stat-item perc-verified">
            <div className="perc-verify-wrap">
              <p className="stat-quantity">
                {
                  spaceActivity.question_statistics
                    ?.total_questions_verified_answers
                }
              </p>
              <p className="stat-label">% verified questions</p>
            </div>
            <div className="perc-verified-pie">
              <ReactEcharts
                option={{
                  tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                      return `<div style="display: flex; align-items: center;"><i style="background-color:${params.color}; width: 16px; height: 16px; border-radius: 100%; float:left; margin-right: 10px;"></i>
                          <span style="word-break: inherit; font-weight: 700;"><span style="font-weight: 500;">${params.data.label}</span>: ${params.data.value}%</span></div>
                        `;
                    },
                  },
                  legend: {
                    show: false,
                  },
                  color: ["#ffa5ad", "#aef0cb"],
                  series: [
                    {
                      // name: 'abc',
                      type: "pie",
                      radius: ["45%", "70%"],
                      avoidLabelOverlap: false,
                      label: {
                        show: false,
                        position: "center",
                      },
                      emphasis: {
                        label: {
                          show: false,
                          fontSize: "40",
                          fontWeight: "bold",
                        },
                      },
                      labelLine: {
                        show: false,
                      },
                      data: [
                        {
                          value:
                            100 -
                            spaceActivity?.question_statistics
                              ?.total_questions_verified_answers,
                          label: "Unverified",
                        },
                        {
                          value:
                            spaceActivity?.question_statistics
                              ?.total_questions_verified_answers,
                          label: "Verified",
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="platform-usage-month-selector">
        <Button
          aaria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
          variant="contained"
          color="default"
          endIcon={<ExpandMoreOutlinedIcon />}
        >
          {months?.length && months?.[selectedIndex]}
        </Button>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {months?.length &&
            months.map((month, index) => (
              <MenuItem
                key={month}
                value={month}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(index, month)}
              >
                {month}
              </MenuItem>
            ))}
        </Menu>
      </div>
      <Grid container className="platform-traffic">
        <Grid
          item
          xs={12}
          className={`graph-item platform ${
            !spaceActivity?.team_activity?.length ? "no-data" : ""
          }`}
        >
          <div className="chart-details">
            <p className="chart-title">Platform usage</p>
            <p className="chart-description">
              Percentage of activity from Tank and Slack platforms each day
            </p>
          </div>
          {spaceActivity?.team_activity?.length ? (
            <ReactEcharts
              option={{
                tooltip: {
                  trigger: "item",
                  formatter: function (params) {
                    console.log("PARAMS --->>", params);
                    return `<div style="display: flex; align-items: center;"><i style="background-color:${params?.color}; width: 16px; height: 16px; border-radius: 100%; float:left; margin-right: 10px;"></i>
                          <span style="word-break: inherit; font-weight: 700;"><span style="font-weight: 500;">Day</span>: ${params?.name}</span></div><span style="margin-left: 24px; font-weight: 500;">Platform: </span><span style="font-weight: 700;">${params?.seriesName}</span>
                          <span style="margin-left: 24px; font-weight: 500;">Activity: </span><span style="font-weight: 700;">${params?.data}%</span>
                        `;
                  },
                },
                legend: {
                  data: ["Slack", "Tank"],
                },
                xAxis: {
                  type: "category",
                  name: "Days",
                  nameLocation: "middle",
                  nameGap: 30,
                  data: daysAxis,
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    formatter: "{value} °C",
                  },
                  name: "Usage",
                  nameLocation: "middle",
                  nameGap: 60,
                },
                series: [
                  {
                    data: getPlatformUsageSlackData(),
                    type: "line",
                    name: "Slack",
                  },
                  {
                    data: getPlatformUsageTankData(),
                    type: "line",
                    name: "Tank",
                  },
                ],
              }}
            />
          ) : (
            <p className="chart-no-data-msg">{NO_DATA_AVAILABLE_MSG}</p>
          )}
        </Grid>
        <Grid item xs={12} className="graph-item-wrapper">
          <div className="graph-item team-adoption">
            <div className="chart-details">
              <p className="chart-title">Team adoption</p>
              <p className="chart-description">
                Percentage of team active each day
              </p>
            </div>
            {spaceActivity?.team_activity?.length ? (
              <ReactEcharts
                option={{
                  title: {
                    left: 20,
                    rich: {},
                  },
                  tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                      return `<div style="display: flex; align-items: center;"><i style="background-color:${params.color}; width: 16px; height: 16px; border-radius: 100%; float:left; margin-right: 10px;"></i>
                          <span style="word-break: inherit; font-weight: 700;"><span style="font-weight: 500;">Day</span>: ${params.name}</span></div><span style="margin-left: 24px; font-weight: 500;">Activity: </span><span style="font-weight: 700;">${params.data}%</span>
                        `;
                    },
                  },
                  xAxis: {
                    type: "category",
                    data: daysAxis,
                  },
                  yAxis: {
                    type: "value",
                  },
                  series: [
                    {
                      data: spaceActivity?.team_activity,
                      type: "bar",
                      showBackground: true,
                      backgroundStyle: {
                        color: "rgba(180, 180, 180, 0.2)",
                      },
                    },
                  ],
                }}
              />
            ) : (
              <p className="chart-no-data-msg">{NO_DATA_AVAILABLE_MSG}</p>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={5} className="graph-section">
        <Grid item xs={12} className="graph-item-wrapper">
          <div className="graph-item collection-activity">
            <div className="chart-details">
              <p className="chart-title">Collection activity</p>
              <p className="chart-description">
                Usage percentage of each collection over the selected month
              </p>
            </div>
            {collectionGraph.length ? (
              <ReactEcharts
                option={{
                  tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                      return `<div style="display: flex; align-items: center; justify-content: flex-start;"><i style="background-color:${params.color}; width: 16px; height: 16px; border-radius: 100%; float:left; margin-right: 8px;"></i>
                            <span style="word-break: inherit; font-weight: 700;"><span style="font-weight: 500;">Activity:</span> ${params.data.value}%</span></div><span style="margin-left: 24px; font-weight: 500;">Queries: <span style="font-weight: 700;">${params.data.questionsNum}</span></span>
                          `;
                    },
                  },
                  legend: {
                    show: false,
                  },
                  label: {
                    show: true,
                    fontSize: "12",
                    fontWeight: "bold",
                  },
                  series: [
                    {
                      type: "pie",
                      radius: "60%",
                      avoidLabelOverlap: false,
                      data: collectionGraph.length ? collectionGraph : [],
                      emphasis: {
                        itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                      },
                    },
                  ],
                }}
              />
            ) : (
              <div className="no-graph-data">
                <p className="no-graph-data-label">{NO_DATA_AVAILABLE_MSG}</p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );

  const checkIsPlanValid = () => {
    if (!isPlanValid)
      return (
        <div className="analytics-upgrade">
          <div className="analytics-upgrade-asset">
            <img
              src={require("../../assets/images/analytics-upgrade.png")}
              alt="upgrade"
            />
          </div>
          <p>Please upgrade</p>
        </div>
      );

    return renderAnalyticsContent();
  };

  return (
    <div
      className={`analytics-page screen-max-width page-top page-loader-pos ${
        isLoading ? "loading" : "page-rendered"
      }`}
    >
      {isFetchingData ? (
        <LoaderWithBackdrop show={isFetchingData} label={"Fetching data..."} />
      ) : null}
      {isLoading ? <Loader /> : checkIsPlanValid()}
    </div>
  );
};

export default AnalyticsPage;
