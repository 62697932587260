import React, { useState, useEffect } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { BILLING_SETTINGS_PAGE } from "../../../../constants/PageRoutes";
import { updatePlan } from "../../../../api/api";
import LoaderWithBackdrop from "../../../../components/Loader/LoaderWithBackdrop";

import "./BillingSuccess.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      padding: theme.spacing(3),
    },
  },
}));

const BillingSuccess = (props) => {
  const classes = useStyles();
  const {
    currentTeamData: { orgID },
  } = props;

  const [showLoader, setShowLoader] = useState(false);

  const subscribedPlanData = JSON.parse(
    window.localStorage.getItem("subscribedPlan")
  );

  console.log("subscribedPlanData 123", subscribedPlanData);

  const updateUserPlan = () => {
    const body = {
      plan: subscribedPlanData?.planName?.toUpperCase(),
      planDuration:
        subscribedPlanData?.planDuration?.charAt(0).toUpperCase() +
        subscribedPlanData?.planDuration?.slice(1),
      orgID,
    };

    axios.post(updatePlan, body).then((res) => {
      console.log("RES updatePlan", res);
      setShowLoader(false);
      window.location.href = BILLING_SETTINGS_PAGE;
    });
  };

  useEffect(() => {
    if (subscribedPlanData?.planName) {
      setTimeout(() => {
        setShowLoader(true);
        updateUserPlan();
      }, [2500]);
    }
  }, []);

  return (
    <div className="billing-success screen-max-width page-top">
      <LoaderWithBackdrop
        show={showLoader}
        label={"Redirecting to billing page..."}
      />
      <div className={classes.root}>
        <Paper elevation={3}>
          <div className="payment-asset">
            <img
              src={require("../../../../assets/images/payment_success.png")}
              alt="payment success"
            />
          </div>
          <div className="payment-info">
            <h2>Payment successful!</h2>
            <p>Your transaction was completed successfully.</p>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default BillingSuccess;
