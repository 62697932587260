export const BASIC_MONTHLY_PLAN = "Basic_monthly";
export const BASIC_MONTHLY_PLAN_PRICE = 30;

export const BASIC_YEARLY_PLAN = "Basic_yearly";
export const BASIC_YEARLY_PLAN_PRICE = 20;

export const PRO_MONTHLY_PLAN = "Pro_monthly";
export const PRO_MONTHLY_PLAN_PRICE = 60;

export const PRO_YEARLY_PLAN = "Pro_yearly";
export const PRO_YEARLY_PLAN_PRICE = 50;
