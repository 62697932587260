import { LOGGED_IN_USER } from "../actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case LOGGED_IN_USER:
      return {
        ...state,
        loggedInUserData: action.payload
      }
    default:
      return state;
  }
}
