import React from 'react';
import Modal from '../Modal/Modal';

import './DeleteModal.scss';

const DeleteModal = (props) => {
  const { confirmDeleteMsg, cancelText, confirmText, onDelete, close, open, isBtnDisabled } = props;

  const modalDisplay = {
    display: open ? "flex" : "none",
  };

  return (
    <div className="delete-modal">
      <Modal
        close={close}
        modalDisplay={modalDisplay}
        isDisabled={false}
        cancelText={cancelText}
        provisionType={confirmText}
        handleProvisioning={onDelete}
        isBtnDisabled={isBtnDisabled}
      >
        <div className='tank-modal delete'>
          <h3 className="confirm-delete-msg">{confirmDeleteMsg}</h3>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteModal;
