import { CURRENT_USER_DATA, CURRENT_USER } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case CURRENT_USER_DATA:
      return action.payload;
    case CURRENT_USER:
      return action.payload;
    default:
      return state;
  }
}
