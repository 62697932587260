import React from 'react';
import Modal from '../Modal/Modal';

import { CANCEL, CONFIRM } from '../../../constants/ButtonLabel';

const ConfirmationModal = (props) => {
  const { message, onConfirm, close, open } = props;

  const modalDisplay = {
    display: open ? "flex" : "none",
  };

  return (
    <div className="delete-modal">
      <Modal
        close={close}
        modalDisplay={modalDisplay}
        isDisabled={false}
        cancelText={CANCEL}
        provisionType={CONFIRM}
        handleProvisioning={onConfirm}
      >
        <div className='tank-modal delete'>
          <h3 className="confirm-delete-msg">{message}</h3>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmationModal;
