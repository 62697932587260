import React from "react";

import Button from "@material-ui/core/Button";

import InputField from "../../components/InputField/InputField";
import SelectDropdown from "../../components/SelectDropdown/SelectDropdown";
import UserEntryContainer from "../../components/UserEntryContainer/UserEntryContainer";
import Shimmer from "../Shimmer";

import { SETTING_UP_PAGE } from "../../constants/PageRoutes";

import "./OnboardingStepper.scss";

const OnboardingStepper = (props) => {
  const {
    handleTeamNameChange,
    teamName,
    teamNameError,
    handleOnboardDataSubmit,
    disableBtn,
    handleTeamSelectionChange,
    userSelectedTeam,
    existingUserTeams,
    organizationLoading,
    selectedTeam,
    configureDashboardData,
    formError,
  } = props;

  return (
    <UserEntryContainer>
      {organizationLoading ? (
        <div className="shimmers">
          <Shimmer variant="rect" width={100} height={18} />
          <Shimmer variant="rect" width={"100%"} height={35} />
        </div>
      ) : !existingUserTeams.length ? (
        <>
          <h3>Let's set up your team</h3>
          <div className="onboard-info-block">
            <InputField
              labelText={"Team Name"}
              inputValue={teamName}
              type={"text"}
              inputTag={"input"}
              handleOnChange={handleTeamNameChange}
              helpText={teamNameError ? "Team name already available" : ""}
            />
          </div>
          <div className="stepper-nav-btns">
            {organizationLoading ? (
              <Shimmer variant="rect" width={100} height={37} />
            ) : (
              <>
                {formError && (
                  <p className="form-err-msg">
                    {"Enter valid first name and team name"}
                  </p>
                )}
                <Button
                  href={SETTING_UP_PAGE}
                  variant="contained"
                  color="primary"
                  className="primary-btn"
                  onClick={handleOnboardDataSubmit}
                  disabled={disableBtn}
                >
                  Onboard
                </Button>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <SelectDropdown
            labelText={"Select your team"}
            value={userSelectedTeam}
            handleOnChange={handleTeamSelectionChange}
            options={existingUserTeams}
            selectedTeam={selectedTeam}
          />
          <div className="stepper-nav-btns">
            {organizationLoading ? (
              <Shimmer variant="rect" width={100} height={37} />
            ) : (
              <>
                {formError && (
                  <p className="form-err-msg">{"Enter valid team name"}</p>
                )}
                <Button
                  href={SETTING_UP_PAGE}
                  variant="contained"
                  color="primary"
                  className="primary-btn"
                  onClick={configureDashboardData}
                  disabled={disableBtn}
                >
                  Go to Dashboard
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </UserEntryContainer>
  );
};

export default OnboardingStepper;
